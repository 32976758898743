import React, { useEffect, useState } from "react";
import { IoLockClosed } from "react-icons/io5";
import apiClient from "../api/apiClient";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";

export const Favourites = () => {
  const [favourites, setfavourites] = useState([]);
  const [spiner, setspiner] = useState(false);

  const fetchData = async () => {
    setspiner(true);
    const result = await apiClient.get("/favorite-restaurants/");
    setspiner(false);
    if (result.ok) setfavourites(result.data.results);
  };

  useEffect(() => {
    fetchData();
  }, []);

  console.log(favourites, "favourites");
  return (
    <>
      <section className="  ">
        <div className="mycontainer py-5">
          {/* {!spiner && favourites.length >0 ? <h4>Mes préférés</h4>:"" }  */}
      
          <div class="row justify-content-between ">
                <h5 class="fs-3 fw-normal text-center text-prpl">
                Voici la liste de vos restaurants favoris !
                </h5>
                
            </div>

          {spiner ? (
            <div className=" mx-auto text-center d-flex justify-content-center mt-5">
              <CircularProgress color="secondary" />
            </div>
          ) : (
             
            <div className="row">
              {favourites.map((item) => {
                return (
                  <div className="col-md-4 col-sm-6 mt-3">
                    <div className="border shadow rounded-3 p-3 d-sm-block d-flex align-items-center justify-content-center flex-column flex-wrap">
                      <Link to={`/detail?id=${item?.restaurant?.id}`}>
                        <div>
                          <img
                            src={item?.restaurant_image?.image}
                            alt=""
                            className="con-img"
                          />
                        </div>
                      </Link>
                      <div className="mt-sm-3 ps-sm-0 ps-3">
                        <h5>{item?.restaurant?.name}</h5>
                        {/* <p className="mb-0">
              <IoLockClosed className='fs-5 ' />Liste privée
              </p>
              <p className="mb-0"> restaurant Mis à jour {item?.formatted_date}</p> */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {!spiner && favourites.length === 0 ? (
            <div className="mt-5 d-flex flex-column  justify-content-between ">
              <h5 className="text-center">
                {" "}
                Vous n’avez pas encore de restaurants favoris. accordion
                <br />
                Découvrez notre sélection et faites votre réservation !
              </h5>
              <Link className="mx-auto" to={"/featured"}>
                <button
                  className="btn text-light mx-auto"
                  style={{ backgroundColor: "#442479", width: "100px " }}
                >
                  {" "}
                  + Add{" "}
                </button>
              </Link>
            </div>
          ) : (
            ""
          )}
        </div>
      </section>
    </>
  );
};

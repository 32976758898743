import React, { useEffect, useState } from "react";
import { FaPlusCircle } from "react-icons/fa";
import apiClient from "../api/apiClient";
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";

import { toast } from "react-toastify";
import Map from "../components/Map";
import { useLocation, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { getCurrentURLParameters } from "../modules/helpers";
import { handleErrors } from "../modules/handleErrors";
import { MdDelete } from "react-icons/md";
import { Button, Modal, ModalFooter } from "react-bootstrap";

export const AddNewUserresturants = () => {
  const [cuisines, setcuisines] = useState([]);
  const [resimages, setresimages] = useState([]);
  const [showmodle, setshowmodle] = useState(false);

  const { id } = getCurrentURLParameters();
  const [personName, setPersonName] = React.useState([]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const fetchcuisines = async () => {
    try {
      // setLoading(true)
      const result = await apiClient.get(`/cuisines/`);
      if (result.ok) setcuisines(result.data.results);
      //   setCount(result?.data.count);
      // setLoading(false)
    } catch (error) {}
  };

  const fetchresturentimage = async () => {
    try {
      // setLoading(true)
      const result = await apiClient.get(
        `/restaurant-images/?restaurant_id=${id}`
      );
      if (result.ok) setresimages(result.data.results);
      //   setCount(result?.data.count);
      // setLoading(false)
    } catch (error) {}
  };

  const deleteresturentimage = async (id) => {
    try {
      // setLoading(true)
      const result = await apiClient.delete(`/restaurant-images/${id}/`);
      if (!result.ok) return;

      fetchresturentimage();

    } catch (error) {}
  };

  useEffect(() => {
// setTimeout(() => {
  fetchcuisines();
// }, 5000);



  

    if (id) {
      fetchresturentimage();
    }
  }, []);

  const loction = useLocation();

  const putdata = loction?.state?.state;

  const initalState = {
    type: [],
    name: "",
    description: "",
    about: "",
    average_price: "50",
    country: "",
    city: "city",
    email: "",
    location: "",
    latitude: "",
    longitude: "",
    images: [],

    password: "",
  };

  const [loader, setloader] = useState(false);
  const [menu, setmenu] = useState([]);
  const [deletemodale, setdeletemodale] = useState(false);
  const [resturant, setResturant] = useState(initalState);
  const [editeimage, setediteimage] = useState("");
  const [editeimageid, setediteimageid] = useState("");

  const [city, setcitydata] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [address, setAddress] = useState({});
  const [errormassege, setErrormassege] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    if (typeof putdata == "object") {
      setResturant(putdata);
    }
    // setImagePreviews(putdata?.images)
  }, []);


  const handleChangeCity = (event) => {
    setSelectedCity(event.target.value);
    setResturant((prevState) => ({
      ...prevState,
      city: event.target.value,
    }));
  };

  const handleChange = (key, value) => {
    if (key === "average_price") {
      const numericValue = parseInt(value, 10);
      if (numericValue >= 50) {
        setResturant({ ...resturant, [key]: value });
        setErrormassege("");
      } else {
        setErrormassege("Average Price must be greater then 50");
        setResturant({ ...resturant, [key]: value });
      }
    } else {
      setResturant({ ...resturant, [key]: value });
    }
  };
  const [imagePreviews, setImagePreviews] = useState([]);

  const handleImageChange = (e) => {
    const images = Array.from(e.target.files);
    setResturant((prevState) => ({
      ...prevState,
      images: prevState.images.concat(images),
    }));

    // Generate previews for selected images
    const previews = [];
    images.forEach((image) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        previews.push(event.target.result);
        if (previews.length === images.length) {
          setImagePreviews((prevPreviews) => [...prevPreviews, ...previews]);
        }
      };
      reader.readAsDataURL(image);
    });
  };

  const fetchData = async () => {
    const result = await apiClient.get("/city/");
    if (result.ok) setcitydata(result.data.results);
  };

  const handelSubmit = async (e) => {
    e.preventDefault();

    if (!address.latitude && !resturant.latitude)
      return toast.error("Restaurant Location is required");
    if (resturant?.images.length == 0)
      return toast.error("Restaurant  Image is required");
    const uploaddata = new FormData();
    uploaddata.append("name", resturant.name);
    uploaddata.append("description", resturant.description);
    // uploaddata.append("location", resturant.location)
    uploaddata.append("about", resturant.about);
    uploaddata.append("average_price", resturant.average_price);
    uploaddata.append("country", resturant.country);
    // uploaddata.append("images", resturant.images)

    if (address.formatted_address) {
      uploaddata.append("location", address.formatted_address || "");
    }

    if (address.latitude) {
      uploaddata.append("latitude", address.latitude || "");
    }

    if (address.longitude) {
      uploaddata.append("longitude", address.longitude || "");
    }

    if (!id) {
      uploaddata.append("email", resturant.email || "");
    }

    resturant.type.forEach((value) => {
      uploaddata.append("type", value || "");
    });

    uploaddata.append("city", resturant.city || "");
    if (!id) {
      uploaddata.append("password", resturant.password || "");
    }

    resturant?.images?.forEach((value) => {
      uploaddata.append("images", value);
    });

    try {
      setloader(true);
      const result = id
        ? await apiClient.put(`/new-user-restaurant/${id}/`, uploaddata)
        : await apiClient.post("/new-user-restaurant/", uploaddata);
      setloader(false);
      if (!result.ok) return handleErrors(result.data);

      toast.success("Restaurant added successfully!");
      navigate("/admin/resturants");
    } catch (error) {
      console.error(error);
      toast.error("Error occurred while adding city");
    }
  };

  useEffect(() => {
    fetchData();
  }, [address]);

console.log(resturant,"resturant");
  return (
    <>
      <div className="">
        <div className="right-mid mx-1 mx-sm-3  p-2">
          <div className=" row d-flex justify-content-between align-items-center">
            <div className="col-12 col-sm-7 p-0 my-3">
              <h4 className="mb-0 mx-2">
                {id ? "Edit" : "Add New"} Restaurant
              </h4>
              <div>{/* Display image previews */}</div>
            </div>
          </div>
        </div>

        <div className="right-bottom mx-1 mx-sm-3 ">
          <div className="bottom-inner">
            <div className="bottom-inner">
              <form action="" onSubmit={handelSubmit}>
                <div className="d-flex gap-4">
                  {id ? (
                    ""
                  ) : (
                    <div
                      className=" rounded-5 mb-3"
                      style={{ width: "170px", background: "#F7F6F9" }}
                    >
                      <label
                        className=" d-flex justify-content-center align-items-center flex-column p-3 "
                        htmlFor="restimg"
                        style={{ height: "17vh" }}
                      >
                        <p className=" d-flex flex-column justify-content-center align-items-center gap-3">
                          <FaPlusCircle
                            style={{ color: "black", fontSize: "20px" }}
                          />
                          Upload Image
                        </p>
                      </label>

                      <input
                        hidden
                        type="file"
                        onChange={handleImageChange}
                        accept="image/*"
                        name="images"
                        multiple
                        id="restimg"
                      />
                    </div>
                  )}

                  {!id ? (
                    ""
                  ) : (
                    <button
                      className="btn"
                      onClick={(e) => {
                        e.preventDefault();
                        setediteimageid("");
                        setshowmodle(true);
                      }}
                    >
                      {" "}
                      Add Image{" "}
                    </button>
                  )}

                  {imagePreviews.map((preview, index) => (
                    <img
                      key={index}
                      src={preview}
                      alt={`Image ${index}`}
                      style={{
                        width: "100px",
                        height: "auto",
                        objectFit: "cover",
                        objectPosition: "top",
                      }}
                    />
                  ))}
                  {resimages.map((preview, index) => (
                    <div className="position-relative ">
                      <div
                        className="position-absolute "
                        style={{ top: "3px", right: "3px" }}
                      >
                        <MdDelete
                          onClick={() => deleteresturentimage(preview?.id)}
                          style={{ fontSize: "17px", color: "red" }}
                        />
                      </div>
                      <div
                        className="position-absolute "
                        style={{ top: "3px", right: "20px" }}
                      >
                        <EditIcon
                          sx={{ fontSize: "17px", color: "red" }}
                          onClick={() => {
                            setshowmodle(true);
                            setediteimage(preview?.image);
                            setediteimageid(preview?.id);
                          }}
                        />
                      </div>
                      <img
                        key={preview?.id}
                        src={preview?.image}
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          objectPosition: "top",
                        }}
                      />
                    </div>
                  ))}
                </div>
                <div className="row px-5">
                  <div className="col-12 col-sm-6 py-1">
                    <select
                      className="form-select"
                      required
                      aria-label="Default select example"
                      value={resturant.city}
                      onChange={handleChangeCity}
                    >
                      <option value="city" selected disabled>
                        Select a City
                      </option>
                      {city?.map((cityItem, index) => (
                        <option key={index} value={cityItem.id}>
                          {cityItem.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-12 col-sm-6 py-1">
                    <input
                      type="text"
                      placeholder="Name"
                      required
                      className="form-control"
                      value={resturant.name}
                      onChange={(e) => handleChange("name", e.target.value)}
                    />
                  </div>
                  <div className="col-12 py-1">
                    <textarea
                      name="description"
                      id=""
                      cols={30}
                      placeholder="Description"
                      rows={8}
                      className="form-control"
                      defaultValue={""}
                      value={resturant.description}
                      onChange={(e) =>
                        handleChange("description", e.target.value)
                      }
                    />
                  </div>

                  <div className="col-12 col-sm-6 py-1">
                    <input
                      required
                      type="text"
                      placeholder="about"
                      className="form-control"
                      value={resturant.about}
                      onChange={(e) => handleChange("about", e.target.value)}
                    />
                  </div>

                  <div className="col-12 col-sm-6 py-1">
                    <Map
                      address={address}
                      setAddress={setAddress}
                      //  location={location}
                    />
                  </div>

                  <div className="row">
                    <div className="col-12 col-sm-6 py-1">
                      <input
                        required
                        type="text"
                        placeholder="country"
                        className="form-control"
                        value={resturant.country}
                        onChange={(e) =>
                          handleChange("country", e.target.value)
                        }
                      />
                    </div>

                    <div className="col-12 col-sm-6 py-1">
                      <input
                        type="number"
                        min={50}
                        placeholder="Average Price"
                        className="form-control"
                        value={resturant.average_price}
                        onChange={(e) =>
                          handleChange("average_price", e.target.value)
                        }
                      />
                      <p className="text-danger"> {errormassege}</p>
                    </div>
                  </div>
                  <div className="row">
                    {!id && (
                      <div className="col-12 col-sm-6 py-1">
                        <input
                          type="email"
                          required
                          placeholder="Email"
                          className="form-control"
                          value={resturant.email}
                          onChange={(e) =>
                            handleChange("email", e.target.value)
                          }
                        />
                      </div>
                    )}
                    <div className="col-12 col-sm-6 py-1">
                    <input
                          type="password"
                          required
                          placeholder="password"
                          className="form-control"
                          value={resturant.password}
                          onChange={(e) =>
                            handleChange("password", e.target.value)
                          }
                        />
                   
                    </div>
                  </div>
                  <div className="row">
                    {!id && (
                      <div className="col-12 col-sm-6 mt-3 mb-2">
                      <FormControl fullWidth>
  <InputLabel
    id="demo-simple-select-label"
    sx={{ color: "black", margin: "0 !important" }}
  >
    <span className="notranslate">Sélectionner le type de cuisine</span>
  </InputLabel>
  <Select 
  className="notranslate"
    sx={{ border: "1px solid #DEE2E6" }}
    multiple
    placeholder="Type"
    onChange={(e) => handleChange("type", e.target.value)}
    value={resturant.type}
  >
    {cuisines?.length > 0 &&
      cuisines.map((e) => {
        return (
          <MenuItem value={e.id} key={e.id} >
            <span className="notranslate">{e.name}</span>
          </MenuItem>
        );
      })}
  </Select>
</FormControl>

                      </div>
                    )}{" "}
                  </div>
                </div>

                <div className="col-12  mb-2 py-1">
                  <button
                    type="submit"
                    // onClick={handelSubmit}
                    className="gradient col-md-2 text-decoration-none px-5 border-0 py-2 text-white rounded-3"
                  >
                    {loader ? "Save..." : "save"}
                  </button>
                </div>
              </form>
            </div>
            {showmodle && (
              <CuisinesEditpopup
                resturantid={id}
                show={showmodle}
                onHide={() => setshowmodle(false)}
                fetchData={fetchresturentimage}
                imagePreview={editeimage}
                id={editeimageid}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const CuisinesEditpopup = ({
  show,
  onHide,
  id,
  fetchData,
  imagePreview,
  image,
  name,
  setname,
  resturantid,
}) => {
  const [editname, seteditname] = useState("");
  const [editimage, seteditimage] = useState("");
  const [EDperveiwimg, setEDperveiwimg] = useState("");
  const handlefavoritedelete = async () => {
    try {
      const formData = new FormData();


      if (typeof editimage == "object") {
        formData.append("image", editimage);
      }
      let response;
      if (id) {
        response = await apiClient.put(`/restaurant-images/${id}/`, formData);
      } else {
        response = await apiClient.post(
          `/restaurant-images/?restaurant_id=${resturantid}`,
          formData
        );
      }
      if (!response.ok) return handleErrors(response.data);

      fetchData();

      onHide();

      console.log(response);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    setEDperveiwimg(imagePreview);
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    seteditimage(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setEDperveiwimg(reader.result); // Set image preview
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ zIndex: "1300" }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Edit</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="rounded-4 card-slider">
            <span className="rounded-2">
              <div
                className="bg-img rounded-3 position-relative"
                style={{
                  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${EDperveiwimg})`,
                }}
              >
                <div
                  className="position-absolute text-white"
                  style={{ bottom: "10px", left: "10px" }}
                ></div>
                <div
                  className="position-absolute text-white"
                  style={{ top: "10px", right: "10px" }}
                ></div>
                <div
                  className="position-absolute text-white"
                  style={{ top: "30px", right: "10px" }}
                ></div>
              </div>
            </span>
          </div>

          <div
            className=" rounded-5 mb-3 mx-auto d-flex  justify-content-center mt-4"
            style={{ width: "170px", height: "70px", background: "#F7F6F9" }}
          >
            <label htmlFor="img">
              {" "}
              <p className=" d-flex flex-column justify-content-center align-items-center gap-3">
                <FaPlusCircle style={{ marginTop: "9px" }} /> Upload Image
              </p>
            </label>
            <input
              hidden
              className="form-control mt-4"
              type="file"
              id="img"
              onChange={handleImageChange}
            />
          </div>
        </Modal.Body>
        <ModalFooter className="d-flex gap-2">
          <Button
            onClick={onHide}
            color="error"
            type="submit"
            variant="contained"
          >
            NO
          </Button>
          <Button type="" onClick={handlefavoritedelete} variant="contained">
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

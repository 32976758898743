import React, { useEffect, useState } from "react";
import apiClient from "../api/apiClient";
import { ArrowBackIos } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const Faq = () => {

  const [data, setdata] = useState([]);
  const [loader, setloader] = useState(true);
  const fetchfaqData  = async()=>{
    setloader(true)
    const result = await apiClient.get(`/faqs/`)
    setloader(false)
    if(!result.ok) return
  
     setdata(result?.data.results)
  }
const location =useLocation()
  useEffect(()=>{
    fetchfaqData()
  },[])
  const navigate= useNavigate()


  return (
    
    <div>
     {  location.pathname=="/faqs" ?   <p onClick={()=>navigate(-1)}>

<ArrowBackIos  className='m-2' sx={{color:"black"}}  />
  </p> :""}
     {loader? 
      <div className="d-flex justify-content-center mt-4">
        { location.pathname=="/faqs" &&  <CircularProgress color="secondary" />}
        </div>
        
        : <section className="">
        <div className="mycontainer py-5 py-sm-5">
          <div className="row justify-content-center">
            <div className="col-lg-10 ">
              <div className="row justify-content-between">
                <h2 className="fs-2 fw-normal text-center text-prpl">
                  {/* Frequently Asked Questions */}
                  Foire aux questions
                </h2>
              </div>
              <div className="accordion" id="accordionExample">
                
{data.map((item)=>(
 <div className="accordion-item my-3 border-0 shadow py-2 rounded-4 px-md-4">
 <h2
   className="accordion-header rounded-4 border-0 py-0"
   id="headingTwo"
 >
   <button
     className="accordion-button rounded-4 fs-6 fw-bold collapsed"
     type="button"
     data-bs-toggle="collapse"
     data-bs-target={`#collapse${item?.id}`}
     aria-expanded="false"
     aria-controls="collapseTwo"
   >
    {item?.question}
   </button>
 </h2>
 <div
   id={`collapse${item?.id}`}
   className="accordion-collapse pt-0 mt-0 collapse"
   aria-labelledby="headingTwo"
   data-bs-parent="#accordionExample"
 >
   <div className="accordion-body pt-0 mt-0 text-secondary">

  { item?.answer}

   </div>
 </div>
</div>
))}
                            </div>
            </div>
          </div>
        </div>
      </section>}
    </div>
  );
};

export default Faq;

import React from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

// Function to flatten JSON structure
const flattenJSON = (data) => {
  const result = data.map((item) => {
    const flattened = {};

    Object.keys(item).forEach((key) => {
      if (Array.isArray(item[key])) {
        flattened[key] = item[key].join(", "); // Convert arrays to comma-separated strings
      } else if (typeof item[key] === "object" && item[key] !== null) {
        Object.keys(item[key]).forEach((subKey) => {
          flattened[`${key}.${subKey}`] = item[key][subKey];
        });
      } else {
        flattened[key] = item[key];
      }
    });

    return flattened;
  });

  return result;
};

const ExportToExcel = ({ jsonData, width }) => {
  const exportToExcel = () => {
    // Flatten JSON data
    if(jsonData.length==0) return console.error("jsonData is empty");
    console.log(jsonData);
    const flattenedData = flattenJSON(jsonData);
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert JSON data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(flattenedData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate a binary string representation of the workbook
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Create a Blob from the binary string
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    // Save the Blob as an Excel file
    saveAs(blob, "data.xlsx");
  };

  return (
    <button
      onClick={exportToExcel}
      style={{
        backgroundColor: "#F8F5FB",
        border: "1px solid #E7E8F4",
        color: "#6a4a9f",
        padding: "12px 0",
        borderRadius: "10px",
        margin: "0 auto",
        width: width ?? "40%",
      }}
    >
      Export to Excel
    </button>
  );
};

export default ExportToExcel;

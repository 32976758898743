import { CircularProgress, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { FaLocationDot } from 'react-icons/fa6'
import { Link } from 'react-router-dom'
import { MdDelete } from "react-icons/md";
import { FiSearch } from 'react-icons/fi';
import apiClient from '../api/apiClient'
import Paginate from '../mui/Paginate'
import DeletePopup from './DeletePopup'
import { CiEdit } from 'react-icons/ci'

const limit=10;

export const Resturants = () => {
  const [restaurant, setrestaurantsData ] = useState([])
  const [page, setPage] = useState(1)
  const [count, setCount]=useState(0)
  const [deleteitemid, setdeleteitemid]=useState(null)
  const [loading, setLoading]=useState(false);
  const [deletemodale, setdeletemodale]=useState(false)
  const [filtercity, setfiltercity]=useState("")


  
  const params = new URLSearchParams();
  if(filtercity)
  params.append('name', filtercity);

  const fetchData  = async()=>{
    try {
      setLoading(true)
      const result = await apiClient.get(`/restaurants/?page=${page}&`+params.toString())
      if (result.ok) setrestaurantsData(result.data.results)
      
      setCount(result?.data.count);

      setLoading(false)

  
      console.log(result, "resttt detail")
    } catch (error) {
      
    }

  }

  const handlePageChange = (event, page) => {
    setPage(page);
  };


  useEffect(() => {
   fetchData()
  }, [page]);

  // console.log(restaurant, "jijiji")
const handleitem=(id)=>{
  setdeleteitemid(id)
  setdeletemodale(true)
}

   const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      fetchData();
    }
    if (event.target.value === '') {
      fetchData();
    }
  
  };

// console.log(deleteitemid,"mmmmm");
  return (
    <>
     <div className="">

    <div className=" right-mid mx-1 mx-sm-3 p-2">
    
      <div className="my-3">
        <div className="col-12 col-sm-7 p-0">
          <h2 className="mb-0 mx-2">Resturants</h2>
          {/* <p className="mb-0 mx-2">
            Welcome to your Dashboard. The Dashboard is a great place to monitor
            all the activity within the Hub giving you full control and
            oversight.
          </p> */}
        </div>

      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
        <div className='position-relative '>
          <input type="search" 
          value={filtercity}
          onKeyUp={handleKeyPress}
         onChange={(e) => setfiltercity(e.target.value)}

          className='form-control' placeholder='search' />
          <FiSearch  className='fs-4 position-absolute translate-middle ' style={{top:"50%", right:"15px", cursor:"pointer"}} onClick={fetchData}/>
        </div>
      </Grid>
      <Grid className='d-flex gap-2 justify-content-end align-items-center my-2' xs={12} md={6}>
      <Link
            to="/admin/addNewUserresturants"
            className="gradient text-decoration-none px-4   py-3 text-white rounded-3"
          >
    
          Add new restaurant
          </Link>
          <Link
            to="/admin/add-resturants"
            className="gradient text-decoration-none px-4   py-3 text-white rounded-3"
          >
               Rattacher à un restaurant existant

          </Link>
       
      </Grid>

        </Grid>
    </div>

{deletemodale&&    < DeletePopup url="restaurants" show={deletemodale}  fetchData={ fetchData} id={deleteitemid}  onHide={()=>setdeletemodale(false)}/>}
    
    { loading ? (
      <div className=" mx-auto text-center d-flex justify-content-center mt-5">
       <CircularProgress color="secondary" />
      </div>
    ): (
    <div className="row ">
    {restaurant?.map((item, index) => (

        <div key={index} className='col-md-3 rounded-4 '>

<Link to={`/admin/addmenus?id=${item?.id}`}>

          <div className='d-flex justify-content-center'>
            <div className=' rounded-4 p-3 ' style={{width:"15rem"}}>
              <img  
                style={{ width:"100%", height:"200px",objectFit:"cover",objectPosition:"top" }}

                className=' mx-auto rounded-4'
                src={item?.images[0]?.image} // Assuming item.images is an array containing image objects
                alt={item.name}
              />
            </div>
          </div>
          
          </Link>
          <div className='px-3 pb-5'>
            <div className='d-flex justify-content-between align-items-start'>
              <span>
                <p className='mb-1'> <FaLocationDot className='fs-4 mx-2'/>
                {item?.location}
                </p>
                <h4 className='text-prpl2'>{item?.name}</h4>
              </span>
              <div className="">
              <MdDelete  onClick={()=>handleitem(item?.id)} style={{fontSize:"20px"}}/>
           <Link to={`/admin/add-resturants?id=${item?.id}`} state={{state:item}}> 
               <CiEdit  className='text-dark'  style={{fontSize:"20px"}}/></Link>
           </div>
            </div>
            <p>
              {item?.description}
              {/* Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator. */}
            </p>
            {/* <Rating name="read-only" value={4} readOnly className='fs-2'/> */}
          </div>
        </div>
      ))}
    </div>
    
    )}
    
    <Paginate count={count} limit={limit} page={page} onChange={handlePageChange}/>



  </div>
    
    
    
    </>
  )
}

import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function MediaCard({item,handleCardClick}) {
  return (
    <Card sx={{ maxWidth: 345,width:345,boxShadow:"none" ,background:"none",cursor:"pointer"}}   onClick={() => handleCardClick(item?.id)} >
      <CardMedia
        sx={{ height: 250 }}
        image={ item?.images[0]?.image}
        title="green iguana"
      />
      <CardContent>
        <Typography className='text-center text-dark' gutterBottom variant="h5" component="div">
       {item.name}
        </Typography>
        
      </CardContent>
     
    </Card>
  );
}

import React, { useEffect, useState } from 'react'
import SimpleTable from '../mui/SimpleTable'
import apiClient from '../api/apiClient';
import { Button, Modal } from 'react-bootstrap';
import { Avatar } from '@mui/material';
import { toast } from 'react-toastify';

export const AdminReveiw = () => {
    const [modalShow, setModalShow] = React.useState(false);
    const [editid, seteditid] = React.useState(null);
    const [opinionsdata, setopinionsdata] = useState([]);
    const fetchopinionsData = async (e) => {
        const result = await apiClient.get(`/opinions/`);
        if (result.ok) setopinionsdata(result.data.results);
      };
    const columns = [
        { field: "user_image", headerName: "Image" },
        { field: "user_name", headerName: "User Name" },
     
        { field: "description", headerName: "Description" },
        { field: "id", headerName: "Action" },
        { field: "status_name", headerName: "Status" },
        
  
      ];
      const cellComponents = {
        id: null, // Default cell
        productName: null,
        description: null, 
        price: null,
        id: (value)=>(  <button className='btn text-light ' style={{backgroundColor:"#513384"}} onClick={()=>{setModalShow(true)
            seteditid(value)

        }} >Edit</button>),
        user_image: ({value})=>(< Avatar className='' style={{height:"50px",width:"50px",borderRadius:"100%"}} src={value} alt="" />)}
      

      useEffect(()=>{fetchopinionsData()},[])
 
     
    
  return (

    <div>
      
   { modalShow &&    
   
   <MyVerticallyCenteredModal
      opinionsdata={opinionsdata}
        show={modalShow}
        fetchopinionsData={fetchopinionsData}
        editid={editid}
        onHide={() => setModalShow(false)}
      />}
           <SimpleTable
    columns={columns}
    data={opinionsdata}
    cellComponents={cellComponents}
    
  />
  
  
  </div>
  )
}

function MyVerticallyCenteredModal(props) {
const {opinionsdata, editid,fetchopinionsData} = props


const [statue, setstatus] = useState("");
const [review, setReview] = useState("");
const [loader, setloader] = useState(false);
console.log(statue);
useEffect(() => {
  console.log(editid);
  console.log(opinionsdata);

  // Ensure opinionsdata is an array and editid is valid
  if (opinionsdata && Array.isArray(opinionsdata) && editid) {
    const des = opinionsdata.find((item) => item?.id === editid?.value);
    console.log(des);

    // Safely update the state if the object is found
    if (des) {
      setReview(des?.description);
      setstatus(des?.status);
   
    } else {
      setReview(""); // Optionally reset if no object is found
    }
  } else {
    console.error("Invalid opinionsdata or editid");
  }
}, [editid, opinionsdata]);

const handelSubmit =async ()=>{
    setloader(true)
    const res = await apiClient.patch(`opinions/${editid?.value}/`,{"description": review,"status":statue})
    setloader(false)
    if (!res.ok)  return
        
    
    console.log(res);
    fetchopinionsData()
    props.onHide()
    toast.success("Review update successfully");
}

    return (
      <Modal
        style={{zIndex:9999999}}
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
        Edit Review
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <input onChange={(e)=>setReview(e.target.value)} value={review} placeholder='Review' className='form-control' type="text" />
        <select value={statue}  onChange={(e)=>setstatus(e.target.value)}  className="form-select mt-3" aria-label="Default select example">
  
  <option value="A">Approve</option>
  <option value="R">Rejected</option>
  <option value="P">Pending</option>
</select>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn text-light'style={{backgroundColor:"#513384"}} onClick={handelSubmit}>{loader?"Save...":"Save"}</button>
        </Modal.Footer>
      </Modal>
    );
  }
import React, { useEffect, useState } from "react";
import { MdLocationPin } from "react-icons/md";
import { FaBowlFood } from "react-icons/fa6";
import { TbCurrencyDollar } from "react-icons/tb";
import { FaSearch } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { TbBrandFacebookFilled } from "react-icons/tb";
import { GrInstagram } from "react-icons/gr";
import { Autocomplete, Paper, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import apiClient from "../api/apiClient";
import RangeSlider from "../pages/RangeSlider";

const SearchPopup = (props) => {
  const [average_price, setaverage_price] = useState("");
  const [type, settype] = useState("");
  const [city, setcity] = useState("");
  const navgater = useNavigate();
  const handleCityChange = (event, value) => {
    setcity(value);
  };
  const hanldeserach = () => {
    navgater(
      `/filter-restaurant?city=${city}&type=${type}`
    );
   
    props.setSearchmodalShow(false);
    // setaverage_price("");
    settype("");
    setcity("");
  };
  const [citydata, setcitydata] = useState([]);
  const fetchcity = async () => {
    const result = await apiClient.get("/city/");
    if (result.ok) setcitydata(result.data.results);
  };

  useEffect(() => {
    fetchcity();
  }, []);
  {
    console.log(citydata);
  }
 
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setdata] = useState([]);
  const [error, setError] = useState(null);
 
  const [valueRange, setvalueRange] = React.useState([50, 700]);

  const fetchmenuData = async () => {
    try {
        const result = await apiClient.get(`/cuisines/?limt=20`);
        if (!result.ok) return;
        setdata(result.data.results);
    } catch (error) {
        console.error('Error fetching menu data:', error);
    }
};

useEffect(() => {
    fetchmenuData();
}, []);

useEffect(() => {
  const preventTouchMove = (e) => {
    e.preventDefault();
  };

  if (props.show) {
    // Add event listener to prevent touch move on iOS
    document.body.addEventListener('touchmove', preventTouchMove, { passive: false });
  } else {
    document.body.removeEventListener('touchmove', preventTouchMove);
  }

  return () => {
    document.body.removeEventListener('touchmove', preventTouchMove);
  };
}, [props.show]);
const onHide = () => {
  settype("");
  setcity("");
  props.setSearchmodalShow(false);
};
  return (
    <div>
      <Modal
        {...props}

        size="lg"
        onHide={onHide} // Reset state on modal close
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
        className="rounded-corner-modal"
      >
        <section className=" my-sm-4 ">
          <div className=" mycontainer">
            <div className=""></div>
            <div className=" row bg-hero position-relative align-items-center py-5 bg-prpl2 radius-hero ">
              <div className="col-12 px-0 rounded-4 h-100 px-4 px-md-5 px-md-4 px-lg-5 position-relative">
                <h1 className=" fs-1 fw-md text-center text-sm-start text-uppercase text-white">
                  {/* Découvrez et réservez les meilleurs restaurants */}
                  {props?.search_title}
                  <br />
                  {/* <span className="outline-text">
                  autours de vous
                </span> */}
                </h1>
              </div>
              <div className="col-sm-6 col-lg-5 px-4 position-relative ">
                <img
                  alt=""
                  className="w-100 d-none px-3 px-sm-0 px-4 px-xl-5"
                  // src="img/home-img.png"
                />
              </div>
              <div className="col-sm-11 col-md-12 col-lg-10 position-relative px-4 px-md-5 px-md-4 px-lg-5 mt-3">
                <form action="">
                  <div className="row mx-0 justify-content-between bg-prpl4 py-3 py-md-4 px-2 px-lg-3 rounded-4">
                    <div className="col-md-3 my-1 my-md-0 col-sm-6">
                      <div className="d-sm-block d-none">
                        <div className=" bg-prpl5 rounded-3 filter-border d-flex align-items-center px-3">
          
                          <MdLocationPin color="white" size={26} />

                          {/* <i className="fa-solid fa-location-dot text-white" /> */}
                        </div>
                      </div>

                      <div className="d-sm-none d-block ">
                        {/* <div className="bg-white text-dark rounded-3 filter-border d-flex align-items-center px-3">
                          <Autocomplete
                            style={{ color: "white" }}
                            disablePortal
                            id="combo-box-demo"
                            className=""
                            options={citydata}
                            PaperComponent={({ children }) => (
                              <Paper style={{ background: "#6A4A9F",color:"white" }}>{children}</Paper>
                            )}
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, option) => (
                              <li className="text-light" {...props}>
                                {option.name}
                              </li>
                            )}
                            sx={{ width: 300, color: "white" }}
                            renderInput={(params) => (
                              <TextField
                                className="text-light "
                                style={{ color: "white" }}
                                {...params}
                                label="Localisation"
                              />
                            )}
                            onChange={handleCityChange}
                            value={city}
                          />
                        
                          <MdLocationPin color="black" size={26} />
                        </div> */}
                      </div>
                    </div>

                    <div className="col-md-3 my-1 my-md-0 col-sm-6">
                    

                      <div className="d-sm-none d-block">
                      <div className="bg-input rounded-3 filter-border d-flex align-items-center px-3 mb-2 ">
                       
                       <select
 className="form-select custom-select-style py-3"
 style={{
   width: "100%",
   border: "0px",
   outline: "none",
 }}
 aria-label="Default select example"
 value={city}
 onChange={(e)=>setcity(e.target.value)}
>
 <option>Localisation              </option>
 {citydata.map((e) => (
   <option key={e.id}    value={e.name}>
     {e?.name}
   </option>
 ))}
</select>

<MdLocationPin color="black" size={26} />
                       </div>
                        <div className="bg-input rounded-3 filter-border d-flex align-items-center px-3 ">
                       
                        <select
  className="form-select custom-select-style py-3"
  style={{
    width: "100%",
    border: "0px",
    outline: "none",
  }}
  aria-label="Default select example"
  value={type}
  onChange={(e) => settype(e.target.value)}
>
  <option>Cuisine              </option>
  {data.map((e) => (
    <option key={e.id} value={e.id}>
      {e?.name}
    </option>
  ))}
</select>

                          <FaBowlFood color="black" size={26} />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 my-1 my-md-0 col-sm-6 d-sm-block d-none">
                      <div className="bg-prpl5 rounded-3 filter-border d-flex align-items-center px-3">
                        <input
                          onChange={(e) => setaverage_price(e.target.value)}
                          className="w-100  text-white bg-transparent focus-none pe-2 placeholder-white border-0 py-3 "
                          placeholder="Prix moyen"
                          type="number"
                        />
                        {/* <i className="fa-solid fa-dollar-sign text-white" /> */}
                        <TbCurrencyDollar color="white" size={26} />
                      </div>
                    </div>
                    <div className="col-md-3 my-1 my-md-0 col-sm-6">
                      <div
                        className="bg-prpl3 rounded-3 filter-border d-sm-block d-none "
                        onClick={hanldeserach}
                      >
                        <button
                          Autres
                          villes
                          au
                          Maroc
                          className="text-white  d-flex align-items-center focus-none  px-3 justify-content-between bg-transparent pe-3 border-0 py-3 w-100"
                          type="submit"
                        >
                          <span className="me-2 ">Recherche  </span>
                          <FaSearch />
                          {/* <i className="fa-solid fa-magnifying-glass text-white" /> */}
                        </button>
                      </div>
                      {/* < RangeSlider value={valueRange} setvalue={ setvalueRange} /> */}
                      <div className="bg-prpl3 rounded-3 filter-border d-sm-none d-block">
                        <div
                          onClick={hanldeserach}
                          className="text-white text-center focus-none  px-3 bg-transparent pe-3 border-0 py-2 w-100"
                          type="submit"
                        >
                          Search
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div id="map" />
              </div>
              <div className="position-absolute social-hero bg-prpl3 d-sm-block d-none px-3 py-4 d-flex align-items-center flex-column">
                <a className="text-prpl text-decoration-none fs-5" href="#">
                  {/* <i className="fa-brands fa-x-twitter fs-5" /> */}
                  <FaXTwitter size={26} />
                </a>
                <a className="text-prpl text-decoration-none fs-5" href="#">
                  <TbBrandFacebookFilled size={26} />
                  {/* <i className="fa-brands fa-facebook-f fs-5" /> */}
                </a>
                <a className="text-prpl text-decoration-none fs-5" href="#">
                  <GrInstagram size={26} />

                  {/* <i className="fa-brands fa-instagram fs-5" /> */}
                </a>
              </div>
            </div>
          </div>
        </section>
      </Modal>
    </div>
  );
};

export default SearchPopup;

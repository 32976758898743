import { Button } from "@mui/material";

import { Modal, ModalFooter } from 'react-bootstrap';

const ErrorMessagePopup = ({show,onHide,errormessage,apierrormessage,fetch,setHover}) => {
  

  return (


    <>
    <Modal
           show={show}
           onHide={ ()=> {onHide()
            // fetch()
           }}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ zIndex: "1300" }}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
                
               
              </Modal.Title>
            </Modal.Header>
           
            <Modal.Body style={{textAlign:"center"}}>
            {errormessage}
     
            </Modal.Body>
            <div className="d-flex justify-content-center gap-2 mb-2">
                <Button   style={{backgroundColor:"#442479",color:"white"}} onClick={ ()=> {onHide()
              if(typeof fetch === "function"){

                fetch()
              }

              }}  type="submit" variant="contained">Close</Button>
                {/* <Button type="" variant="contained">Yes</Button> */}

            </div>
            
            </Modal>
    </>
  )
}

export default ErrorMessagePopup
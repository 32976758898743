import { Box, Button, Container, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";

import { Modal, ModalFooter } from 'react-bootstrap'
import { handleErrors } from "../modules/handleErrors";
import apiClient from "../api/apiClient";
import Rating from '@mui/material/Rating';
import { useState } from "react";
import { toast } from "react-toastify";

const ReviewPopup = ({show,onHide,id,url, fetchData}) => {
   
    const [description, setdescription] = useState("");
  const handlefavoritedelete = async () => {
    if(description.trim()=="") return  toast.error("Add review")

    try {
        const response = await apiClient.post(`/opinions/`,{
           "description":description
        } )
        if(!response.ok) return handleErrors(response.data);
        toast.success(" Thank you for your review! It has been submitted successfully.")
         fetchData()
         onHide()
        

        console.log(response); 
    } catch (error) {
        console.error('Error:', error);
    }
  };
  return ( 


    <>
    <Modal
           show={show}
           onHide={onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ zIndex: "1300" }}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
               
              </Modal.Title>
            </Modal.Header>
           
            <Modal.Body className="flex-column  justify-content-between align-items-center">
        
        
   
<div class="form-floating">
  <textarea class="form-control" onChange={(e)=>setdescription(e.target.value)} value={description} placeholder="Leave a comment here" id="floatingTextarea2" style={{height:"100px"}}></textarea>
  <label for="floatingTextarea2">Review</label>
</div>
            </Modal.Body>
            <ModalFooter className="d-flex gap-2 justify-content-center     ">
              
                <Button className="text-light" type="" style={{backgroundColor:"#442479"}} onClick={handlefavoritedelete} variant="contained">Submit</Button>

            </ModalFooter>
            
            </Modal>
    </>
  )
}

export default ReviewPopup
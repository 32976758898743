import { CircularProgress, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FiSearch } from 'react-icons/fi'
import apiClient from '../api/apiClient'
import Paginate from '../mui/Paginate'
import DeletePopup from './DeletePopup'
import { MdDelete } from 'react-icons/md'
import { CiEdit } from "react-icons/ci"
import Spinner from '../modules/Spinner'

const limit=10;
const Cities = () => {
  const [city, setcitydata] = useState([])
  const [deleteitemid, setdeleteitemid]=useState(null)

  const [deletemodale, setdeletemodale]=useState(false)
  const [page, setPage] = useState(1)
  const [count, setCount]=useState(0)
  const [loading, setLoading]=useState(false);
  const [filtercity, setfiltercity]=useState("");


  const handleitem=(id)=>{
    setdeleteitemid(id)
    setdeletemodale(true)
  }
  const fetchData  = async()=>{

    try {
      setLoading(true)
      const result = await apiClient.get(`/city/?page=${page}&name=${filtercity}`)
      if (result.ok) setcitydata(result.data.results)
      setCount(result?.data.count);
      setLoading(false)
  
    } catch (error) {
      
    }
   
  }

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  useEffect(() => {

    fetchData()

  }, [page]);
 
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      fetchData();
    }
    if(event.target.value==""){    fetchData();}
  };

  // console.log(city, "jijiij")



    // const data = [
    //     {
    //       img: itemImage,
    //       name: "Marrakech",
    //     },
    //     {
    //       img: itemImage2,
    //       name: "Rabat",
    //     },
    //     {
    //       img: itemImage3,
    //       name: "Tanger",
    //     },
    //     {
    //       img: itemImage4,
    //       name: "Agadir",
    //     },
    //     {
    //       img: itemImage5,
    //       name: "Essaouira",
    //     },
    //   ];
 
    return (
        <div className="">
{deletemodale&&    < DeletePopup url="city" show={deletemodale}  fetchData={ fetchData} id={deleteitemid}  onHide={()=>setdeletemodale(false)}/>}
        <div className="right-mid mx-1 mx-sm-3 p-2">
        
          <div className="my-3">
            <div className="col-12 col-sm-7 p-0">
              <h2 className="mb-0 mx-2">Cities</h2>
              <p className="mb-0 mx-2">
                City list
              </p>
            </div>
    
          </div>
    
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
            <div className='position-relative '>
              <input type="search" 
               onKeyUp={handleKeyPress}
              value={filtercity}
             onChange={(e) => setfiltercity(e.target.value)}
    
              className='form-control' placeholder='search' />
              <FiSearch  className='fs-4 position-absolute translate-middle ' style={{top:"50%", right:"15px", cursor:"pointer"}} onClick={fetchData}/>
            </div>
          </Grid>
          <Grid className='d-flex justify-content-end align-items-center my-2' xs={12} md={6}>
              <Link
                to="/admin/add-city"
                className="gradient text-decoration-none px-4   py-3 text-white rounded-3"
              >
                Add New City
              </Link>
          </Grid>
    
            </Grid>
            
        </div>


    
        { 
        loading ? (
          <div className=" mx-auto text-center d-flex justify-content-center mt-5">
        <CircularProgress color="secondary" />
      </div>
        ) : (

          <div className="row ml-2">
        {city.map((item, index) => (
        <div key={index} className=' col-md-3  rounded-4 card-slider'>
        {/* <span className='p-3'> */}
                  <span className='rounded-2'>
                    <span className=''>
                    <div className='bg-img rounded-3 position-relative' style={{backgroundImage:`linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${item.image})`}}>

                    <div className='position-absolute text-white' style={{bottom:"10px", left:"10px", }}>
                        {item.name}
                    </div>
                    <div className='position-absolute text-white' style={{top:"10px", right:"10px", }}>
                    <MdDelete  onClick={()=>handleitem(item?.id)} style={{fontSize:"20px"}}/>
                    </div>
                    <div className='position-absolute text-white' style={{top:"30px", right:"10px", }}>
                   <Link to={`/admin/add-city?id=${item?.id}&name=${item?.name}`} state={{state:item?.image}}>  <CiEdit  className='text-light'  style={{fontSize:"20px"}}/>  </Link>
                    </div>
                    </div>
                    {/* </span> */}
                  </span>
                </span>
                
              </div>
            ))}
        </div>

        )
        }

        <Paginate count={count} limit={limit} page={page} onChange={handlePageChange}/>



      </div>
    );
}

export default Cities;

import { CircularProgress, Rating } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BsReceiptCutoff } from "react-icons/bs";
import { IoChevronForward } from "react-icons/io5";
import apiClient from "../api/apiClient";
import Ratings from "./Rating";
import { CiEdit } from "react-icons/ci";
import HomeModal from "../components/HomeModal";
import { toast } from "react-toastify";
import { Edit } from "@mui/icons-material";
import { handleErrors } from "../modules/handleErrors";
import ErrorMessagePopup from "../components/ErrorMessagePopup";
import HomeModalNested from "../components/HomeModalNested";

export const UpcomingBooking = () => {
  const [bookingdata, setbookingdata] = useState([]);
  const [spiner, setspiner] = useState(true);
  const [deleteitemid, setdeleteitemid] = useState(null);
  const [modalshow, setmodalshow] = useState(false);
  const [showmodel, setshowmodel] = useState(false);
  const [stair, setstair] = useState(null);
  const [reviewerrormsg, setreviewerrormsg] = useState("");

  const [singlebooking, setsinglebooking] = useState(null);
  const handelmodalbooking = (item) => {
    setmodalshow(true);
    setsinglebooking(item);
  };
  const [deletemodale, setdeletemodale] = useState(false);
  const fetchData = async () => {
    setspiner(true);
    const result = await apiClient.get("/bookings/?status=u");
    setspiner(false);
    if (result.ok) {
      setbookingdata(result.data.results);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleitem = (id) => {
    setdeleteitemid(id);
    setdeletemodale(true);
  };

  const handlenoreview = () => {
    toast.error("Cannot submit review. Please complete the booking first.");
  };
  const handlefavoriterating = async (value, id) => {
    try {
      const response = await apiClient.post(`/reviews/`, {
        rating: value,
        restaurant: id,
      });

      if (!response.ok) return handleErrors(response.data);
      toast.success("Review submitted successfully");
      fetchData();

      console.log(response);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handlesubmitrating = (value, id, e) => {
    if (e?.status == "CA") {
      return (
        setreviewerrormsg("Cannot submit review for a canceled booking"),
        setshowmodel(true)
      );
    } else if (e?.status == "PE") {
      if (e.date_passed) {
        return (
          setreviewerrormsg("Cannot submit review for a canceled booking"),
          setshowmodel(true)
        );
      } else {
        return (
          setreviewerrormsg(
            "Cannot submit review. Please complete the booking first."
          ),
          setshowmodel(true)
        );
      }
    }

    handlefavoriterating(value, id);

    setstair(value);
  };

  return (
    <>
      {spiner ? (
        <div className="d-flex justify-content-center mt-4">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <section className="  ">
          <div className="mycontainer py-5">
            <div className="row">
              {!spiner && bookingdata.length > 0 ? (
                <h4>Réservations passés et annulés</h4>
              ) : (
                ""
              )}

              {bookingdata.map((e) => {
             

                return (
                  <div className="col-md-6 mt-3" key={e?.id}>
                    <div className="shadow border rounded-3 p-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center gap-2">
                          <div>
                            {/* <img src={require("../assets/glry1.png")} alt="" className="g-img" /> */}
                            <img
                              src={e.menu[0]?.picture}
                              alt=""
                              className="g-img"
                            />
                          </div>
                          <div>
                            {/* <h6 className="mb-0 test">    { e?.menu[0]?.name }  </h6> */}
                            <p className="mb-0">
                              <BsReceiptCutoff className="me-1" />
                              {e?.restaurant_details?.name}
                            </p>
                            <div className="mt-2">
                              <p className="font-12 max bg-prpl py-1 px-2 text-white rounded-pill mb-0">
                                {e.date_passed ? "Annulé" : e?.status_display}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div></div>
                      </div>
                      <div className="mt-2">
                        <button className="w-100 border p-2 rounded-2 fw-bold">
                          {e.date_display}. {e.formatted_date}. {e.persons}
                          personnes
                        </button>
                      </div>
                      <div className="mt-2">
                        <p className="text-center mb-0">
                          Comment s'est passée votre expérience ?
                        </p>
                        <div className="d-flex justify-content-center">
                          {e?.user_rating ? (
                            <Rating
                              readOnly
                              name="no-value"
                              value={e?.user_rating}
                              onChange={(event) => handlesubmitrating()}
                              className="fs-5"
                            />
                          ) : (
                            <Rating
                              name="no-value"
                              value={e?.user_rating}
                              onChange={(event) =>
                                handlesubmitrating(
                                  event.target.value,
                                  e?.restaurant_details?.id,
                                  e
                                )
                              }
                              className="fs-5"
                            />
                          )}
                        </div>
                        {/* {e?.status=="CM"? <button className='btn'style={{color:"white", backgroundColor:"#442479"}} onClick={()=> handleitem(e?.restaurant_details.id)}>notation</button> : <button onClick={handlenoreview} className='btn'style={{color:"white", backgroundColor:"#442479"}}>notation</button>} */}

                        {e.date_passed || (
                          <div className="">
                            {e?.status == "CA" || (
                              <div
                                className="d-flex flex-column justify-content-center align-items-center pointer"
                                onClick={() => handelmodalbooking(e)}
                              >
                                <CiEdit className="fs-4 text-prpl" />
                                <p className="mb-0 text-prpl fw-bold">
                                  Modifier
                                </p>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {modalshow && (
              <HomeModalNested
                fetchbooking={fetchData}
                show={modalshow}
                singlebooking={singlebooking}
                onHide={() => setmodalshow(false)}
              />
            )}
            {showmodel && (
              <ErrorMessagePopup
                errormessage={reviewerrormsg}
                fetch={fetchData}
                show={showmodel}
                onHide={() => setshowmodel(false)}
              />
            )}
          </div>
          {/* < Ratings  url="reviews" show={deletemodale}  fetchData={ fetchData} id={deleteitemid}  onHide={()=>setdeletemodale(false)}/> */}
        </section>
      )}

      {!spiner && bookingdata.length === 0 ? (
        <div>
          <h5 className="text-center">Pas de réservation</h5>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FaFacebook } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import apiClient from '../api/apiClient';


const SocialMedia = () => {
  const [social,setsocial]=useState([])


  const fetchcityData  = async()=>{
      const result = await apiClient.get('/social/')
      if (result.ok) setsocial(...result.data.results)
    
    }
  

    useEffect(()=>{
    
      fetchcityData()

    },[])
    return (
        <div>
            <div className='my-3'>

            {/* <Grid container spacing={2}> */}
            {/* <Grid item xs={12} md={6}>
            <div className='position-relative '>
              <input type="search" 
              // value={name}
            //  onChange={(e) => setSearchName(e.target.value)}
    
              className='form-control' placeholder='search' />
              <FiSearch  className='fs-4 position-absolute translate-middle ' style={{top:"50%", right:"15px", cursor:"pointer"}}/>
            </div>
          </Grid> */}
          <Grid className='d-flex justify-content-end align-items-center' xs={12} >
              <Link
                to="/admin/add-media"
                className="gradient text-decoration-none px-4   py-3 text-white rounded-3"
              >
                Add Social Media
              </Link>
          </Grid>
    
            {/* </Grid> */}
            
            <div className=' my-3 d-flex align-items-center text-white gap-5 col-md-5 p-2' style={{backgroundColor:"#543586"}}>
                <FaFacebook size={28}/>
                <Link to={social?.facebook} target="_blank">
                <p className='mb-0'>
                    www.facebook.com
                </p>
                </Link>
            </div>


            <div className=' my-3 d-flex align-items-center text-white gap-5 col-md-5 p-2' style={{backgroundColor:"#543586"}}>
                <FaInstagramSquare size={28}/>
                <Link to={social?.insta} target="_blank">
                <p className='mb-0'>
                    www.Instagram.com
                </p>
                </Link>
            </div>

            <div className=' my-3 d-flex align-items-center text-white gap-5 col-md-5 p-2' style={{backgroundColor:"#543586"}}>
                <FaSquareXTwitter size={28}/>
                <Link to={social?.twitter} target="_blank">
                <p className='mb-0'>
                    www.twitter.com
                </p>
                </Link>
            </div>

            </div>
        </div>
    );
}

export default SocialMedia;

import React from 'react'

export const Favorite = () => {
  return (
    <div><section className=" first-sec ">
    <div className="mycontainer py-5">
      <h4>
        My Favourites
      </h4>
      <div className="row">
        <div className="col-md-4 col-sm-6 mt-3">
          <div className="border shadow rounded-3 p-3 d-sm-block d-flex align-items-center">
            <div>
              <img
                alt=""
                className="con-img"
                src={require("../assets/contact1.png")}
              />
            </div>
            <div className="mt-sm-3 ps-sm-0 ps-3">
              <h5>
                Paris
              </h5>
              <p className="mb-0">
                <i className="bi bi-lock-fill" />
                {' '}Private list
              </p>
              <p className="mb-0">
                0 restaurant . Updated Mar 20, 2024
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-6 mt-3">
          <div className="border shadow rounded-3 p-3 d-sm-block d-flex align-items-center">
            <div>
              <img
                alt=""
                className="con-img"
                src={require("../assets/contact1.png")}
              />
            </div>
            <div className="mt-sm-3 ps-sm-0 ps-3">
              <h5>
                Paris
              </h5>
              <p className="mb-0">
                <i className="bi bi-lock-fill" />
                {' '}Private list
              </p>
              <p className="mb-0">
                0 restaurant . Updated Mar 20, 2024
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-6 mt-3">
          <div className="border shadow rounded-3 p-3 d-sm-block d-flex align-items-center">
            <div>
              <img
                alt=""
                className="con-img"
                src={require("../assets/contact1.png")}
              />
            </div>
            <div className="mt-sm-3 ps-sm-0 ps-3">
              <h5>
                Paris
              </h5>
              <p className="mb-0">
                <i className="bi bi-lock-fill" />
                {' '}Private list
              </p>
              <p className="mb-0">
                0 restaurant . Updated Mar 20, 2024
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  
  </div>
  )
}

import React, { useEffect, useState } from "react";
import SimpleTable from "../mui/SimpleTable";
import apiClient from "../api/apiClient";
import ExportToExcel from "../restaurent-pages/ExportToExcel";

export const AllBooking = () => {
  const [Booking, setbooking] = useState([]);
  const fetch = async () => {
    const res = await apiClient.get("/bookings/?page_size=5000");
    if(!res.ok) return
    setbooking(res?.data.results);
  };

  useEffect(() => {
    fetch();
  }, []);
  const columns = [
    { field: "user_name", headerName: "User Name" },
    { field: "status_display", headerName: "Status Display" },
    { field: "date_display", headerName: "Booking Date" },
    { field: "persons", headerName: "persons" },
  ];

  const cellComponents = {
    id: null, // Default cell
    productName: null,
    description: null, // Adjust description rendering
    price: null,
    action: null,
  };

  return (
    <div>
      <SimpleTable
        columns={columns}
        data={Booking.map((booking) => ({
          ...booking,
          user_name: booking?.user_booked?.username,
        }))}
        cellComponents={cellComponents}
      />
      <div className="mt-2">

        <ExportToExcel width={"100%"} jsonData={Booking} />
      </div>
    </div>
  );
};

const listItemStyle={
    color: "black",
    "&.active": { background: "linear-gradient(360deg, rgba(68, 36, 121,1) 30%, rgba(121, 92, 169, 0.88) 100%)", color: "red",
    "& .myIconClass, & .MuiTypography-root": {
      color: "white"
    } }
  }
  const buttonStyle={
  
  "&:hover": {
    color: "black",
    "& .myIconClass, & .MuiTypography-root": {
      // color: "white"
    }
  }
  }

  export {listItemStyle,buttonStyle}
import { AppBar, Avatar, Hidden, IconButton, Toolbar } from '@mui/material';
import React from 'react';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu'
import { Link } from 'react-router-dom';
import { IoPower } from "react-icons/io5";
import { PiNotificationBold } from 'react-icons/pi';


function Navbar({ handleDrawerToggle }) {
  // const [modalShow, setModalShow] = useState(false);
  // const location = useLocation()






  return (
    <Box className="" >



      <AppBar className='' position="fixed" sx={{ boxShadow: "0" }}>
        <Toolbar className='' style={{ display: 'flex', justifyContent: 'space-between' }} >
          <Box className=' d-flex  align-items-center justify-content-between w-100 p-2 rounded-2 nav-style'>
            <Box className="  d-flex justify-content-start align-items-center" >

              <h5 className=' my-0 mx-3 fw-bold text-prpl'>  Welcome Back</h5>
            </Box>
            <div className='d-flex align-items-center'>
            {/* <Link to={'/admin/notifications'}><PiNotificationBold className='fs-4 mx-2' style={{color:"#003B93"}}/></Link> */}
            {/* <IoPower  className='fs-4 mx-2 me-3' style={{color:"#003B93"}}/> */}
              {/* <Avatar alt="Remy Sharp" src='' /> */}


              <Hidden
                mdUp
              >

                <IconButton onClick={handleDrawerToggle}>
                  <MenuIcon style={{ color: "black" }} />
                </IconButton>
              </Hidden>
            </div>




          </Box>
        </Toolbar>
      </AppBar>

    </Box>
  );
}

export default Navbar;

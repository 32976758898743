import { CircularProgress, Rating, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { getCurrentURLParameters } from "../modules/helpers";
import apiClient from "../api/apiClient";
import { handleErrors } from "../modules/handleErrors";
import { Link, useLocation } from "react-router-dom";
import { SyncLoader } from "react-spinners";

const FilderRestaurent = ({}) => {
  const [ResturentdDta, setResturentdDta] = useState([]);

  const [isfavloader, setisfavloader] = useState(false);
  const [isfavloaderid, setisfavloaderid] = useState(null);
  const [loader, setloader] = useState(true);

  const loction = useLocation();
  const { city, type, min, max, id } = getCurrentURLParameters();
  console.log(city, "jijkjkjkjk");
  const params = new URLSearchParams();
  if (min) {
    params.append("min_price", min);
  }
  if (max) {
    params.append("max_price", max);
  }

  if (city && city !== "undefined") {
    params.append("city", city);
  }
  if (type) {
    params.append("type", type);
  }
  if (id) {
    params.append("id", id);
  }

  console.log(params, "params");

  const fetchData = async (e) => {
    // e.preventDefault();
    setloader(true);
    const result = await apiClient.get(`/restaurants/?` + params.toString());
    setloader(false);
    if (result.ok) setResturentdDta(result.data.results);
    // console.log(result, "city detail")
  };
  useEffect(() => {
    fetchData();
    
  }, [type, city, loction.search]);

  const handlefavoritedelete = async (id, itemid) => {
    try {
      setisfavloaderid(itemid);

      setisfavloader(true);

      const response = await apiClient.delete(`/favorite-restaurants/${id}/`);
      setisfavloader(false);

      if (!response.ok) return handleErrors(response.data);
      fetchData();

      console.log(response);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handlefavorite = async (id) => {
    try {
      setisfavloaderid(id);

      setisfavloader(true);

      const response = await apiClient.post("/favorite-restaurants/", {
        restaurant: id,
      });
      setisfavloader(false);

      if (!response.ok) return handleErrors(response.data);
      fetchData();
      //    setisfav(response?.data?.restaurant)
      console.log(response?.data?.restaurant?.is_favorite);

      console.log(response);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      {loader ? (
        <div className="d-flex justify-content-center mt-4">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <div>
          <section class="bg-ltw  ">
            <div class="mycontainer py-3 py-sm-5">
              <div class="row justify-content-between ">
                <h2 class="fs-1 fw-normal text-center text-prpl">
                Voici la sélection qui correspond à votre recherche !
                </h2>
              
              </div>
              <div class="row ">
                {ResturentdDta.length > 0 ? (
                  ResturentdDta?.map((item) => {
                    return (
                      <div class="col-12 col-sm-6 col-md-4 mt-3">
                        <div class="p-2 pointer" onclick="">
                          <div class="card p-3 pb-5 rounded-4 shadow-sm border-0">
                            <Link to={`/detail?id=${item.id}`}>
                              {" "}
                              <img
                                style={{ width: "100%", height: "220px" }}
                                src={item?.images[0]?.image}
                                class="card-img-top rounded-4"
                                alt="/img/ist.jpeg"
                              />
                            </Link>
                            <div class="card-body">
                              <div class="d-flex align-items-center justify-content-between">
                                <div>
                                  <div class="d-flex align-items-center">
                                    <FaLocationDot className="fa-solid text-prpl fa-location-dot fs-5" />
                                    <p class="mb-0 text-black mx-2">
                                      {item?.city_name?.name}
                                    </p>
                                  </div>
                                  <p class="mb-0 text-prpl fw-md fs-5">
                                    {item?.name}
                                  </p>
                                </div>
                                {isfavloader && isfavloaderid == item.id ? (
                                  <SyncLoader size={10} color="#442479" />
                                ) : item.is_favorite ? (
                                  <FaHeart
                                    className="fs-4"
                                    onClick={() =>
                                      handlefavoritedelete(item.fav_id, item.id)
                                    }
                                    style={{ color: "#442479" }}
                                  />
                                ) : (
                                  <FaRegHeart
                                    className="fs-4"
                                    onClick={() => handlefavorite(item.id)}
                                  />
                                )}
                              </div>
                              <p class="card-text">{item?.description}</p>
                              <Stack spacing={1}>
                                <Rating
                                  name="half-rating-read"
                                  defaultValue={item?.average_rating}
                                  precision={0.5}
                                  readOnly
                                />
                              </Stack>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p className="w-100 text-center mt-3 fst-italic">
                    Rien trouvé ici
                  </p>
                )}
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
};

export default FilderRestaurent;

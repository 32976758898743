import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { NavLink, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
// import { logout } from '../../redux/counterSlice'
import { buttonStyle, listItemStyle } from "./SideNavStyles";
import { logout } from "../redux/authSlice";
import { TfiWorld } from "react-icons/tfi";
import { HiOutlineUsers } from "react-icons/hi";
import { selectAdminData } from "../redux/adminSlice";
import { useEffect, useState } from "react";
import apiClient from "../api/apiClient";

// import { useDispatch } from "react-redux";

const drawerWidth = 210;
const listItemData = [
  {
    label: "Dashboard",
    link: `/restaurant/dashboard`,
    icon: <HiOutlineUsers style={{ fontSize: "20px" }} />,
  },
  {
    label: "Reservation Management",
    link: "/restaurant/ReservationManagement",
    icon: <TfiWorld style={{ fontSize: "20px" }} />,
  },
  {
    label: "Profile",
    link: "/restaurant/profile",
    icon: <TfiWorld style={{ fontSize: "20px" }} />,
  },
  {
    label: "Notification",
    link: "/restaurant/notifications",
    icon: <TfiWorld style={{ fontSize: "20px" }} />,
  },
 
];

function SideNav(props) {
  // const [show, setShow] = useState(false)

  const [restaurantsData, setRestaurantsData] = useState([]);
  const [Selectrestaurant, setSelectrestaurant] = useState("");

  const fetchData = async () => {
    try {
      // setLoading(true);
      const result = await apiClient.get(`/owner-restaurant/restaurant_info/`);
      if (result.ok) setRestaurantsData(result.data.restaurants);
      console.log(result.data.restaurants);
      // setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  function handleCardClick(id) { 
    console.log("Restaurant selected with ID:", id);
    setSelectrestaurant(id)
    // Save the selected restaurant ID to localStorage
    localStorage.setItem('selectedRestaurantId', id); 
    
    // Reload the page to apply changes
    window.location.reload(); 
}
   const seveid= localStorage.getItem('selectedRestaurantId'); 
  console.log(seveid);
  // const { window } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const adminData = useSelector(selectAdminData);
  const drawer = (
    <div className="side-nav-outer-rounded ">
      <div className="" style={{ height: "100%", borderRadius: "20px" }}>
        <div className="">
          {/* <div className="py-2" style={{backgroundColor:"#F4F9F5",borderTopLeftRadius:"20px"}}> */}

          <div
            className="p-3 mx-auto d-flex justify-content-center "
            style={{ width: "100%" }}
          >
            <div className="w-100 d-flex flex-column align-items-center">
              <img
                src={require("../assets/logo.png")}
                alt=""
                style={{ width: "60%" }}
                className=" mx-auto"
              />
              <div className="w-100 d-flex flex-column align-items-center gap-1">
                <h6 className="text-center m-0" style={{ color: "#6a4a9f" }}>
                  {adminData?.restaurant_data?.name}
                </h6>
                <h6 className="text-center m-0" style={{ color: "#6a4a9f" }}>
                  {adminData?.restaurant_data?.city_name?.name}
                </h6>
              </div>
            </div>
            {/* <img width={"100%"} className="" src='' alt="Logo here" /> */}
          </div>

          {/* </div> */}

          {/* <h4 className="p-2 mb-4 text-black text-center fw-bold">Business</h4> */}
          <List className="ms-4">
            {listItemData.map((value, i) => (
              <div key={i}>
                <div className="rounded-start">
                  <RenderItem
                    value={value}
                    i={i}
                    style={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: "10px",
                      color: "black",
                    }}
                  />
                </div>
              </div>
            ))}
  <select class="form-select form-select-sm px-3" 
  aria-label="Default select example" 
  style={{border:"0px"}} 
  value={seveid}
  onChange={(e) => handleCardClick(e.target.value)}>
  {/* <option selected>My Restaurant</option> */}
  {restaurantsData?.map((item) => (
    <option value={item.id}>{item?.name}</option>
  ))}
</select>
          </List>
          <List
            className="mt-auto position-absolute translate-middle rounded-start-4 gradient"
            style={{
              bottom: "0px",
              width: "calc(100% - 80px)",
              marginLeft: "145px",
            }}
          >
            <ListItem
              disablePadding
              className=" list_text cursor-pointer"
              style={{cursor: "pointer"}}
              onClick={() => {
                dispatch(logout());
                navigate("/");
              }}

            >
              <ListItemText
                className="d-flex  mt-auto gap-3 px-3 "
                primary={
                  <Typography
                    variant="body2"
                    style={{ fontSize: 14, color: "white" }}
                    title="logout"
                  >
                    <LogoutIcon />
                    Logout
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </div>
        {/* {show &&
          <MuiDialog 
            // title={"Logout"}
            title={"Are you sure you want to Logout?"} 
            show={show}
            // onHide={show}
            Buttons={
              ()=>(<>
              <Button
              onClick={() => { setShow(false); }}
              >Cancel
              </Button>
                <Button  
                onClick={() => {
               dispatch(logout())
               navigate('/')
                 }}
                 color="error" variant="contained">
                  Logout
                </Button>
              </>
              )
            }
            />
          } */}
      </div>
    </div>
  );

  // const container =
  //   window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      className=""
      component="nav"
      sx={{
        width: { md: drawerWidth },
        flexShrink: { md: 0 },
        bgcolor: "black",
      }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        // container={container}
        variant="temporary"
        open={props.mobileOpen}
        onClose={props.handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            bgcolor: "#FFFFFF",
            color: "#AFB7BE",
            zIndex: { md: 1000, xs: 1200 },
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", md: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            bgcolor: "black",
            border: 0,
            color: "#AFB7BE",
            width: drawerWidth,
            zIndex: { md: 1100, xs: 1200 },
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}

SideNav.propTypes = {
  window: PropTypes.func,
};

export default SideNav;

const RenderItem = ({ value, i }) => {
  return value.link ? (
    <ListItem
      key={i}
      component={NavLink}
      to={value?.link || ""}
      disablePadding
      sx={listItemStyle}
      className="rounded-start-4"
    >
      <ListItemButton
        className="list-item list_text "
        // sx={{ ...buttonStyle }}
        sx={{ ...buttonStyle, "&:active": { color: "white" } }}
      >
        {/* <ListItemIcon className="myIconClass" sx={{ color: "white", marginRight: -3 }}> {value.icon}</ListItemIcon> */}
        <ListItemText
          className=""
          primary={
            <Typography
              variant="body2"
              className=""
              style={{ fontSize: 14, paddingLeft: 0, whiteSpace: "nowrap" }}
              title={value.label}
            >
              {value.label}
            </Typography>
          }
        />
      </ListItemButton>
    </ListItem>
  ) : (
    <ListItemButton
      className="list-item list_text no_link_list_item "
      sx={{ ...buttonStyle, "&:hover": { backgroundColor: "#04BCFA" } }}
    >
      {/* <ListItemIcon className="myIconClass" sx={{ color: "white", }}>{value.icon}</ListItemIcon> */}
      <ListItemText
        className=""
        primary={
          <Typography
            variant="body2"
            style={{ fontSize: 14, color: "red", fontWeight: "bold" }}
            title={value.label}
          >
            {value.label}
          </Typography>
        }
      />
    </ListItemButton>
    
  );
};

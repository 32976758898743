import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CiClock2 } from 'react-icons/ci';
import { RiNotification2Fill } from "react-icons/ri";
import apiClient from '../api/apiClient';
export const Notifications = () => {
    
    const [notif,setnotif]=useState([])
    const [loader,setloader]=useState(true)
    const fetchnotif  = async()=>{
       
        setloader(true)
        const result = await apiClient.get(`/notifications/?restaurant_id=${localStorage.getItem('selectedRestaurantId')}`)
        setloader(false)
        if(!result.ok) return
        setnotif(result?.data.results)
    
      }

      useEffect(()=>{
        fetchnotif()
      },[])

    return (
        <>
            <div className="container">
                <h4 className='fw-bold m-0'>Notifications</h4>
                {/* <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to <br /> demonstrate the visual form of a In publishing and graphic</p> */}
                {
                    notif.map((e)=>(
                        <div className="">

                      { loader?<div className="d-flex justify-content-center ">    <CircularProgress color="secondary" /></div> : <div className="row p-3 rounded-4  my-2" style={{ backgroundColor: "#F5F5F5", border: "2px solid #E7E8F4", }}>
                    <div className="col-md-6">
                        <div className='d-flex align-items-center gap-3'>
                            <div className='rounded-circle p-2 d-flex align-items-center justify-content-center' style={{ backgroundColor: "#CEDCF2" }}>
                                <RiNotification2Fill className='fs-4' />
                            </div>
                            <div>
                                {/* <h6 className='fw-bold m-0 fs-semismall'>{e.notification}</h6> */}
                                <h6 className='fw-bold m-0 fs-semismall'>New Team Member Added</h6>
                                <p className='text-gray-400 m-0 fs-semismall'>
                                    {e.description}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 d-flex justify-content-end">
                        <div className='fs-semismall text-gray-400'>
                        <CiClock2 className='fs-6 text-gray-400 mx-2'/>
                            {e.created_at}
                        </div>
                    </div>

                </div>}
                </div>
                    ))
                }
            </div>


        </>
    )
}

import React, { useEffect } from "react";

const GoogleTranslate = () => {
  useEffect(() => {
    const loadGoogleTranslate = () => {
      try {
        // Observe changes in the DOM for the dropdown to appear
        const observer = new MutationObserver(() => {
          const combo = document.querySelector(".goog-te-combo");
          if (combo) {
            combo.value = "fr"; // Set language to French (or any other code like 'ur' for Urdu)
            combo.dispatchEvent(new Event("change"));
            observer.disconnect(); // Stop observing once it's done
          }
        });

        // Start observing the DOM for changes
        observer.observe(document.body, {
          childList: true,
          subtree: true,
        });
      } catch (error) {
        console.error("Error during Google Translate dropdown setup:", error);
      }
    };

    const googleTranslateElementInit = () => {
      try {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: "en",
            autoDisplay: false,
          },
          "google_translate_element"
        );
        loadGoogleTranslate(); // Load the translate dropdown once initialized
      } catch (error) {
        console.error("Error initializing Google Translate:", error);
      }
    };

    // Load the Google Translate API script
    const script = document.createElement("script");
    script.src =
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    script.async = true;
    script.defer = true;
    script.onerror = (error) => {
      console.error("Error loading Google Translate script:", error);
    };

    document.head.appendChild(script);

    // Attach the googleTranslateElementInit function to the global window object
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  return null;
};

export default GoogleTranslate;

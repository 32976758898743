import React, { useEffect, useState } from "react";
import SimpleTable from "../mui/SimpleTable";
import apiClient from "../api/apiClient";
import ExportToExcel from "../restaurent-pages/ExportToExcel";
import { Link } from "react-router-dom";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
const UserList = () => {
  const [allUsers, setAllUsers] = useState([]);
  const columns = [
    { field: "first_name", headerName: "First Name" },
    { field: "last_name", headerName: "Last Name" },
    { field: "user_type", headerName: " Role" },
    { field: "email", headerName: "Email" },
    { field: "dob", headerName: "Date of Birth" },
    { field: "phone", headerName: "Phone" },
  ];
 
  const cellComponents = {
    id: null, // Default cell
    productName: null,
    description: null, // Adjust description rendering
    price: null,
    action: null,
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const result = await apiClient.get("/register/?page_size=500");
    if (result.ok) setAllUsers(result?.data?.results);
  };


  return (
    <div>
        <p className='text-dark '>

<Link to={"/admin/resturants"}>
<ArrowBackIosNewIcon sx={{color:"black",cursor:"pointer"}} />
</Link>
      </p>
      <div className="d-flex justify-content-end mb-3"> 
        
        
             <Link
            to="/admin/createnewuser"
            className="gradient text-decoration-none px-4   py-3 text-white rounded-3"
          >
            Add new User
          </Link>
          
          
          
          
           </div>
      <SimpleTable
        columns={columns}
        data={allUsers}
        cellComponents={cellComponents}
      />
      <div className="my-2">
        <ExportToExcel width={"100%"} jsonData={allUsers} />
      </div>
    </div>
  );
};

export default UserList;

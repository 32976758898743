import { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import { IconButton, Rating, useMediaQuery } from '@mui/material';
import { GrNext, GrPrevious } from 'react-icons/gr';
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { SyncLoader } from "react-spinners";
import apiClient from '../api/apiClient';
import { Link } from 'react-router-dom';
import { handleErrors } from '../modules/handleErrors';
import { useSelector } from 'react-redux';
import ErrorMessagePopup from './ErrorMessagePopup';

export const Slider2 = ({ heading, resturentdata, fetchData, handlelocation }) => {
  const {  isLoggedIn, userid } = useSelector((state) => state.auth);
  const sliderRef = useRef(null);
  const [isfav, setisfav] = useState("");
  const [isfavloader, setisfavloader] = useState(false);
  const [isfavloaderid, setisfavloaderid] = useState(null);
  const [initialized, setInitialized] = useState(false);
  
  const isMobile = useMediaQuery("(max-width: 600px)");

  useEffect(() => {
    if (resturentdata.length > 0) {
      setInitialized(true);
    }
  }, [resturentdata]);

  const handlefavorite = async (id) => {
    try {
      setisfavloaderid(id);
      setisfavloader(true);
      const response = await apiClient.post('/favorite-restaurants/', { "restaurant": id });
      setisfavloader(false);
      fetchData();
      handlelocation();
      if (!response.ok) return handleErrors(response.data);
      setisfav(response?.data?.restaurant);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handlefavoritedelete = async (id, itemid) => {
    try {
      setisfavloaderid(itemid);
      setisfavloader(true);
      const response = await apiClient.delete(`/favorite-restaurants/${id}/`);
      setisfavloader(false);
      if (!response.ok) return handleErrors(response.data);
      fetchData();
      handlelocation();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const prevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  const [showmodel, setshowmodel] = useState(false);

  return (
    <>
      <div className="custom-arrows d-flex justify-content-between">
        <span>
          <ErrorMessagePopup errormessage={"Please Login To Proceed!"} show={showmodel} onHide={() => setshowmodel(false)} />
          <h3 className='text-prpl2'>{heading}</h3>
        </span>
      </div>
      <div className="slider-wrapper">
        <div className="d-flex justify-content-between">
          <IconButton onClick={prevSlide}>
            <GrPrevious />
          </IconButton>
          <IconButton onClick={nextSlide}>
            <GrNext />
          </IconButton>
        </div>
        {initialized && (
          <Slider ref={sliderRef} {...settings}>
            {resturentdata?.map((item, index) => (
              <div key={index} className='col-md-3 rounded-4 card-slider'>
                <Link to={`/detail?id=${item.id}`}>
                  <div className='d-flex justify-content-center'>
                    <div className='rounded-4 p-3'>
                      <img
                        style={{ width: "100%", height: "220px",objectFit:"cover" ,objectPosition:"top"}}
                        className='img-fluid mx-auto rounded-4'
                        src={item?.images[0]?.image}
                        alt="Paella dish"
                      />
                    </div>
                  </div>
                </Link>
                <div className='px-3 pb-5'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <span>
                      <p className='mb-1'>
                        <FaLocationDot className='fs-4 mx-2' />
                        {item?.city_name?.name}
                      </p>
                      <h4 className='text-prpl2'>{item?.name}</h4>
                    </span>
                    <span>
                      {isLoggedIn ? (isfavloader && isfavloaderid === item.id
                        ? <SyncLoader size={10} color="#442479" />
                        : (item.is_favorite
                          ? <FaHeart className='fs-4' onClick={() => handlefavoritedelete(item.fav_id, item.id)} style={{ color: "#442479" }} />
                          : <FaRegHeart className='fs-4' onClick={() => handlefavorite(item.id)} />
                        )) : (
                        <FaRegHeart className='fs-4' onClick={() => setshowmodel(true)} />
                      )}
                    </span>
                  </div>
                  
                  <Rating name="read-only" value={item?.average_rating} readOnly className='fs-2' />
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>
    </>
  );
}

import {
  AppBar,
  Avatar,
  Badge,
  Hidden,
  IconButton,
  Toolbar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import { IoPower } from "react-icons/io5";
import { PiNotificationBold } from "react-icons/pi";
import apiClient from "../api/apiClient";
import { useSelector } from "react-redux";
import { selectAdminData } from "../redux/adminSlice";
import { RxAvatar } from "react-icons/rx";
import { Bell } from "react-bootstrap-icons";

function Navbar({ handleDrawerToggle }) {
  const [notification, setnotification] = useState(false);
  const fetchnotif = async () => {
    const result = await apiClient.get(
      `/notifications/check_all_notifications_seen/?restaurant_id=${localStorage.getItem('selectedRestaurantId')} `
    );
    if (!result.ok) return;
    setnotification(result?.data?.all_notifications_seen);
  };

  useEffect(() => {
    fetchnotif();
  }, []);


  const adminData = useSelector(selectAdminData);

  return (
    <Box className="">
      <AppBar className="" position="fixed" sx={{ boxShadow: "0" }}>
        <Toolbar
          className=""
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Box className=" d-flex  align-items-center justify-content-between w-100 p-2 rounded-2 nav-style">
            <Box className="  d-flex justify-content-start align-items-center">
              <h5 className="  my-0 mx-3 fw-bold text-prpl">
                Welcome Back {adminData?.restaurant_data?.name}
              </h5>
            </Box>
            <div className="d-flex align-items-center">
              <Link to={"/restaurant/notifications"}>
                {
                  <Badge
                    invisible={!notification}
                    color="secondary"
                    variant="dot"
                  >
                    <Bell
                      className="fs-4 mx-2"
                      style={{ color: "#003B93" }}
                    />
                  </Badge>
                }
              </Link>
              {/* <Link to={"/restaurant/notifications"}>
                {
                  <RxAvatar
                    className="fs-4 mx-2"
                    style={{ color: "#003B93" }}
                  />
                }
              </Link> */}
              {/* <IoPower  className='fs-4 mx-2 me-3' style={{color:"#003B93"}}/> */}
              {/* <Avatar alt="Remy Sharp" src='' /> */}

              <Hidden mdUp>
                <IconButton onClick={handleDrawerToggle}>
                  <MenuIcon style={{ color: "black" }} />
                </IconButton>
              </Hidden>
            </div>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Navbar;

import { ContentCopy } from '@mui/icons-material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CheckIcon from '@mui/icons-material/Check';

const CopyButton = () => {
    const {  userid } = useSelector((state) => state.auth);
  const [textToCopy, setTextToCopy] = useState(`https://www.boogeat.com/?ideats=${userid}`);
  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipboard = () => {
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        setCopySuccess('Copied!');
      })
      .catch(err => {
        setCopySuccess('Failed to copy');
        console.error('Failed to copy: ', err);
      });
  }

  return (
    <div>
   
      <button className='btn' onClick={copyToClipboard}> {!copySuccess?<ContentCopy sx={{color:"black"}}/>:<CheckIcon sx={{color:"black"}} /> }</button>
      
    </div>
  );
}

export default CopyButton;

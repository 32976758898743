import React, { useEffect, useState } from "react";
import RecipeReviewCard from "../mui/Cards";
import { Slider2 } from "../components/Slider2";
import { Slider3 } from "../components/Slider3";
import { FaQuoteLeft } from "react-icons/fa";
import { MdLocationPin } from "react-icons/md";
import { FaBowlFood } from "react-icons/fa6";
import { FaSearch } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { TbBrandFacebookFilled } from "react-icons/tb";
import { GrInstagram } from "react-icons/gr";
import { CiEdit } from "react-icons/ci";
import HomeModal from "../components/HomeModal";
import apiClient from "../api/apiClient";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Avatar,
  CircularProgress,
  Paper,
  TextField,
} from "@mui/material";
import Ratings from "./Rating";
import { useIndexPageData } from "../contextapi/Indexpagedata";
import ReviewPopup from "./ReviewPopup";
import LocationModal from "./Bookinglocation ";
import Faq from "./Faq";
import { SliderNearRea } from "../components/SliderNearRea";
import { useSelector } from "react-redux";
import RangeSlider from "./RangeSlider";
import HomeModalNested from "../components/HomeModalNested";

export const Home = () => {
  const [valueRange, setvalueRange] = React.useState([50, 700]);
  const { indexData, Loading } = useIndexPageData();

  const { token, userType, isLoggedIn, userid } = useSelector(
    (state) => state.auth
  );

  const handlelocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log(position,"possss");
          sendLocation(position.coords.latitude, position.coords.longitude);
          console.log(position);
       
        },
        (error) => {}
      );
    } else {
    }
  };
  const sendLocation = async (latitude, longitude) => {
    const formData = new FormData();
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    console.log(latitude, latitude, "ppppppppppppppppp");

    try {
      const result = await apiClient.post("/restaurants/getnearby/", formData);
      console.log(result.data.nearby_restaurants, "pppppp");
      if (result?.data?.message) {
        throw new Error(result.data.message);
      }
      if (result.ok) setResturentdDta(result?.data?.nearby_restaurants);
    } catch (error) {
      console.error("Error sending location:", error);
    }
  };
  const [bookingdata, setbookingdata] = useState([]);
  const [resturentloader, setresturentloader] = useState(true);
  const [showLocationModal, setshowLocationModal] = useState(false);
  const fetchbooking = async () => {
    const result = await apiClient.get("/bookings/?status=dashboard");
    if (result.ok) {
      setbookingdata(result.data.results);
    }
  };

  useEffect(() => {
    fetchbooking();
    handlelocation();
  }, []);

  const [modalshow, setmodalshow] = useState(false);
  const [opinionsdata, setopinionsdata] = useState([]);

  const [typedata, settypedata] = useState([]);
  const [reviewmodal, setreviewmodal] = useState(false);
  const [type, settype] = useState("");
  const [city, setcity] = useState({
    name: "",
  });
  const [citydata, setcitydata] = useState([]);
  const [average_price, setaverage_price] = useState("");
  const [singlebooking, setsinglebooking] = useState(null);

  const [resturentdata, setResturentdDta] = useState([]);
  const [resturentdatacity, setResturentdDtacity] = useState([]);
  const navgater = useNavigate();
  const [deleteitemid, setdeleteitemid] = useState(null);

  const [deletemodale, setdeletemodale] = useState(false);

  const fetchData = async (e) => {
    const result = await apiClient.get(`/restaurants/`);

    if (result.ok) setResturentdDta(result.data.results);
  };
  const fetchDatacityresturent = async (e) => {
    const result = await apiClient.get(`/restaurants/?city=Casablanca`);

    if (result.ok) setResturentdDtacity(result.data.results);
  };
  const fetchopinionsData = async (e) => {
    const result = await apiClient.get(`/opinions/?status=true`);
    if (result.ok) setopinionsdata(result.data.results);
  };

  const hanldeserach = () => {
    navgater(
      `/filter-restaurant?city=${city?.name}&type=${type}`
    );
  };
  const fetchcity = async () => {
    const result = await apiClient.get("/city/");
    if (result.ok) setcitydata(result.data.results);
  };

  useEffect(() => {
    fetchcity();
    fetchopinionsData();
    fetchDatacityresturent();
  }, []);

  const handleCityChange = (event, value) => {
    setcity(value);
  };

  const handelmodalbooking = (item) => {
    setmodalshow(true);
    setsinglebooking(item);
  };

  const fetchmenuData = async () => {
    try {
      setresturentloader(true);
      const result = await apiClient.get(`/cuisines/?limt=20`);
      setresturentloader(false);
      if (!result.ok) return;
      settypedata(result.data.results);
    } catch (error) {
      console.error("Error fetching menu data:", error);
    }
  };

  useEffect(() => {
    fetchmenuData();
  }, []);

  return (
    <>
      {Loading || resturentloader ? (
        <div className="d-flex justify-content-center mt-4">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <div>
          {/* <ShareButton/> */}

          <section className=" my-sm-4 ">
            <div className="  " style={{}}>
              {/* <div className=""></div> */}
              <div
                className=" row bg-hero position-relative align-items-center bg-prpl2 mx-0  px-0"
                style={{ paddingBottom: "100px", paddingTop: "100px" }}
              >
                <div className="col-12 px-0 rounded-4 h-100 px-4 px-md-5 px-md-4 px-lg-5 position-relative">
                  <h1 className=" fs-1 fw-md text-center text-sm-start text-uppercase text-white">
                    {/* Découvrez et réservez les meilleurs restaurants */}

                    {indexData?.search_title}
                    <br />
                    {/* <span className="outline-text">
          autours de vous        
                </span> */}
                  </h1>
                </div>
                {/* <div className="col-sm-6 col-lg-5 px-4 position-relative ">
                  <img
                    alt=""
                    className="w-100 d-none px-3 px-sm-0 px-4 px-xl-5"
                  />
                </div> */}
                <div className="col-sm-11 d-sm-block d-none  col-md-12 col-lg-10 position-relative px-4 px-md-5 px-md-4 px-lg-5 mt-3">
                  <form action="">
                    <div className="row mx-0 justify-content-between bg-prpl4 py-3 py-md-4 px-2 px-lg-3 rounded-4">
                      <div className="col-md-4 my-1 my-md-0 col-sm-6">
                        <div className="d-sm-block d-none">
                          <div className=" bg-prpl5 rounded-3 filter-border d-flex align-items-center  px-3">
                            <Autocomplete
                              disablePortal
                              clearIcon
                              id="combo-box-demo"
                              options={citydata}
                              PaperComponent={({ children }) => (
                                <Paper style={{ background: "#6A4A9F",color:"white" }}>{children}</Paper>
                              )}
                              getOptionLabel={(option) => option.name}
                              renderOption={(props, option) => (
                                <li {...props}>{option.name}</li>
                              )}
                              sx={{ width: 300 }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Localisation"
                                  sx={{ color: "white" }}
                                  className=""
                                />
                              )}
                              onChange={handleCityChange}
                              value={city}
                            />

                            <MdLocationPin color="white" size={26} />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 my-1 my-md-0 col-sm-6">
                        <div className="d-sm-block d-none">
                          <div className="bg-input rounded-3 filter-border d-flex align-items-center px-3">
                            <select
                              style={{
                                width: "100%",
                                border: "0px",
                                outline: "none",
                                backgroundColor: "#492C79",
                                color: "white",
                              }}
                              className="py-3 "
                              aria-label="Default select example"
                              value={type}
                              onChange={(e) => settype(e.target.value)}
                            >
                              <option selected>Cuisine</option>

                              {typedata.map((e) => (
                                <option style={{backgroundColor:"#6A4A9F"}} value={e?.id}>{e?.name}</option>
                              ))}
                            </select>

                            <FaBowlFood color="white" size={26} />
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-md-3 my-1 my-md-0 col-sm-6 d-sm-block d-none">
                        <RangeSlider
                          value={valueRange}
                          setvalue={setvalueRange}
                        />
                      </div> */}
                      <div className="col-md-4 my-1 my-md-0 col-sm-6">
                        <div
                          className="bg-prpl3 rounded-3 filter-border d-sm-block d-none "
                          onClick={hanldeserach}
                        >
                          <button
                            Autres
                            villes
                            au
                            Maroc
                            className="text-white  d-flex align-items-center focus-none  px-3 justify-content-between bg-transparent pe-3 border-0 py-3 w-100"
                            type="submit"
                          >
                            <span className="me-2 notranslate ">Recherche </span>
                            <FaSearch />
                            {/* <i className="fa-solid fa-magnifying-glass text-white" /> */}
                          </button>
                        </div>
                        <div className="bg-prpl3 rounded-3 filter-border d-sm-none d-block">
                          <div
                            onClick={hanldeserach}
                            className="text-white text-center focus-none  px-3 bg-transparent pe-3 border-0 py-2 w-100"
                            type="submit"
                          >
                            Search
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div id="map" />
                </div>
                <div className="position-absolute social-hero bg-prpl3 d-sm-block d-none px-3 py-4 d-flex align-items-center flex-column">
                  <a className="text-prpl text-decoration-none fs-5" href="#">
                    {/* <i className="fa-brands fa-x-twitter fs-5" /> */}
                    <FaXTwitter size={26} />
                  </a>
                  <a className="text-prpl text-decoration-none fs-5" href="#">
                    <TbBrandFacebookFilled size={26} />
                    {/* <i className="fa-brands fa-facebook-f fs-5" /> */}
                  </a>
                  <a className="text-prpl text-decoration-none fs-5" href="#">
                    <GrInstagram size={26} />

                    {/* <i className="fa-brands fa-instagram fs-5" /> */}
                  </a>
                </div>
              </div>
            </div>
          </section>
          {bookingdata.length > 0 && (
            <section className="mt-sm-5 pb-sm-5 pt-5">
              <div className="mycontainer">
                <h4>Réservation à venir</h4>
                {bookingdata.length > 0 ? (
                  <div className="border shadow rounded-3 p-3">
                    <h5> {bookingdata[0]?.restaurant_details?.name} </h5>
                    <button className="border rounded-2 bg-white p-2 text-center w-100 text-prpl">
                      {bookingdata[0]?.date_display} .{" "}
                      {bookingdata[0]?.formatted_date} .{" "}
                      {bookingdata[0]?.persons} guests
                    </button>
                    <div className="mt-2">
                      <button
                        onClick={() => setshowLocationModal(true)}
                        className="border rounded-2 bg-prpl p-2 text-white text-center w-100"
                      >
                        Obtenir l’itinéraire
                      </button>
                      <LocationModal
                        show={showLocationModal}
                        onHide={() => setshowLocationModal(false)}
                        singlebooking={bookingdata[0]}
                      />
                    </div>
                    <div className="d-flex justify-content-center align-items-center px-3 py-2">
                      <div
                        className="d-flex flex-column justify-content-center align-items-center pointer"
                        onClick={() => handelmodalbooking(bookingdata[0])}
                      >
                        <CiEdit className="fs-4 text-prpl" />
                        <p className="mb-0 text-prpl  fw-bold">Modifier</p>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </section>
          )}

          {modalshow && (
            <HomeModalNested
              fetchbooking={fetchbooking}
              show={modalshow}
              singlebooking={singlebooking}
              onHide={() => setmodalshow(false)}
            />
          )}
          <section className=" mycontainer mt-sm-5 py-5  ">
            <RecipeReviewCard />
          </section>
{resturentdata.length>0&&
          <section
            className=" container-fluid mt-5 py-5"
            style={{ backgroundColor: "rgb(250,250,250)" }}
          >
           
            <div className="mycontainer ">
              <SliderNearRea
                fetchData={handlelocation}
                fetchDatacityresturent={fetchDatacityresturent}
                resturentdata={resturentdata}
                heading={indexData?.around}
              />
            </div>
          </section>}

          <section className="container-fluid mt-5 py-5">
            <div className="mycontainer  ">
              <Slider2
                fetchData={fetchDatacityresturent}
                handlelocation={handlelocation}
                resturentdata={resturentdatacity}
                heading={indexData?.popular}
              />
            </div>
          </section>
          <section
            className="container-fluid mt-5 py-5"
            style={{ backgroundColor: "rgb(250,250,250)" }}
          >
           
            <div className="mycontainer ">
              <Slider3 heading={indexData?.other_city} />
            </div>
          </section>

          <section class="bg-ltw">
            {isLoggedIn && (
              <div className="d-flex  justify-content-center  ">
                <button
                  className="btn   text-light"
                  style={{ backgroundColor: "#442479" }}
                  onClick={() => setreviewmodal(true)}
                >
               
                  Reveiw
                </button>
              </div>
            )}
            <div class="mycontainer py-5 py-sm-5">
              <div class="row justify-content-between ">
                <h2 class="fs-1 fw-normal text-center mb-4 text-prpl">
                  Avis clients
                </h2>
              </div>
              <div class="center">
                <div className="row">
                  {opinionsdata?.map((item) => {
                    console.log(
                      item?.user_detail?.image,
                      "item?.user_detail?.imageitem?.user_detail?.image"
                    );
                    return (
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div class="p-2">
                          <div
                            class="card px-3 px-sm-4 py-4 rounded-4 border-0"
                          >
                            <div class="d-flex flex-column flex-sm-row justify-content-center align-items-center mx-3 clientss">
                              <Avatar
                                src={item?.user_detail?.image}
                                sx={{ width: "70px", height: "70px" }}
                              />{" "}
                              <div class="mx-4">
                                <p class="mb-0 text-sm-start  text-center fw-bold">
                                  {item?.user_detail?.first_name}
                                </p>

                                {/* <p class="mb-0 text-secondary">/ Customer</p> */}
                              </div>
                            </div>
                            <div class="card-body text-center">
                              <p class="">{item?.description}</p>
                              <FaQuoteLeft
                                className="fa-solid mt-3  fs-2 fa-quote-left"
                                style={{ color: "#442479" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <ReviewPopup
                  show={reviewmodal}
                  fetchData={fetchopinionsData}
                  onHide={() => setreviewmodal(false)}
                />
              </div>
            </div>
          </section>
          <Faq />

          <Ratings
            url="reviews"
            show={deletemodale}
            fetchData={fetchData}
            id={deleteitemid}
            onHide={() => setdeletemodale(false)}
          />
        </div>
      )}
    </>
  );
};

import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import DashedLineSVG from "./DashedLineSVG";
import Pagination from "./Pagination";
import { SyncLoader } from "react-spinners";
import { Link } from "react-router-dom";

// Custom styling for the Table component
const StyledTable = styled(Table)(({ theme }) => ({
  "& .MuiTableCell-head": {
    color: "#fff",
    background: "linear-gradient(360deg, rgba(68, 36, 121,1) 30%, rgba(121, 92, 169, 0.88) 100%)",
    "&:first-of-type": {
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
    },
    "&:last-of-type": {
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
    },
  },
  "& .MuiTableRow-root": {
    backgroundColor: "transparent",
  },
  "& .MuiTableCell-root": {
    borderBottom: `none`,
  },
  "& .MuiTableCell-body": {
    color: "#192A3E",
  },
}));

// Custom styling for the TableContainer component
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  backgroundColor: "transparent",
  boxShadow: "none",
  overflowX: "auto",
  "@media (max-width: 900px)": {
    "& .MuiTable-root": {
      minWidth: "900px",
    },
  },
}));

// Custom checkbox styles
const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "#D4D4D4",
  "&.Mui-checked": {
    color: "#3BCBCE",
  },
  "&.MuiCheckbox-indeterminate": {
    color: "#3BCBCE", // Border and tick color for partially checked
    
  },
}));

// Component for rendering custom cell content
const CellContent = ({ value, CustomComponent }) => {
  if (CustomComponent) {
    return <CustomComponent value={value} />;
  } else if (typeof value === "object" && value !== null) {
    return <div>{JSON.stringify(value)}</div>;
  } else {
    return <div>{value}</div>;
  }
};

const SimpleTable = ({
  data,
  columns,
  customStyles,
  cellComponents,
  showCheckbox,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState(new Set());
  const rowsPerPage = 10;
  const totalPages = Math.ceil(data.length / rowsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedRows(new Set(data.map((row) => row.id)));
    } else {
      setSelectedRows(new Set());
    }
  };

  const handleSelectRow = (id) => {
    setSelectedRows((prevSelectedRows) => {
      const newSelectedRows = new Set(prevSelectedRows);
      if (newSelectedRows.has(id)) {
        newSelectedRows.delete(id);
      } else {
        newSelectedRows.add(id);
      }
      return newSelectedRows;
    });
  };

  const paginatedData = data.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const isAllSelected =
    showCheckbox &&
    paginatedData.length > 0 &&
    paginatedData.every((row) => selectedRows.has(row.id));

  const isIndeterminate =
    showCheckbox &&
    paginatedData.length > 0 &&
    paginatedData.some((row) => selectedRows.has(row.id)) &&
    !isAllSelected;

  return (
    <>
  
    <Box sx={{ ...customStyles }}>
       
      <CustomTableContainer component={Paper} elevation={0}>
        {paginatedData.length > 0 ? (
          <StyledTable>
            <TableHead>
              <TableRow>
                {showCheckbox && (
                  <TableCell padding="checkbox">
                    <CustomCheckbox
                      indeterminate={isIndeterminate}
                      checked={isAllSelected}
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                )}
                {columns.map((column) => (
                  <TableCell key={column.field}>{column.headerName}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ height: "25px", backgroundColor: "transparent" }}>
                {/* Space between header and body */}
              </TableRow>
              {paginatedData.map((row) => (
                <React.Fragment key={row.first_name}>
                  <TableRow>
                    {showCheckbox && (
                      <TableCell padding="checkbox">
                        <CustomCheckbox
                          checked={selectedRows.has(row.id)}
                          onChange={() => handleSelectRow(row.id)}
                        />
                      </TableCell>
                    )}
                    {columns.map((column) => (
                      <TableCell key={column.field}>
                        <CellContent
                          value={row[column.field] || "N/A"}
                          CustomComponent={cellComponents[column.field]}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell
                      colSpan={columns.length + (showCheckbox ? 1 : 0)}
                      padding="none"
                    >
                      <DashedLineSVG
                        width="100%"
                        height="0.5px"
                        dashWidth="7"
                        spaceWidth="5"
                      />
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </StyledTable>
        ) : (
          <div className="p-4 w-100 d-flex align-items-center justify-content-center">
            <SyncLoader />
          </div>
        )}
      </CustomTableContainer>
      {totalPages !== 1 && paginatedData.length > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </Box>
    </>
  );
};

export default SimpleTable;

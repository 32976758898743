import { Button, styled } from "@mui/material";

import { Modal, ModalFooter } from 'react-bootstrap'
import { handleErrors } from "../modules/handleErrors";
import apiClient from "../api/apiClient";
import { purple } from "@mui/material/colors";

const DeletePopup = ({show,onHide,id,url, fetchData}) => {
  const handlefavoritedelete = async () => {
    try {
        const response = await apiClient.delete(`/${url}/${id}/` )
        if(!response.ok) return handleErrors(response.data);
         fetchData()
         onHide()

        console.log(response); 
    } catch (error) {
        console.error('Error:', error);
    }
  };
  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#513384"),
    backgroundColor: "#513384",
    '&:hover': {
      backgroundColor: "#513384",
    },
  }));
  return (


    <>
    <Modal
           show={show}
           onHide={onHide}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ zIndex: "1300" }}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
                Delete
               
              </Modal.Title>
            </Modal.Header>
           
            <Modal.Body>
                Are You sure you want to delete.
          
            </Modal.Body>
            <ModalFooter className="d-flex gap-2">
                <Button variant="outlined" color="secondary" onClick={onHide}  type="submit" >NO</Button>
                
                <ColorButton  onClick={handlefavoritedelete} variant="contained " >Yes</ColorButton>

            </ModalFooter>
            
            </Modal>
    </>
  )
}

export default DeletePopup
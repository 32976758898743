import React, { useEffect, useState } from "react";
import apiClient from "../api/apiClient";
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";

export const Dashboard = () => {
  const [dashboardData, SetDashboardData] = useState();
  const [loader, Setloader] = useState(false);

  const fetchData = async () => {
    Setloader(true);
    const result = await apiClient.get(`/stats/`);
    Setloader(false);
    if (result.ok) SetDashboardData(result.data);
  };

  useEffect(() => {
    fetchData();
  }, []);
  console.log(dashboardData, "DashboardData");

  return (
    <>
      {loader ? (
        <div className="d-flex justify-content-center ">
          {" "}
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <div className="mycontainer">
          <div className="right-mid mx-1 mx-sm-3 p-2">
            <div className="row d-flex justify-content-between align-items-center">
              <div className="col-12 col-md-6 p-0">
                <h2 className="mb-0 mx-2">Dashboard</h2>
                <p className="mb-0 mx-2">
                  Welcome to your Dashboard. The Dashboard is a great place to
                  monitor all the activity within the Hub giving you full
                  control and oversight.
                </p>
              </div>
            </div>
          </div>
          <div className="right-bottom mx-1 mx-sm-3">
            <div className="bottom-inner">
              <div className="row mx-2 mx-md-0 d-flex justify-content-between ">
                <div className="col-12 my-4 col-sm-6 col-lg-4">
                <Link to={"/admin/resturants"} style={{textDecoration:"none",color:"black"}}>
    
                  <div className="sdw p-3 rounded-4">
                    <p>Restaurants</p>
                    <h2 className="display-3 mb-0">
                      {dashboardData?.admin_total_restaurants}
                    </h2>
                  </div>
                  </Link>
                </div>
                <div className="col-12 my-4 col-sm-6 col-lg-4">
                  <div className="sdw p-3 rounded-4">
                    <p>Reviews</p>
                    <h2 className="display-3 mb-0">
                      {dashboardData?.admin_total_reviews}
                    </h2>
                  </div>
                </div>
                <div className="col-12 my-4 col-sm-6 col-lg-4">
                <Link to={"/admin/AllBooking"} style={{textDecoration:"none",color:"black"}}>
            
                  <div className="sdw p-3 rounded-4">
                    <p>Bookings</p>
                    <h2 className="display-3 mb-0">
                      {dashboardData?.admin_total_bookings}
                    </h2>
                  </div>
                  </Link>
                </div>

                <div className="col-12 my-4 col-sm-6 col-lg-4">
                <Link to={"/admin/users-list"} style={{textDecoration:"none",color:"black"}}>
               
                  <div className="sdw p-3 rounded-4">
                    <p>Users</p>
                    <h2 className="display-3 mb-0">
                      {dashboardData?.admin_total_users}
                    </h2>
                  </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

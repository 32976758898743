import React, { useEffect, useState } from "react";
import apiClient from "../api/apiClient";
import { getCurrentURLParameters } from "../modules/helpers";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Delete, Edit } from "@mui/icons-material";
import { handleErrors } from "../modules/handleErrors";
import Editmenupopup from "./Editmenupopup";

const Addmenus = () => {
  const id =  localStorage.getItem('selectedRestaurantId');
  // const { id } = getCurrentURLParameters();
  const [editmodal, seteditmodal] = useState(false);
  const [Editid, seteidetid] = useState("");
  const [singlemenu, setsinglemenu] = useState("");

  const [menudata, setmenudata] = useState([]);

  const [loader, setloader] = useState(false); // State to store the picture file
  const [menuItems, setMenuItems] = useState([]);

  const fetchmenuData = async () => {
    const result = await apiClient.get(`/menus/?restaurant_id=${id}`);
    if (!result.ok) return;

    setmenudata(result?.data.results);
    // console.log(result, "city detail")
  };

  useEffect(() => {
    fetchmenuData();
  }, []);

  const handleAddMenuItem = () => {
    setMenuItems([...menuItems, { name: "", price: "" }]);
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const newMenuItems = [...menuItems];
    newMenuItems[index][name] = value;
    setMenuItems(newMenuItems);
  };
  const handledelete = async (id) => {
    try {
      const response = await apiClient.delete(`/menus/${id}/`);
      if (!response.ok) return handleErrors(response.data);
      fetchmenuData();
      console.log(response);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const onSubmit = async (e) => {
    try {
      e.preventDefault();

      setloader(true);
      const response = await apiClient.post("/menus/", {
        restaurant_id: id,
        menus: menuItems,
      });
      setloader(false);
      if (!response.ok) return;
      setMenuItems([]);
      fetchmenuData();

      if (response.ok) toast.success("Menu added successfully");
      // navigate("/admin/resturants")
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handlemodal = (data) => {
    setsinglemenu(data);
    seteditmodal(true);
  };

  return (
    <div className="row justify-content-between ">
      <div className="col-md-4 mt-4">
        <form onSubmit={onSubmit}>
          {menuItems.map((menuItem, index) => (
            <div key={index}>
              <input
                className="form-control my-2"
                type="text"
                required
                placeholder="Menu name"
                name="name"
                value={menuItem.name}
                onChange={(e) => handleChange(index, e)}
              />
              <input
                className="form-control"
                required
                type="number"
                placeholder="Price"
                name="price"
                value={Number(menuItem?.price)}
                onChange={(e) => handleChange(index, e)}
              />
            </div>
          ))}
          <button
            className="btn me-2 mt-2"
            style={{ backgroundColor: "#492c79", color: "white" }}
            onClick={handleAddMenuItem}
          >
            Add Menu Item
          </button>

          <button
            type="submit"
            className="btn mt-2"
            style={{ backgroundColor: "#492c79", color: "white" }}
          >
            {!loader ? "Add" : "Add..."}
          </button>
        </form>
      </div>
      <div className="col-md-6">
        <div
          className="tab-content px-0"
          id="tab2"
          style={{
            display: "block",
          }}
        >
          <div className="mt-1">
            <h2 className="fs-2">Menu</h2>
            <div className="my-4">
              {menudata?.map((e) => (
                <div
                  key={e.id}
                  className="py-3 px-3 my-2 bg-tb d-flex justify-content-between align-items-center"
                >
                  <p>
                    <Delete onClick={() => handledelete(e?.id)} />{" "}
                    <Edit onClick={() => handlemodal(e)} />
                  </p>
                  <p className="mb-0 text-prpl">
                    {/* Appetizers */}
                    {e?.name}
                  </p>
                  <div className="d-flex bg-tb align-items-center">
                    <span className="text-prpl">
                      {/* 10 DH */}
                      {e?.price} DH
                    </span>
                  </div>
                </div>
              ))}

              {editmodal && (
                <Editmenupopup
                  fetchData={fetchmenuData}
                  singlemenu={singlemenu}
                  show={editmodal}
                  onHide={() => seteditmodal(false)}
                  id={id}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addmenus;

import { MedicalServices } from "@mui/icons-material";
import { Avatar, Rating, TextField } from "@mui/material";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";

import Modal from "react-bootstrap/Modal";
import { BsReceiptCutoff } from "react-icons/bs";
import { IoChevronForward } from "react-icons/io5";
import { Link } from "react-router-dom";

function LocationModal(props) {
  console.log(
    props.singlebooking?.restaurant_details?.longitude,
    props.singlebooking?.restaurant_details?.latitude,
    "pppppppppppppppp"
  );

  return (
    <Modal
      style={{ borderRadius: "50px" }}
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        style={{ backgroundColor: "#F0F2F2", color: "black" }}
      >
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body">
          <h2>Réservation à venir </h2>
          <iframe
            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDQmPq0HH1XTcSNa55xUP748V1Vv4hhK1w&q=${props.singlebooking?.restaurant_details?.latitude},${props.singlebooking?.restaurant_details?.longitude}`}
            width="100%"
            height="430"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className="rounded-4"
          ></iframe>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default LocationModal;

import * as React from "react";
import { styled } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Slider from "react-slick";
import { IconButton } from "@mui/material";
import "slick-carousel/slick/slick.css";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import { GrNext, GrPrevious } from "react-icons/gr";
import { useIndexPageData } from "../contextapi/Indexpagedata";
import apiClient from "../api/apiClient";
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RecipeReviewCard() {
  const sliderRef = React.useRef(null); // Reference to the Slider component

  const [data, setdata] = React.useState();
  const { indexData } = useIndexPageData();
  const fetchData = async () => {
    try {
      const result = await apiClient.get(`/cuisines/`);

      if (result.ok) setdata(result.data.results);

      console.log(result, "resttt detail");
    } catch (error) {}
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const navgater = useNavigate();
  const hanldeserach = (type) => {
    console.log(type);

    navgater(`/filter-restaurant?type=${type}`);
  };
  const prevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  // Function to navigate to the next slide
  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };
  return (
    <>
      <div className="custom-arrows d-flex justify-content-between" style={{}}>
        <span>
          <h3 className="text-prpl2">
            {/* BoogEat vous recommande   */}
            {indexData?.Boogeat_recommends}
          </h3>

          {/* <p> {indexData?.descover} </p> */}
        </span>
      </div>
      <div className="slider-wrapper">
        <div className="d-flex justify-content-between ">
          <IconButton onClick={prevSlide}>
            <GrPrevious />
          </IconButton>
          <IconButton onClick={nextSlide}>
            <GrNext />
          </IconButton>
        </div>
        <Slider ref={sliderRef} {...settings}>
          {data?.map((item, index) => (
            <div key={index} className="rounded-4 card-slider">
              <span className="p-3">
                <span
                  className="rounded-4 p-3 "
                  onClick={() => hanldeserach(item?.id)}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "250px",
                      objectPosition: "top",
                    }}
                    className="object-fit-cover mx-auto rounded-4"
                    src={item?.image}
                    alt="Paella dish"
                  />
                </span>
              </span>
              <CardContent className="text-center pt-0">
                <Typography className="" variant="h6" color="text.secondary">
                  {item.name}
                </Typography>
              </CardContent>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
}

import { ArrowBackIos, Chat } from '@mui/icons-material'
import React from 'react'
import { useNavigate } from 'react-router-dom'


const Chose = () => {
    const navigate= useNavigate()
  
  return (
    <>
       <p onClick={()=>navigate(-1)}>

<ArrowBackIos  className='m-2' sx={{color:"black"}}  />
  </p>
  
    <div className='d-flex justify-content-center align-item-center'  style={{minHeight:"60vh"}}>
     
        <div className="row justify-content-center gap-4 align-items-center  my-2 py-2 mx-2 mx-md-4 " >

   <div className="col-md-4 shadow p-3 py-4 rounded-3 "  >
    <div className="d-flex align-items-center gap-3">

    <Chat sx={{color:"#442479",fontSize:"30px"}}></Chat>
    <div className="">
      <h4> FOIRE AUR QUESTIONS   </h4>
      <p> Réponses rapides aux questions les plus fréquentes (Mon compte, Mes réservations…)</p>
      <button className='btn border-1  ' onClick={()=>navigate("/faqs")} style={{border:"1px solid #442479",color:"#442479"}}>ACCEDER A LA FAQ</button>
      </div>
    </div>




   </div>

   <div className="col-md-4 shadow p-3 py-4  rounded-3 gap-3 align-items-center " >
   <div className="d-flex align-items-center gap-3">

<Chat sx={{color:"#442479",fontSize:"30px"}}></Chat>

<div className="">
<h4>  CHAT EN DIRRCT  </h4>
<p> Réponses rapides aux questions les plus fréquentes (Mon compte, Mes réservations…)</p>
<button className='btn  ' onClick={()=>navigate("/contact-us")} style={{border:"1px solid #442479",color:"#442479"}} >MASSAGERIE INSTANTANEE</button>
</div>
</div>
   </div>
  
  
        </div>
    </div>
    </>
  )
}

export default Chose
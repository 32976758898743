import { Button } from "@mui/material";

import { Modal, ModalFooter } from 'react-bootstrap'
import { handleErrors } from "../modules/handleErrors";
import apiClient from "../api/apiClient";
import { useEffect, useState } from "react";

const Editmenupopup = ({show,onHide,id,url, fetchData,singlemenu}) => {
    
  const [editname,seteditname]=useState("")
  const [price,setprice]=useState("")
 

  const handlefavoritedelete = async () => {

    try { 
  
     
    
    const response=    await apiClient.put(`/menus/${singlemenu?.id}/`,{name:editname,price:price} )
        if(!response.ok) return handleErrors(response.data);
      
         fetchData()

         onHide()

        console.log(response); 
    } catch (error) {
        console.error('Error:', error);
    }
  };


  useEffect(()=>{
    setprice(singlemenu.price)
    seteditname(singlemenu.name)
  },[])




  return (


    <>
    <Modal
           show={show}
           onHide={onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ zIndex: "1300" }}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
                Edit
               
              </Modal.Title>
            </Modal.Header>
           
            <Modal.Body>
           
                
                
            <input className="form-control mt-4" type="text"   value={editname}  onChange={(e)=>seteditname(e.target.value)}    />
            <input className="form-control mt-4" type="number"   value={Number(price)}  onChange={(e)=>setprice(e.target.value)}    />
                 
            </Modal.Body>
            <ModalFooter className="d-flex gap-2">
                <Button onClick={onHide} color="error" type="submit" variant="contained">NO</Button>
                <Button type="" onClick={handlefavoritedelete} variant="contained">Yes</Button>

            </ModalFooter>
            
            </Modal>
    </>
  )
}

export default Editmenupopup
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import apiClient from "../api/apiClient";
import { CircularProgress } from "@mui/material";
import ExportToExcel from "./ExportToExcel";
export const ReservationManagement = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [allrestaurant, setallrestaurant] = useState([]);
  const [allrestaurantloader, setallrestaurantloader] = useState(false);
  const [comprestaurant, setcomprestaurant] = useState([]);
  const [comprestaurantloader, setcomprestaurantloader] = useState(false);
  const [rejectrestaurant, setrejectrestaurant] = useState([]);
  const [rejectrestaurantloader, setrejectrestaurantloader] = useState(false);
  const [loader, setloader] = useState(false);

;
  const fetchallData = async () => {
    setallrestaurantloader(true);
    const result = await apiClient.get(`/bookings/?status=a&restaurant_id=${localStorage.getItem('selectedRestaurantId')}`);
    setallrestaurantloader(false);
    if (!result.ok) return;
    setallrestaurant(result?.data.results);
  };
  const fetchcompData = async () => {
    setcomprestaurantloader(true);
    const result = await apiClient.get(`/bookings/?status=c&restaurant_id=${localStorage.getItem('selectedRestaurantId')}`);
    setcomprestaurantloader(false);
    if (!result.ok) return;
    setcomprestaurant(result?.data.results);
  };
  const fetchRejectData = async () => {
    setcomprestaurantloader(true);
    const result = await apiClient.get(`/bookings/?status=c&restaurant_id=${localStorage.getItem('selectedRestaurantId')}`);
    setrejectrestaurantloader(false);
    if (!result.ok) return;
    setrejectrestaurant(result?.data.results);
  };

  const patchstatuse = async (id, status) => {
    setloader(true);
    const result = await apiClient.put(`/bookings/${id}/`, {
      status: status,
    });
    setloader(false);

    // Execute fetchallData() and fetchcompData() after 3 seconds
    setTimeout(() => {
      fetchallData();
      fetchcompData();
    }, 1000);
  };
  


  useEffect(() => {
    fetchallData();
    fetchcompData();
    // fetchRejectData();
  }, []);

  return (
    <div>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="All" value="1" />
              <Tab label="Accpected" value="2" />
              {/* <Tab label="Rejected" value="3" /> */}
            </TabList>
          </Box>
          <TabPanel value="1">
            <div className="row p-0 m-0">
              {allrestaurantloader ? (
                <div className="d-flex justify-content-center mt-4">
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                allrestaurant.map((item) => (
                  <>
                    <div className="col-12 mb-3">
                      <div
                        className="w-100    p-4 rounded-3 "
                        style={{
                          backgroundColor: "#F8F5FB",
                          border: "2px solid #E7E8F4",
                        }}
                      >
                        <div className="d-flex flex-wrap  gap-4">
                          <div className="d-flex align-items-center  gap-2">
                            <p>Customer:</p>{" "}
                            <p
                              className=""
                              style={{ color: "#B48ED2", fontSize: "13px" }}
                            >
                              {" "}
                              {item?.user_booked?.username}
                            </p>{" "}
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <p>Email;</p>{" "}
                            <p style={{ color: "#B48ED2", fontSize: "13px" }}>
                              {" "}
                              {item?.user_booked?.email}
                            </p>{" "}
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <p>Reservation date:</p>{" "}
                            <p style={{ color: "#B48ED2", fontSize: "13px" }}>
                              {" "}
                              {item?.date}
                            </p>{" "}
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <p>Reservation Time:</p>{" "}
                            <p style={{ color: "#B48ED2", fontSize: "13px" }}>
                              {" "}
                              {item?.time}
                            </p>{" "}
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <p>Status</p>{" "}
                            <p style={{ color: "#B48ED2", fontSize: "13px" }}>
                              {" "}
                              {item?.status_display}
                            </p>{" "}
                          </div>
                        </div>
                        <div className="d-flex gap-3 mt-3">
                          <button
                            onClick={() => patchstatuse(item?.id, "CM")}
                            disabled={loader}
                            className="btn "
                            style={{
                              backgroundColor: "green",
                              color: "white",
                              width: "130px",
                            }}
                          >
                            {loader ? "Accept" : "Accept"}
                          </button>
                          <button
                            onClick={() => patchstatuse(item?.id, "CA")}
                            disabled={loader}
                            className="btn "
                            style={{
                              backgroundColor: "red",
                              color: "white",
                              width: "130px",
                            }}
                          >
                            {loader ? "Reject" : "Reject"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                ))
              )}
              {!allrestaurantloader && <ExportToExcel jsonData={allrestaurant} />}
            </div>
          </TabPanel>
          <TabPanel value="2">
            {" "}
            <div className="row p-0 m-0">
              {comprestaurantloader ? (
                <div className="d-flex justify-content-center mt-4">
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                comprestaurant.map((item) => (
                  <div className="col-12 mb-3">
                    <div
                      className="w-100    p-4 rounded-3 "
                      style={{
                        backgroundColor: "#F8F5FB",
                        border: "2px solid #E7E8F4",
                      }}
                    >
                      <div className="d-flex flex-wrap  gap-4">
                        <div className="d-flex align-items-center  gap-2">
                          <p className="mb-0">Customer:</p>{" "}
                          <p
                            className="mb-0"
                            style={{ color: "#B48ED2", fontSize: "13px" }}
                          >
                            {" "}
                            {item?.user_booked?.username}
                          </p>{" "}
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <p className="mb-0">Email;</p>{" "}
                          <p
                            className="mb-0"
                            style={{ color: "#B48ED2", fontSize: "13px" }}
                          >
                            {" "}
                            {item?.user_booked?.email}
                          </p>{" "}
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <p className="mb-0">Reservation date:</p>{" "}
                          <p
                            className="mb-0"
                            style={{ color: "#B48ED2", fontSize: "13px" }}
                          >
                            {" "}
                            {item?.date}
                          </p>{" "}
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <p className="mb-0">Reservation Time:</p>{" "}
                          <p
                            className="mb-0"
                            style={{ color: "#B48ED2", fontSize: "13px" }}
                          >
                            {" "}
                            {item?.time}
                          </p>{" "}
                        </div>
                      </div>
                      <button
                        disabled={loader}
                        onClick={() => patchstatuse(item?.id, "CA")}
                        className="btn mt-3 "
                        style={{
                          backgroundColor: "red",
                          color: "white",
                          width: "130px",
                        }}
                      >
                        {loader ? "Reject" : "Reject"}
                      </button>
                    </div>
                  </div>
                ))
              )}
              {!comprestaurantloader && <ExportToExcel jsonData={comprestaurant} />}
            </div>
          </TabPanel>
          <TabPanel value="3">
            {" "}
            <div className="row p-0 m-0">
              {rejectrestaurantloader ? (
                <div className="d-flex justify-content-center mt-4">
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                rejectrestaurant.map((item) => (
                  <div className="col-12 mb-3">
                    <div
                      className="w-100    p-4 rounded-3 "
                      style={{
                        backgroundColor: "#F8F5FB",
                        border: "2px solid #E7E8F4",
                      }}
                    >
                      <div className="d-flex flex-wrap  gap-4">
                        <div className="d-flex align-items-center  gap-2">
                          <p className="mb-0">Customer:</p>{" "}
                          <p
                            className="mb-0"
                            style={{ color: "#B48ED2", fontSize: "13px" }}
                          >
                            {" "}
                            {item?.user_booked?.username}
                          </p>{" "}
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <p className="mb-0">Email;</p>{" "}
                          <p
                            className="mb-0"
                            style={{ color: "#B48ED2", fontSize: "13px" }}
                          >
                            {" "}
                            {item?.user_booked?.email}
                          </p>{" "}
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <p className="mb-0">Reservation date:</p>{" "}
                          <p
                            className="mb-0"
                            style={{ color: "#B48ED2", fontSize: "13px" }}
                          >
                            {" "}
                            {item?.date}
                          </p>{" "}
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <p className="mb-0">Reservation Time:</p>{" "}
                          <p
                            className="mb-0"
                            style={{ color: "#B48ED2", fontSize: "13px" }}
                          >
                            {" "}
                            {item?.time}
                          </p>{" "}
                        </div>
                      </div>
                      <button
                        disabled={loader}
                        onClick={() => patchstatuse(item?.id, "CA")}
                        className="btn mt-3 "
                        style={{
                          backgroundColor: "red",
                          color: "white",
                          width: "130px",
                        }}
                      >
                        {loader ? "Reject" : "Reject"}
                      </button>
                    </div>
                  </div>
                ))
              )}
              {!comprestaurantloader && <ExportToExcel jsonData={rejectrestaurantloader} />}
            </div>
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import Chart from "./Chart";
import ChartBar from "./ChartBar";
import apiClient from "../api/apiClient";
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectAdminData, setAdminData } from "../redux/adminSlice";
// import { fetchAdminData } from "../redux/adminSlice";

const ResDashbord = () => {
  const { userid } = useSelector((state) => state.auth);
  // const [dashboardData, SetDashboardData] = useState();
  const [loader, Setloader] = useState(false);
  const dispatch = useDispatch();
  const adminData = useSelector(selectAdminData);

  const fetchData = async () => {
    const savedRestaurantId =  localStorage.getItem('selectedRestaurantId');
    Setloader(true);
    const result = await apiClient.get(`/owner-restaurant/${savedRestaurantId}/stats/`);
    Setloader(false);
    if (result.ok) {
      // SetDashboardData(result.data);
      dispatch(setAdminData(result.data));
    }
  };
  useEffect(() => {
    fetchData();
    // dispatch(fetchAdminData());
  }, []);
  // console.log(dashboardData, "dashboardData");
  return (
    <div className="">
      {loader ? (
        <div className="d-flex justify-content-center mt-4">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <div>
          <div className="d-flex gap-3 mx-3 flex-wrap my-4">
            <div
              className="sacnshadow p-3 rounded-4"
              style={{ backgroundColor: "#F5F0F9", width: "220px" }}
            >
              <p className="fw-bolder ">Number of bookings</p>
              <h4 className="fw-bold mb-3 " style={{ color: "#56009a" }}>
                {" "}
                {adminData?.user_total_bookings}{" "}
              </h4>
            </div>
            <Link to={"/restaurant/ReservationManagement"}  className="text-decoration-none text-dark" >
           
            <div
              className="sacnshadow p-3 rounded-4"
              style={{ backgroundColor: "#F5F0F9", width: "220px" }}
            >
              <p className="fw-semibold   "> Accepted Bookings</p>
              <h4 className="fw-bold mb-3 " style={{ color: "#56009a" }}>
                {adminData?.user_comfirmed_bookings}
              </h4>
            </div>
            </Link>
            <Link to={"/restaurant/ReservationManagement"}  className="text-decoration-none text-dark" >
           
            <div
              className="sacnshadow p-3 rounded-4"
              style={{ backgroundColor: "#F5F0F9", width: "220px" }}
            >
              <p className="fw-semibold   ">Pending Bookings</p>
              <h4 className="fw-bold mb-3 " style={{ color: "#56009a" }}>
                {adminData?.user_pending_bookings}
              </h4>
            </div>
            </Link>
          </div>
          <div className="d-flex flex-wrap  gap-3 mx-3 my-4">
          <Link to={"/restaurant/ReservationManagement"} className="text-decoration-none text-dark" >
        
            <div
              className="sacnshadow p-3 rounded-4"
              style={{ backgroundColor: "#F5F0F9", width: "220px" }}
            >
              <p className="fw-semibold   ">Canceled Bookings</p>
              <h4 className="fw-bold mb-3 " style={{ color: "#56009a" }}>
                {adminData?.user_cancelled_bookings}
              </h4>
            </div>
            </Link>
            
            <Link
              to={`/restaurant/addmenus`}
              className="text-decoration-none"
            >
              <div
                className="sacnshadow p-3 rounded-4"
                style={{ backgroundColor: "#F5F0F9", width: "220px" }}
              >
                <p className="fw-semibold  text-dark "> Number of menus </p>
                <h4 className="fw-bold mb-3 " style={{ color: "#56009a" }}>
                  {adminData?.user_total_menus}
                </h4>
              </div>
            </Link>
            <Link
              to={`/restaurant/cuisinesadd`}
              className="text-decoration-none"
            >
              <div
                className="sacnshadow p-3 rounded-4"
                style={{ backgroundColor: "#F5F0F9", width: "220px" }}
              >
                <p className="fw-semibold text-dark   "> Number of cuisine </p>
                <h4 className="fw-bold mb-3 " style={{ color: "#56009a" }}>
                  {adminData?.user_total_cousines}
                </h4>
              </div>
            </Link>
          </div>
          {/* <div className="row mt-4">
            <div className="col-lg-5 p-3">
              <div className='bg-white p-3 rounded-3' style={{boxShadow: "0px 0px 3px 3px rgb(0,0,0,0.05)"}}>
                <div className="d-flex justify-content-between my-3">
                  <span className='h5 fw-bold'>Bookings</span>
               
                </div>
                <div style={{overflow:"auto"}}>
                <ChartBar/>
                </div>
              </div>
            </div>
            <div className="col-lg-6 p-3">
              <div className='bg-white p-3 rounded-3' style={{boxShadow: "0px 0px 3px 3px rgb(0,0,0,0.05)"}}>
                <div className="d-flex justify-content-between my-3">
                  <span className='h5 fw-bold'>Services</span>
                  <small className='text-gray'>Current Year</small>
                </div>
                <div style={{overflow:"auto"}}>
                <Chart/>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default ResDashbord;

import { Box } from '@mui/material';
import React, { useState } from 'react';

import { Link, useNavigate, useParams } from 'react-router-dom';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import { Close } from '@mui/icons-material';
import { MdLockOpen } from "react-icons/md";
import apiClient from '../api/apiClient';
import { handleErrors } from '../modules/handleErrors';
import { toast } from 'react-toastify';

export default function ResetPassword() {
    const [loader,setloader]=useState(false)
    const [password,setpassword]=useState("")
    const [confirmpass,setconfirmpass]=useState("")
const navigate=useNavigate()
    const paramdata=useParams()
    

        const onSubmit = async (e) => {
            e.preventDefault();
            console.log("submit");
            if(confirmpass!==password)return  toast.error("Password and confirm password does not match")
            try {
               
           
                setloader(true)
                const response = await apiClient.post('/confirm-password/', {
                "uidb64":paramdata?.id,
                "token":paramdata?.token,
                "new_password":password
              });
                setloader(false)
                if(!response.ok)return handleErrors(response.data)
                  navigate("/")
                
                if (response.ok)  toast.success("Password changed successfully!")
                // navigate("/admin/resturants")
            } catch (error) {
                console.error('Error:', error);
            }
        };
  return (

    <>
   <div className='d-flex justify-content-center align-items-center' style={{height:"80vh"}}>

    <Box className="px-4 my-5 sacnshadow" sx={{width:{sm:"400px",xs:"90%"},backgroundColor:"white",color:"",borderRadius:"19px"}}>
    {/* <div className=" d-flex justify-content-end mt-4">
 <Close className='text-end ms-auto'  style={{backgroundColor:"red",borderRadius:"50%"}}/>
        </div>     */}
        <form onSubmit={onSubmit} className="my-4 mx-3">

    <div className='text-center mb-4  ' > 
      <MdLockOpen className='p-2 ' style={{color:"#2C9BF6",borderRadius:"29px", backgroundColor:"#151C29",fontSize:"49px",borderTop:"2px solid #1F649F",borderRight:"1px solid #1F649F",borderLeft:"1px solid #1F649F"}}/>  </div>
  
    
    <h6 className='text-center'> New Password</h6>
    <p className='text-center' style={{fontSize:"9px",opacity:"0.7"}}>Please enter a new password</p>
    <div className="row">
    <div className=" mb-3  p-0 ">
<label style={{color:""}} for="exampleInputEmail1" class="form-label m-0">New Password</label>
<input  type="password" onChange={(e)=>setpassword(e.target.value)} placeholder='At least 8 character' class="form-control  " id="exampleInputEmail1" aria-describedby="emailHelp"/>

</div>
    <div className=" mb-3  p-0 ">
<label style={{color:""}} for="exampleInputEmail1" class="form-label m-0">Confirm Password</label>
<input  onChange={(e)=>setconfirmpass(e.target.value)} type="password" value={confirmpass} placeholder='At least 8 character' class="form-control  " id="exampleInputEmail1" aria-describedby="emailHelp"/>

</div>

</div>

<div className="row ">
{/* <Link to={"/password-changed"} className='text-center'> */}

  <button disabled={loader}  style={{backgroundColor:"#442479"}}  type="submit" class="btn  mt-5 text-light  text-center px-5 mb-2  mx-auto">{loader?"Submit...":"Submit"}</button>
{/* </Link> */}

  
  
</div>
        </form>
  

    </Box>


    </div>
    
    </>



  
  );
}
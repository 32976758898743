import React, { useEffect, useRef } from "react"
function Map({ setAddress, address, location }) {
  const mapContainerRef = useRef(null)
  const inputRef = useRef(null)
  const autocompleteRef = useRef(null)
  const map = useRef(null)
  useEffect(() => {
    loadGoogleMapsScript()
  }, [])
  const loadGoogleMapsScript = () => {
    const script = document.createElement("script")
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDQmPq0HH1XTcSNa55xUP748V1Vv4hhK1w&libraries=places`
    script.async = true
    script.defer = true
    script.onload = initMap
    document.head.appendChild(script)
    
  }
  const initMap = () => {
    map.current = new window.google.maps.Map(mapContainerRef.current, {
      center: { lat: 40.76, lng: -73.983 },
      zoom: 15
    })
    map.current.setTilt(45)
    autocompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current)
    autocompleteRef.current.addListener("place_changed", handlePlaceSelect)
    // Set initial value from the address prop
    inputRef.current.value = address.formatted_address || ""
    // Check if the address prop has coordinates, and if so, update the map
    if (address.latitude && address.longitude) {
      const { latitude, longitude } = address
      map.current.setCenter({ lat: latitude, lng: longitude })
      map.current.setZoom(15)
    }
  }
  const handlePlaceSelect = () => {
    const place = autocompleteRef.current.getPlace()
    console.log(place, "kkkl")
    if (place.place_id) {
      const geocoder = new window.google.maps.Geocoder()
      geocoder.geocode({ placeId: place.place_id }, (results, status) => {
        if (status === "OK" && results[0] && results[0].geometry) {
          let lng = place.geometry.location.lng()
          let lat = place.geometry.location.lat()
          const { address_components, geometry, formatted_address } = place
          const jsonData = {
            city:
              getAddressComponentValue(address_components, "locality") ||
              getAddressComponentValue(address_components, "administrative_area_level_3"),
            state: getAddressComponentValue(address_components, "administrative_area_level_1"),
            country: getAddressComponentValue(address_components, "country"),
            latitude: geometry.location.lat(),
            longitude: geometry.location.lng(),
            formatted_address: formatted_address
          }
          setAddress(jsonData)
          map.current.setCenter({ lat, lng })
          map.current.setZoom(10)
        } else {
          console.error("Unable to retrieve coordinates for the selected place:", status)
        }
      })
    } else {
      console.error("No valid place_id in the selected place:", place)
    }
  }
  const getAddressComponentValue = (addressComponents, type) => {
    const component = addressComponents.find(component => component.types.includes(type))
    return component ? component.long_name : ""
  }
  return (
    <div className="d-flex justify-content-center flex-column">
      <input
        ref={inputRef}
        className="  mb-3 form-control "
        style={{ borderRadius: "8px" }}
        id="city-input"
        type="text"
        
        placeholder="Restaurant   Location "
      />
      <div ref={mapContainerRef} style={{ height: "400px", width: "500px" }}></div>
    </div>
  )
}
export default Map
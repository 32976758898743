import React, { useState } from 'react';
import { FaPlusCircle } from 'react-icons/fa';
import { handleErrors } from '../modules/handleErrors';
import apiClient from '../api/apiClient';
import { useNavigate } from 'react-router-dom';

const AddSocialMedia = () => {
    const navigater=useNavigate()
    const [Social,setSocial]=useState({facebook:"",insta:"",twitter:""})
    const handleonchage=(key,value)=>{
        setSocial((pre)=>({...pre,[key]:value}))

    }


 const handlesubmit = async (e) => {
    e.preventDefault()
    try {
     
      
     
  
        const response = await apiClient.post('/social/',Social
      
       
         );
    
         if(!response.ok) return handleErrors(response.data)
         navigater(-1)
        //  setisfav(response?.data?.restaurant)
        //  console.log(response?.data?.restaurant?.is_favorite);
  
    } catch (error) {
        console.error('Error:', error);
    }
  };
    return (



        <div className="">
                <div className="right-mid mx-1 mx-sm-3  p-2">
                    <div className=" row d-flex justify-content-between align-items-center">
                        <div className="col-12 col-sm-7 p-0 my-3">
                            <h4 className="mb-0 mx-2">Add Social Media</h4>
                            {/* <p className="mb-0 mx-2">
                                Welcome to your Dashboard. The Dashboard is a great place to monitor
                                all the activity within the Hub giving you full control and
                                oversight.
                            </p> */}
                        </div>
                    </div>
                </div>

                <div className="right-bottom mx-1 mx-sm-3 ">
                    <div className="bottom-inner">
                        <div className="bottom-inner">
                            <form action="" onSubmit={handlesubmit}>
                              
                                {/* <div className=' rounded-5 mb-3' style={{width:"170px", background:"#F7F6F9"}} >
                                    <label className='mt-3 d-flex justify-content-center align-items-center flex-column p-3 ' htmlFor="restimg" style={{height:"17vh"}}>
                                    <FaPlusCircle />
                                    <p className='mt-3'>
                                    Upload Image
                                    </p>
                                    </label>

                                    <input hidden type="file"  name="" id="restimg" />
                                    
                                </div> */}
                               
                                


                        <div className="row px-5">

                            <div className="col-12 col-sm-6 py-1">
                                <input
                                onChange={(e)=>handleonchage("facebook",e.target.value)}
                                    type="url"
                                    placeholder="Facebook"
                                    className="form-control"
                                />
                            </div>

                     </div>

                        <div className="row px-5">

                            <div className="col-12 col-sm-6 py-1">
                                <input
                                  onChange={(e)=>handleonchage("insta",e.target.value)}
                                    type="url"
                                    placeholder="Instagram"
                                    className="form-control"
                                />
                            </div>

                     </div>

                        <div className="row px-5">

                            <div className="col-12 col-sm-6 py-1">
                                <input
                                  onChange={(e)=>handleonchage("twitter",e.target.value)}
                                    type="url"
                                    placeholder="Twitter"
                                    className="form-control"
                                />
                            </div>

                     </div>



                            <div className="col-12  mb-2 py-1">
                                <button
                                    type="submit"
                                    className="gradient col-md-2 text-decoration-none px-5 border-0 py-2 text-white rounded-3">
                                    Save
                                </button>
                            </div>


                            </form>
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default AddSocialMedia;

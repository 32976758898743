import { Modal } from 'react-bootstrap';
import ShareButton from "./ShareButton";

const Copypopup = ({show,onHide,id,url, fetchData}) => {
 
  return (


    <>
    <Modal
           show={show}
           onHide={onHide}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ zIndex: "1300" }}
          >
            
           
            <Modal.Body>
             
            <ShareButton/>
            </Modal.Body>
            {/* <ModalFooter className="d-flex gap-2">
                <Button onClick={onHide} color="error" type="submit" variant="contained">NO</Button>
                <Button type="" variant="contained">Yes</Button>

            </ModalFooter> */}
            
            </Modal>
    </>
  )
}

export default Copypopup
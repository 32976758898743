import React, { useEffect, useState } from 'react'
import { getCurrentURLParameters } from '../modules/helpers';
import apiClient from '../api/apiClient';
import { CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';
import { handleErrors } from '../modules/handleErrors';

const ResCuisinesadd = () => {



    const [data, setdata] = useState([]);
    const [cusloader, setcusloader] = useState(false);
    const [Addcusloader,Addsetcusloader] = useState(false);
    const [Allcuisin, setAllcuisin] = useState([]);
    const [Selectcuisin, setSelectcuisin] = useState(null);
    


    const id =  localStorage.getItem('selectedRestaurantId');

    const fetchData = async () => {

        try {
          setcusloader(true);
          const result = await apiClient.get(`/cuisines/?restaurant_id=${id}`);
          setcusloader(false);
          if (!result.ok) return;
          setdata(result.data.results);
        } catch (error) {
          console.error("Error fetching menu data:", error);
        }
      };
    const fetchAllCui = async () => {

        try {
          // setcusloader(true);
          const result = await apiClient.get(`/cuisines/`);
          // setcusloader(false);
          if (!result.ok) return;
          setAllcuisin(result.data.results);
        } catch (error) {
          console.error("Error fetching menu data:", error);
        }
      };


      useEffect(()=>{
        fetchAllCui()
        fetchData()
      },[])
const deletecui =async (deleteid)=>{
  const result = await apiClient.post(`/cuisines/remove-cuisine/?restaurant_id=${id}`,{
    "cuisine_id": deleteid

});
 if(!result.ok) return handleErrors(result.data)
 toast.success("Successfully deleted")
 fetchData()
}
const Addtecui =async ()=>{
  Addsetcusloader(true)
  console.log("addd");
  const result = await apiClient.post(`/cuisines/add-cuisine/?restaurant_id=${id}`,{
    "cuisine_id": Selectcuisin
});
Addsetcusloader(false)
 if(!result.ok) return handleErrors(result.data)
 toast.success(" Successfully added ")
 fetchData()
}

  return (
    <div>
<div className="d-flex align-items-end gap-3">
      <div className="" style={{maxWidth:"200px"}}>

        <div class="form-group">
    <label for="exampleFormControlSelect1">Cuisines</label>
    <select class="form-control" id="exampleFormControlSelect1"onChange={(e)=>setSelectcuisin(e.target.value)} value={Selectcuisin} >
    
      {Allcuisin?.map((item)=>(

<option value={item?.id}>  {item?.name} </option>
))}
    </select>
  </div>
      </div>
 <button className='btn  text-light ' style={{backgroundColor:"#684B97"}} onClick={Addtecui} >{ Addcusloader? "Add cuisine...":"Add cuisine"}</button>
</div>
        {/* <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Age</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    
    label="Age"
 
  >
 
   
  </Select>
</FormControl> */}
        {!cusloader ? (
          <div className="row">




            {data.map((item, index) => (
              <div key={index} className="col-md-3 rounded-4 card-slider">
                <span className="rounded-2">
                  <div
                    className="bg-img rounded-3 position-relative"
                    style={{
                      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${item.image})`,
                    }}
                  >
                    <div
                      className="position-absolute text-white"
                      style={{ bottom: "10px", left: "10px" }}
                    >
                      {item.name}
                    </div>
                    <div
                      className="position-absolute text-white"
                      style={{ top: "10px", right: "10px" }}
                    >
                      <MdDelete 

                        onClick={() => deletecui(item.id)}
                        style={{ fontSize: "20px" }}
                      />
                    </div>
                    <div
                      className="position-absolute text-white"
                      style={{ top: "30px", right: "10px" }}
                    >
                 
                      {/* <p onClick={() => handleEidt(item)}> */}
             
                      {/* </p> */}
                      {/* )} */}
                    </div>
                  </div>
                </span>
              </div>
            ))}
          </div>
        ) : (
          <div className=" mx-auto text-center d-flex justify-content-center mt-5">
            <CircularProgress color="secondary" />
          </div>
        )}
    </div>
  )
}

export default ResCuisinesadd
import React, { useEffect, useState } from 'react';
import apiClient from '../api/apiClient';
import { FaLocationDot } from 'react-icons/fa6';
import { MdDelete } from 'react-icons/md';
import { CiEdit } from 'react-icons/ci';
import { Link, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import MediaCard from '../admin/Muicard';

export const ResRestaurents = () => {
  const [loading, setLoading] = useState(false);
  const [restaurantsData, setRestaurantsData] = useState([]);
  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await apiClient.get(`/owner-restaurant/restaurant_info/`);
      if (result.ok) setRestaurantsData(result.data.restaurants);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const navigate= useNavigate()

  const handleCardClick = (id) => {
    localStorage.setItem('selectedRestaurantId', id); // Save restaurant id to localStorage
    navigate("/restaurant/dashboard")


  };



  return (
    <div className=""style={{backgroundColor: ''}} >

<img
                src={require("../assets/logo.png")}
                alt=""
                style={{ height: "120px" }}
                className=" mx-2"
              />
    <div className="d-flex mt-5 align-items-center justify-content-center flex-row">
      {!loading&& restaurantsData.length===0?<div className="d-flex justify-content-center align-items-center gap-3 w-100"> <h1 className='text-light' >No Restaurant Attached </h1></div> :
      
      <div className='d-flex justify-content-center align-items-center gap-3 w-100'>
     
        
           {!loading && restaurantsData.length>0? 
     <div className="row flex-wrap justify-content-center align-items-center gap-3 w-100">
        {restaurantsData?.map((item, index) => (
          < MediaCard item={item} handleCardClick={handleCardClick}/>
        ))}
      </div>
      : <CircularProgress sx={{color:"white"}} 
      />
      
      
      }</div>}


      
    </div>
    </div>
  );
};

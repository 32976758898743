import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import { CardContent, IconButton, Rating, Typography } from "@mui/material";
import { GrNext, GrPrevious } from "react-icons/gr";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import apiClient from "../api/apiClient";
import { Link } from "react-router-dom";
import { handleErrors } from "../modules/handleErrors";
import { SyncLoader } from "react-spinners";
import { useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import ErrorMessagePopup from "./ErrorMessagePopup";

export const SliderNearRea = ({
  heading,
  resturentdata,
  fetchData,
  fetchDatacityresturent,
}) => {
  const sliderRef = useRef(null);
  const [isfav, setisfav] = useState("");
  const [showmodel, setshowmodel] = useState(false);
  const [isfavloader, setisfavloader] = useState(false);
  const [isfavloaderid, setisfavloaderid] = useState(null);
  const [initialized, setInitialized] = useState(false);

  const { token, userType, isLoggedIn, userid } = useSelector(
    (state) => state.auth
  );
  const isMobile = useMediaQuery("(max-width: 600px)");

  useEffect(() => {
    if (resturentdata.length > 0) {
      setInitialized(true);
    }
  }, [resturentdata]);

  const handlefavorite = async (id) => {
    try {
      setisfavloaderid(id);
      setisfavloader(true);

      const response = await apiClient.post("/favorite-restaurants/", {
        restaurant: id,
      });
      setisfavloader(false);
      fetchData();
      fetchDatacityresturent();

      if (!response.ok) return handleErrors(response.data);

      setisfav(response?.data?.restaurant);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handlefavoritedelete = async (id, itemid) => {
    try {
      setisfavloaderid(itemid);
      setisfavloader(true);

      const response = await apiClient.delete(`/favorite-restaurants/${id}/`);
      setisfavloader(false);

      if (!response.ok) return handleErrors(response.data);
      fetchData();
      fetchDatacityresturent();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const prevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="custom-arrows d-flex justify-content-between">
        <span>
          <h3 className="text-prpl2">{heading}</h3>
        </span>
      </div>
      <div className="slider-wrapper">
        <div className="d-flex justify-content-between">
          <IconButton onClick={prevSlide}>
            <GrPrevious />
          </IconButton>
          <IconButton onClick={nextSlide}>
            <GrNext />
          </IconButton>
        </div>
        {initialized && (
          <Slider ref={sliderRef} {...settings}>
            {resturentdata.length > 0  ? resturentdata?.map((item, index) => (
              <div key={index} className="col-md-3 rounded-4 card-slider">
                <Link to={`/detail?id=${item?.restaurant?.id}`}>
                  <div className="d-flex justify-content-center">
                    <div className="rounded-4 p-3" style={{ width: "15rem" }}>
                      <img
                        style={{ width: "100%", height: "220px" }}
                        className="img-fluid mx-auto rounded-4"
                        src={item?.restaurant?.images[0]?.image}
                        alt="Paella dish"
                      />
                    </div>
                  </div>
                </Link>
                <div className="px-3 pb-5">
                  <div className="d-flex justify-content-between align-items-center">
                    <span>
                      <Link to={`/detail?id=${item?.restaurant?.id}`} className="text-decoration-none text-black">
                        <p className="mb-1 text-decoration-none">
                          <FaLocationDot className="fs-4 mx-2" />
                          {item?.restaurant?.city_name?.name}
                        </p>
                        <h4 className="text-prpl2">{item?.restaurant?.name}</h4>
                      </Link>
                    </span>
                    <span>
                      {isLoggedIn ? (
                        isfavloader && isfavloaderid === item?.restaurant?.id ? (
                          <SyncLoader size={10} color="#442479" />
                        ) : item?.restaurant?.is_favorite ? (
                          <FaHeart
                            className="fs-4"
                            onClick={() =>
                              handlefavoritedelete(
                                item?.restaurant?.fav_id,
                                item?.restaurant?.id
                              )
                            }
                            style={{ color: "#442479" }}
                          />
                        ) : (
                          <FaRegHeart
                            className="fs-4"
                            onClick={() => handlefavorite(item?.restaurant?.id)}
                          />
                        )
                      ) : (
                        <FaRegHeart
                          className="fs-4"
                          onClick={() => setshowmodel(true)}
                        />
                      )}
                    </span>
                  </div>
                  <ErrorMessagePopup
                    errormessage={"Please Login To Proceed!"}
                    show={showmodel}
                    onHide={() => setshowmodel(false)}
                  />
         
                  <Rating
                    name="read-only"
                    value={item?.restaurant?.average_rating}
                    readOnly
                    className="fs-2"
                  />
                </div>
              </div>
            )) : <p>No Restaurant near you.</p>}
          </Slider>
        )}
      </div>
    </>
  );
};

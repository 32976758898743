import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css"

import { Indexpagedata } from './contextapi/Indexpagedata';
import apiClient from './api/apiClient';

// Listen for the appinstalled event
const handldownloeadpwa = async () => {

  try {
   
    
   

      const response = await apiClient.post("/eat/create_eat/",{status:"app"})
 
  
    
 
  } catch (error) {
 
  }
};

window.addEventListener('appinstalled', (event) => {
  console.log(event,"event");
  handldownloeadpwa()
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store} >
    <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
  <BrowserRouter>
  <Indexpagedata>
  <App />
  </Indexpagedata>
  </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

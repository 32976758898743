import React, { useEffect, useState } from 'react'
import apiClient from '../api/apiClient';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Cancel, Delete, Edit } from '@mui/icons-material';
import { handleErrors } from '../modules/handleErrors';

const Faqs = () => {
    const [qua, setqus] = useState('');
    const [ans, setans] = useState('');
    const [Editid, seteidetid] = useState('');
    const [data, setdata] = useState([]);
    const [loader, setloader] = useState(false); // State to store the picture file

    const navigate = useNavigate()
    const fetchfaqData  = async()=>{

        const result = await apiClient.get(`/faqs/`)
        if(!result.ok) return
      console.log(result?.data.results,"lkkkkkkkkkkkkkkk");
         setdata(result?.data.results)
      }

      useEffect(()=>{
        fetchfaqData()
      },[])

    

    const onSubmit = async (e) => {
        try {
            e.preventDefault();
        
            const response = Editid? await apiClient.put(`/faqs/${Editid}/`,{"question":qua,"answer":ans}): await apiClient.post('/faqs/',{"question":qua,"answer":ans})
            // setloader(false)
            if(!response.ok)return handleErrors(response.data)
            fetchfaqData()
            setqus("")
            setans("")
           

           
            if (response.ok)  Editid?  toast.success(" Update successfully"): toast.success(" added successfully")
            seteidetid("")
            console.log(response); // Log the response from the server
        } catch (error) {
            console.error('Error:', error);
        }
    };
 
 const handleEidt= (item)=>{
    seteidetid(item.id)
     setqus( item.question)
    setans(item.answer) 
    console.log();

 }
   
 console.log(Editid,"klklkl");

 const handledelete=(id)=>{

     apiClient.delete(`/faqs/${id}/`)
  
    toast.success("Deleted successfully")
    setTimeout(() => {
        fetchfaqData()
    }, 1000);
  

 }
  return (
    <div>
        <div className="row justify-content-between  ">

       
           <div className="col-md-4 mt-4">
                <input type="text" value={qua} onChange={(e) => setqus(e.target.value)} placeholder="Question" className="form-control" />
                <textarea  value={ans} onChange={(e) => setans(e.target.value)} placeholder="Answer" cols="30" rows="10" className="form-control my-3" />
       
                <button className="btn" style={{ backgroundColor: "#492c79", color: "white" }} onClick={onSubmit}>{Editid?  ( !loader?"Edit":"Edit..."):  ( !loader?"Add":"Add...")}</button>
                
            </div>
            
            <div className="col-md-5">
              { data.map((item)=>{
                return(
<div className="" key={item.id}>
    <div className=" d-flex "  >

   {Editid? (Editid!==item.id? <Edit  sx={{color:"black"}} />  : 
   <p onClick={()=>{  
    seteidetid(null)
    setans("")
    setqus("")
    
    }} >
      
      <Cancel  sx={{color:"black"}}/>
      
      
      </p> ): <p onClick={()=>handleEidt(item)}><Edit  sx={{color:"black"}}/></p> }

<p onClick={()=>handledelete(item?.id)}><Delete sx={{color:"black"}}/></p>

    </div>
                    <h5>{item?.question}</h5>
                    {item?.answer && (item?.answer.length > 200 ? (
  <p>{item.answer.substring(0, 300)}...</p>
) : (
  <p>{item.answer}</p>
))}                <hr />
                </div>
                )
                

              })

              }

            </div>
            </div>
    </div>
  )
}

export default Faqs
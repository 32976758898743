import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { FaPlusCircle } from "react-icons/fa";
import apiClient from "../api/apiClient";
import { toast } from 'react-toastify';
import { handleErrors } from "../modules/handleErrors";


const EditContent = () => {
  const [value, setValue] = React.useState("1");
  const [aboutimagepreview, setaboutimagepreview] = useState(null);
  const [HomePage, setHomePage] = useState({
    page_title: "",
    video_title: "",
    address: "",
    
    copyright: "",
    social_text: "",
    "search_title": "",
    "around": "",
    "descover": "",
    "popular": "",
    "Boogeat_recommends": "",
    "other_city": "",
   
  });
 

  const [aboutdata, setaboutdata] = useState({
    description: "",
    image: "",
    title: "",
    banner_text: "",
  });

  const handleImageChange = (e) => {
    const imageFile = e.target.files[0];
    const previewImageUrl = URL.createObjectURL(imageFile);
    setaboutimagepreview(previewImageUrl)
    setaboutdata({
      ...aboutdata,
      image: imageFile,
      previewImage: previewImageUrl,
    });
  };



  const onSubmitHomeaboutPage = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
// if(aboutdata.previewImage){
 

// }
console.log(typeof aboutdata?.image,"kljkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk");
    if(typeof aboutdata?.image == "object"){

      formData.append("image", aboutdata?.image); 
    }
      
   
      formData.append("description", aboutdata?.description);
      formData.append("title", aboutdata?.title);
      formData.append("banner_text", aboutdata?.banner_text);
      const response =    aboutdata?.id? await apiClient.put(`/aboutus_content/${aboutdata?.id}/`, formData):await   apiClient.post("/aboutus_content/", formData)
      if(!response.ok) handleErrors(response?.data);


      toast.success("About us updated")
 
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const GetHomeaboutPage = async (e) => {

    try {
  
      const response = await apiClient.get("/aboutus_content/");
      if(!response.ok) handleErrors(response?.data);

console.log(response?.data?.results,"response?.data?.result");

setaboutdata(...response?.data?.results)
setaboutimagepreview(response.data?.results[0].image)

     
  
     
    } catch (error) {
      console.error("Error:", error);
    }
  };


 
  useEffect(()=>{GetHomeaboutPage()},[])
  const handleChangeabout = (e) => {
    const { name, value } = e.target;
    setaboutdata({
      ...aboutdata,
      [name]: value,
    });
  };

  console.log(aboutimagepreview);

  const fetchhomepageData  = async()=>{

    const result = await apiClient.get("/index_content/")
    if(!result.ok) return
  
     setHomePage(...  result?.data.results)
   
  }

  useEffect(()=>{
    fetchhomepageData()
  },[])
  const onSubmitHomePage = async (e) => {
    try {
      // Make an API
      e.preventDefault();
      const response = await apiClient.post("/index_content/", HomePage);
      fetchhomepageData()
      if(response.ok) toast.success("Home page Data updated");
      GetHomeaboutPage()
      console.log(response); // Log the response from the server
    } catch (error) {
      console.error("Error:", error);
    }
  };




  const handleChangeHome = (event) => {
    const { name, value } = event.target;
    setHomePage({
      ...HomePage,
      [name]: value,
    });
  };



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
 
console.log(aboutimagepreview,"aboutimagepreview");
  return (
    <div>
      <div>
        <h2>Edit Content</h2>

      
      </div>

      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Home Page" value="1" />
            <Tab label=" About us Page" value="2" />
            {/* <Tab label="Contact us Page" value="3" /> */}
          </TabList>
        </Box>

        <TabPanel value="1">
          <h3>Header Section</h3>

          <form action="" onSubmit={onSubmitHomePage}>
            <div className="row">

           
            <div className="col-md-6  py-1">
              
            
               <input
               placeholder="Search Title"
               value={HomePage?.search_title}
                type="text"
                name="search_title"
                onChange={handleChangeHome}
                className="form-control  my-2"
              />
              <input
                type="text"
                value={HomePage?.copyright}
                name="copyright"
                placeholder="Copy Right"
                onChange={handleChangeHome}
                className="form-control my-2"
              />
            

              <input
                type="text"
                value={HomePage?.address}
                name="address"
                placeholder="Address"
                onChange={handleChangeHome}
                className="form-control"
              />
              <input
                type="text"
                value={HomePage?.video_title}
                name="video_title"
                placeholder="Video Title"
                onChange={handleChangeHome}
                className="form-control  my-2"
              />
              <input
                type="text"
                name="popular"
                value={HomePage?.popular}
                placeholder="Popular"
                onChange={handleChangeHome}
                className="form-control"
              />

              <input
                type="text"
                name="social_text"
                value={HomePage?.social_text}
                placeholder="Social Text"
                onChange={handleChangeHome}
                className="form-control my-2"
              />
              <input
                type="text"
                name="Boogeat_recommends"
                value={HomePage?.Boogeat_recommends}
                placeholder="Boogeat recommends"
                onChange={handleChangeHome}
                className="form-control my-2"
              />
              <input
                type="text"
                name="descover"
                value={HomePage?.descover}
                placeholder="Descover"
                onChange={handleChangeHome}
                className="form-control my-2"
              />
              <input
                type="text"
                name="around"
                value={HomePage?.around}
                placeholder="Around"
                onChange={handleChangeHome}
                className="form-control my-2"
              />
              {/* <input
                type="text"
                value={HomePage?.search_title}
                name="search_title"
                placeholder="Search title"
                onChange={handleChangeHome}
                className="form-control my-2"
              /> */}
              <input
                type="text"
                name="other_city"
                value={HomePage?.other_city}
                placeholder="other city"
                onChange={handleChangeHome}
                className="form-control my-2"
              />
              {/* <input
  type="file"
  name="video"
  value={HomePage?.video}
  placeholder="Video"
  onChange={handleChangeHome}
  className="form-control my-2"
  accept="video/*" 
/> */}




            </div>
            </div>
            <div className="col-12  mb-2 py-1">
              <button
                type="submit"
                className="gradient col-md-2 text-decoration-none px-5 border-0 py-2 text-white rounded-3"
              >
                Save
              </button>
            </div>
          </form>
        </TabPanel>

        <TabPanel value="2">
        <h3>About us</h3>

        <form action="" onSubmit={onSubmitHomeaboutPage}>
          <div className="d-sm-flex gap-4">

        
          <div
            className=" rounded-5 mb-3"
            style={{ width: "170px",height:"140px", background: "#F7F6F9" }}
          >
            <label
              className="mt-3 d-flex justify-content-center align-items-center flex-column p-3 "
              htmlFor="restimg"
              style={{ height: "140px" }}
            >
              <p className=" d-flex flex-column justify-content-center align-items-center gap-3">
                <FaPlusCircle /> Upload Image
              </p>
            </label>

            <input
              hidden
              type="file"
              onChange={handleImageChange}
              name="image"
              id="restimg"
            />
          </div>

          {/* Image preview */}
          {aboutimagepreview && (
            <img src={aboutimagepreview} alt="Preview" style={{  height: '200px' }} />
          
          )}
  </div>
          <div className="col-12 py-1">
            <textarea
              name="description"
              id=""
              cols={15}
              placeholder="description"
              rows={3}
              value={aboutdata?.description}
              onChange={handleChangeabout}
              className="form-control"
            />
          </div>
          <div className="row my-2 ">
            <div className="col-6">
              <input
                type="text"
                name="title"
                onChange={handleChangeabout}
                placeholder="Title"
                value={aboutdata?.title}
                className="form-control"
              />
            </div>

            <div className="col-6">
              <input
                type="text"
                onChange={handleChangeabout}
                placeholder="Banner Text"
                name="banner_text"
                value={aboutdata?.banner_text}
                className="form-control"
              />
            </div>
          </div>

          <div className="col-12  mb-2 py-1">
            <button
              type="submit"
              className="gradient col-md-2 text-decoration-none px-5 border-0 py-2 text-white rounded-3"
            >
              Save
            </button>
          </div>
        </form>
      </TabPanel>

        {/* <TabPanel value="3">
          <h3>Contact us</h3>

          <form action="" onSubmit={onSubmitHomecontactPage}>
            <div
              className=" rounded-5 mb-3"
              style={{ width: "170px", background: "#F7F6F9" }}
            >
              <label
                className="mt-3 d-flex justify-content-center align-items-center flex-column p-3 "
                htmlFor="restimg"
                style={{ height: "17vh" }}
              >
                <p className="mt-3">
                  <FaPlusCircle /> Upload Image
                </p>
              </label>

              <input
                hidden
                type="file"
                name=""
                id="restimg"
                onChange={handleImageChangecontact}
              />
            </div>

            <div className="col-12 py-1">
              <textarea
                onChange={handleChangeContacus}
                name="title"
                id=""
                cols={15}
                placeholder="Type"
                rows={3}
                className="form-control"
                defaultValue={""}
              />
            </div>

            <div className="col-12  mb-2 py-1">
              <button
                type="submit"
                className="gradient col-md-2 text-decoration-none px-5 border-0 py-2 text-white rounded-3"
              >
                Save
              </button>
            </div>
          </form>
        </TabPanel> */}
        
      </TabContext>
    </div>
  );
};

export default EditContent;

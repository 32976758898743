import { Box, Button, Container, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";

import { Modal, ModalFooter } from 'react-bootstrap'
import { handleErrors } from "../modules/handleErrors";
import apiClient from "../api/apiClient";
import Rating from '@mui/material/Rating';
import { useState } from "react";

const Ratings = ({show,onHide,id,url, fetchData}) => {
    const [value, setValue] = useState(0);
    const [loader, setloader] = useState(false);
    const [review_text, setreview_text] = useState("");
  const handlefavoritedelete = async () => {
    try {
      setloader(true)
        const response = await apiClient.post(`/${url}/`,{
           "rating": value,
           "restaurant":id,
           "review_text":review_text
        } )
        setloader(false)
        if(!response.ok) return handleErrors(response.data);
         fetchData()
         onHide()

        console.log(response); 
    } catch (error) {
        console.error('Error:', error);
    }
  };
  return (


    <>
    <Modal
           show={show}
           onHide={onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ zIndex: "1300" }}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
               Rating
               
              </Modal.Title>
            </Modal.Header>
           
            <Modal.Body className="flex-column  justify-content-between align-items-center">
            <Rating
        name="simple-controlled"
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        
      />
<div class="form-floating">
  <textarea class="form-control" onChange={(e)=>setreview_text(e.target.value)} placeholder="Leave a comment here" id="floatingTextarea2" style={{height:"100px"}}></textarea>
  <label for="floatingTextarea2">Comments</label>
</div>
            </Modal.Body>
            <ModalFooter className="d-flex gap-2 justify-content-center     ">
                {/* <Button onClick={onHide} color="error" type="submit" variant="contained">NO</Button> */}
                <button disabled={loader} className="btn" type="" onClick={handlefavoritedelete} style={{color:"white", backgroundColor:"#442479"}} variant="contained">Submit</button>

            </ModalFooter>
            
            </Modal>
    </>
  )
}

export default Ratings
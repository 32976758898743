import React, { useState } from 'react';
import apiClient from '../api/apiClient';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ArrowBackIos } from '@mui/icons-material';

const Contactus = () => {
const [spiner,setSpiner]=useState(false)
const [foamdata,setfoamdata]=useState({
name:"",
email:"",
website:"",
description:""

})
const handelchange=(key,value)=>{
  setfoamdata((pre)=>({...pre,[key]:value}))
}

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        setSpiner(true);
        const result =  await apiClient.post("/contactus_content/send_email/",foamdata );
        setSpiner(false);
        if (!result.ok) return toast.error("Error");
        toast.success("Email sent successfully");
     
       
    } catch (error) {
        console.error(error);
        toast.error("Error occurred while adding city");
    }
};
const navigate= useNavigate()
    return (
        <div style={{zoom:"0.9"}}>
            <section className=" ">
            <p onClick={()=>navigate(-1)}>

<ArrowBackIos  className='m-2' sx={{color:"black"}}  />
  </p>
  <div className="mycontainer  mt-2 mb-4 pt-sm-5">
    <div className="row justify-content-center ">
      {/* <div className="col-md-6">
        <div>
          <img
            alt=""
            className="w-100 rounded-4"
            src={require("../assets/contact2.png")}
            // <img src={require("../assets/smalllogo.svg").default} alt="" />

          />
        </div>
      </div> */}
      <div className="col-md-6 mt-md-0">
        <div className="shadow rounded-4 px-4 py-4">
          <h2 className="fs-2 text-uppercase">
            {/* Write to us */}
            Nous sommes là pour vous aider !

          </h2>
          <form onSubmit={handleSubmit}
            action=""
            className="mt-4"
          >
            <input
            required
            onChange={(e)=>handelchange("name",e.target.value)}
            value={foamdata?.name}
              className="py-2 rounded-2 border border-1 w-100 my-2 focus-none px-3"
              id=""
              name=""
              placeholder="Votre nom"
              type="text"
            />
            <input
            required
              onChange={(e)=>handelchange("email",e.target.value)}
              value={foamdata?.email}
              className="py-2 rounded-2 border border-1 w-100 my-2 focus-none px-3"
              id=""
              name=""
              placeholder="Adresse e-mail"
              type="email"
            />
            <input
            required
              className="py-2 rounded-2 border border-1 w-100 my-2 focus-none px-3"
              onChange={(e)=>handelchange("website",e.target.value)}
              value={foamdata?.website}
              id=""
              name=""
              placeholder="Objet"
              type="text"
            />
            <textarea
             onChange={(e)=>handelchange("description",e.target.value)}
             value={foamdata?.description}
              className="py-2 rounded-2 border border-1 w-100 my-2 focus-none px-3"
              id=""
              name=""
              placeholder="Écrivez votre message"
              rows="5"
            />
            <button
             disabled={spiner}
              className="py-3 w-100  px-3 text-white bg-prpl border-0 rounded-3"
              type="submit"
            >
            {  spiner?"Envoyer..." :"Envoyer" }
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
        </div>
    );
}

export default Contactus;

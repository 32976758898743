import { MedicalServices } from "@mui/icons-material";
import { Avatar, Rating, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";

import Modal from "react-bootstrap/Modal";
import { CiEdit, CiLocationOn } from "react-icons/ci";
import { RxCross1 } from "react-icons/rx";
import { Link } from "react-router-dom";
import apiClient from "../api/apiClient";
import { handleErrors } from "../modules/handleErrors";
import { toast } from "react-toastify";
import { MdDone } from "react-icons/md";

function HomeModalNested(props) {
  console.log(
    props.singlebooking?.restaurant_details?.latitude,
    "props.restaurant_details?.latitude"
  );

  const [persons, setpersons] = useState("");
  // const [modify,setmodify]=useState(true)
  const [time, settime] = useState();
  const [date, setdate] = useState();
  const [status, setstatus] = useState();
  const [loader, setloader] = useState();
  console.log(date);
  useEffect(() => {
    setpersons(props?.singlebooking?.persons);
    settime(props?.singlebooking?.time);
    setstatus(props?.singlebooking?.status);
    setdate(props?.singlebooking?.date);
  }, []);

  const handelbooking = async () => {
    // e.preventDefault();

    const result = await apiClient.put(
      `/bookings/${props?.singlebooking?.id}/`,
      { persons: persons, time: time, date: date }
    );

    props.onHide();
    // props.mainpopu()
    if (!result.ok) return;
    toast.success("Booking Edit Successfully");
    props.fetchbooking();
  };
  const handelbookingcancel = async (status) => {
    // e.preventDefault();

    setloader(true);

    const result = await apiClient.put(
      `/bookings/${props?.singlebooking?.id}/`,
      { status: status }
    );
    setloader(false);
    props.onHide();
    // props.mainpopu()
    if (!result.ok) return handleErrors(result.data);
    props.onHide();
    toast.success("Booking cancel Successfully");
    props.fetchbooking();
  };

  console.log(props.singlebooking);

  return (
    <Modal
      style={{ borderRadius: "50px" }}
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        style={{ backgroundColor: "#F0F2F2", color: "black" }}
      >
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body">
          <div className="d-flex align-items-center justify-content-center">
            <h6>Manage my Booking</h6>
          </div>
          <div className="d-flex algn-items-center justify-content-between">
            <div>
              <h6 className="fw-bold">
                {props.singlebooking?.restaurant_details?.name}{" "}
              </h6>
            </div>
            <div>
              <p className="font-12 max bg-prpl py-1 px-2 text-white rounded-pill mb-0">
                {props?.singlebooking?.status_display}
              </p>
            </div>
          </div>
          <div className="d-md-flex  gap-3 my-3 align-items-center">
            <label htmlFor="">Persone</label>{" "}
            <input
              min={1}
              max={10}
              className="form-control"
              type="number"
              placeholder="persons"
              value={persons}
              onChange={(e) => setpersons(e.target.value)}
            />
            <label htmlFor="">Time</label>{" "}
            <input
              className="form-control"
              type="time"
              placeholder="time"
              value={time}
              onChange={(e) => settime(e.target.value)}
            />
            <label htmlFor="">Date</label>{" "}
            <input
              className="form-control"
              type="date"
              placeholder="Date"
              value={date}
              onChange={(e) => setdate(e.target.value)}
            />
          </div>

          <div className="mt-2">
            <button className="w-100 border p-2 rounded-2 fw-bold">
              {" "}
              {props?.singlebooking?.date_display} .{" "}
              {props?.singlebooking?.formatted_date} .{" "}
              {props?.singlebooking?.persons}persons
            </button>
          </div>
          <div className="d-flex align-items-center mt-2">
            <p className="max text-white bg-prpl mb-0">EATS</p>
            <p className="mb-0 ms-2">
              You will earn <span className="border-prpl1">10 EATS</span>
            </p>
          </div>
          <div className="d-flex justify-content-center gap-4 align-items-center  px-3 py-2 border rounded-2 mt-3">
            <div
              className="d-flex flex-column justify-content-center align-items-center pointer"
              onClick={() => {
                handelbooking();
                props.onHide();
              }}
            >
              <MdDone className="fs-4 text-prpl" />
              <p className="text-prpl fw-bold m-0">Done</p>
            </div>
            <div
              className="d-flex flex-column justify-content-center align-items-center pointer"
              onClick={() => {
                if (props?.singlebooking?.status_display === "Complété" || props?.singlebooking?.status_display === "Annulé") {
                  toast.error("Cannot cancel an already cancelled or completed booking!");
                  return;
                }
                handelbookingcancel("CA");
              }}
            >
              <RxCross1 className="fs-4 text-danger" />
              <p className="mb-0 text-danger fw-bold">
                {loader ? "Cancel..." : "Cancel"}
              </p>
            </div>
          </div>

          <div className="mt-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                {/* <CiLocationOn className='text-prpl' /> */}
                {/* <p className="ms-2 mb-0">37, rule de al'ma</p> */}
              </div>
              <div>
                {/* <a href="#" className="text-decoration-none text-prpl fw-bold font-12">Obtenir l’itinéraire</a> */}
              </div>
            </div>
            <div>
              <div className="mycontainer">
                <div>
                  {
                    <iframe
                      src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDQmPq0HH1XTcSNa55xUP748V1Vv4hhK1w&q=${props.singlebooking?.restaurant_details?.latitude},${props.singlebooking?.restaurant_details?.longitude}`}
                      width="100%"
                      height="130"
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                      className="rounded-4"
                    ></iframe>
                  }
                  {/* <iframe
            allowFullScreen
            height="450"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d83998.77824450853!2d2.264633798119612!3d48.85893843534506!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66e1f06e2b70f%3A0x40b82c3688c9460!2sParis%2C%20France!5e0!3m2!1sen!2s!4v1710742609376!5m2!1sen!2s"
            style={{
              border: '0'
            }}
            width="100%"
          /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default HomeModalNested;

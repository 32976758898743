import { Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import Contactus from "./pages/Contactus";
import { Featured } from "./pages/Featured";
import { Home } from "./pages/Home";
// import "./Main.css"
// import "./Main2.css"
import AddCity from "./admin/AddCity";
import { AddResturants } from "./admin/AddResturants";
import AddSocialMedia from "./admin/AddSocialMedia";
import Addmenus from "./admin/Addmenus";
import Cities from "./admin/Cities";
import CuisinesAdd from "./admin/CuisinesAdd";
import EditContent from "./admin/EditContent";
import Faqs from "./admin/Faqs";
import { Resturants } from "./admin/Resturants";
import ShareButton from "./admin/ShareButton";
import SocialMedia from "./admin/SocialMedia";
import { Dashboard } from "./admin/dashboard";
import AdminLogin from "./components/AdminLogin";
import ProtectedRoute from "./components/ProtectedRoute";
import About from "./pages/About";
import { BestRestaurent } from "./pages/BestRestaurent";
import Chose from "./pages/Chose";
import { CityDetail } from "./pages/CityDetail";
import Detail from "./pages/Detail";
import { EatTo } from "./pages/EatTo";
import { Eats } from "./pages/Eats";
import Faq from "./pages/Faq";
import { Favorite } from "./pages/Favorite";
import { Favourites } from "./pages/Favourites";
import FilderRestaurent from "./pages/FilderRestaurent";
import HelpSupport from "./pages/HelpSupport";
import { PersonalInformation } from "./pages/PersonalInformation";
import { Profile } from "./pages/Profile";
import ResetPassword from "./pages/ResetPassword";
import Reviews from "./pages/Reviews";
import Seetings from "./pages/Seetings";
import SendEmail from "./pages/SendEmail";
import { UpcomingBooking } from "./pages/UpcomingBooking";
import { Notifications } from "./restaurent-pages/Notifications";
import ResCuisinesadd from "./restaurent-pages/ResCuisinesadd";
import ResDashbord from "./restaurent-pages/ResDashbord";
import { ReservationManagement } from "./restaurent-pages/ReservationManagement";
import Dashboardnavrestaurent from "./sidebar-restaurent/Dashboard";
import Dashboardnav from "./sidenav-admin/Dashboard";
import UserList from "./sidenav-admin/UserList";
import SelectRestaurant from "./sidebar-restaurent/SelectRestaurant";
import { ResRestaurents } from "./restaurent-pages/Restaurents";
import { CreateNewUser } from "./admin/CreateNewUser";
import { RestaProfile } from "./sidebar-restaurent/RestaProfile";
import GoogleTranslate from "./components/GoogleTranslate";
import { AllBooking } from "./sidenav-admin/AllBooking";
import { AddNewUserresturants } from "./admin/AddNewUserresturants";
import { useState } from "react";
import { AdminReveiw } from "./admin/AdminReveiw";


function App() {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
   <GoogleTranslate/>
   
    <Routes>
      <Route path="/admin/login" element={<AdminLogin />} />
      <Route path="/restaurants" element={<ResRestaurents />} />
      <Route
        element={
          <ProtectedRoute>
          
            <Navbar modalShow={modalShow} setModalShow={setModalShow} />
          </ProtectedRoute>
        }
      >
        <Route path="/" element={<Home />} />
        <Route path="/ShareButton" element={<ShareButton />} />

        <Route path="/featured" element={<Featured />} />
        <Route path="/filter-restaurant" element={<FilderRestaurent />} />
        {/* <Route path='/filter-Nearrestaurant ' element={<Nearrestaurant />}/> */}
        <Route path="/about" element={<About />} />
        <Route path="/send-email" element={<SendEmail />} />
        <Route path="/reset-password/:id/:token" element={<ResetPassword />} />
        <Route path="/select" element={<Chose />} />
        <Route path="/detail" element={<Detail />} />
        <Route path="/contact-us" element={<Contactus />} />
        <Route path="/faqs" element={<Faq />} />
        <Route path="/best-restaurant " element={<BestRestaurent />} />
        {/* <Route path="/city-detail" element={<CityDetail />} /> */}
        <Route path="/eat-to" element={<EatTo />} />
        <Route path="/eats" element={<Eats />} />
        <Route path="/favourites" element={<Favourites />} />
        <Route
          path="/profile/personal-information"
          element={<PersonalInformation />}
        />
        <Route path="/profile" element={<Profile setModalShow={setModalShow} />} />
        <Route path="/upcoming-booking" element={<UpcomingBooking />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/seetings" element={<Seetings />} />
        <Route path="/help-support" element={<HelpSupport />} />
      </Route>

      <Route
        element={
          <ProtectedRoute>
       
            <Dashboardnav />
          </ProtectedRoute>
        }
      >
        <Route path="/admin/dashboard" element={<Dashboard />} />
        <Route path="/admin/AllBooking" element={<AllBooking />} />
        <Route path="/admin/Reviews" element={<AdminReveiw />} />
        <Route path="/admin/resturants" element={<Resturants />} />
        <Route path="/admin/Addmenus" element={<Addmenus />} />
        <Route path="/admin/createnewuser" element={<CreateNewUser />} />

        <Route path="/admin/add-resturants" element={<AddResturants />} />
        <Route path="/admin/addNewUserresturants" element={<AddNewUserresturants />} />
        <Route path="/admin/favorite" element={<Favorite />} />
        <Route path="/admin/cities" element={<Cities />} />
        <Route path="/admin/add-city" element={<AddCity />} />
        <Route path="/admin/edit-content" element={<EditContent />} />
        <Route path="/admin/social-media" element={<SocialMedia />} />
        <Route path="/admin/add-media" element={<AddSocialMedia />} />
        <Route path="/admin/Faqs" element={<Faqs />} />
        <Route path="/admin/cuisinesadd" element={<CuisinesAdd />} />
        <Route path="/admin/users-list" element={<UserList />} />
      </Route>
      <Route
        element={
          <ProtectedRoute>
            <Dashboardnavrestaurent />
          </ProtectedRoute>
        }
      >
        <Route
          path="/restaurant/ReservationManagement"
          element={<ReservationManagement />}
        />
        <Route
          path="/restaurant/choose-restaurant"
          element={<SelectRestaurant />}
        />
        <Route path="/restaurant/notifications" element={<Notifications />} />
      
        <Route path="/restaurant/dashboard" element={<ResDashbord />} />
        <Route path="/restaurant/profile" element={<RestaProfile />} />
        <Route path="/restaurant/addmenus" element={<Addmenus />} />
        <Route path="/restaurant/cuisinesadd" element={<ResCuisinesadd />} />
      </Route>
    </Routes>
    </>
  );
}

export default App;

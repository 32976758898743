import React from 'react';
import { Instagram } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { FacebookIcon, FacebookShareButton, TwitterIcon, WhatsappIcon, XIcon } from 'react-share';
import CopyButton from '../components/CopyButton';

function ShareButton() {
    const { token, userType, isLoggedIn, userid } = useSelector((state) => state.auth);
    const websiteLink = 'https://www.boogeat.com/';

    const shareOnWhatsApp = () => {
        const message = `Check out this website: ${websiteLink}`;
        const encodedMessage = encodeURIComponent(message);
        const whatsappUrl = `whatsapp://send?text=${encodedMessage}?ideats=${userid}`;
        window.open(whatsappUrl, '_blank');
    };

    const shareOnFacebook = () => {
        const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(websiteLink)}?ideats=${userid}`;
        window.open(facebookUrl, '_blank');
    };    

    const shareOnInstagram = () => {
        const instagramUrl = `https://www.instagram.com/share?url=${encodeURIComponent(websiteLink)}`;
        window.open(instagramUrl, '_blank');
    };
  
    const shareOnTwitter = () => {
        const message = `Check out this website: ${websiteLink}`;
        const encodedMessage = encodeURIComponent(message);
        const twitterUrl = `https://twitter.com/intent/tweet?text=${encodedMessage}`;
        window.open(twitterUrl, '_blank');
    };
  
    return (
        <div className='d-flex justify-content-center '>
            <button className='btn' onClick={shareOnFacebook}> <FacebookIcon  size={32} round={true}/> </button>
            <button className='btn' onClick={shareOnInstagram}> < Instagram/> </button>
            <button className='btn' onClick={shareOnWhatsApp}>< WhatsappIcon  size={32} round={true}/></button>
            <button className='btn' onClick={shareOnTwitter}><XIcon size={32} round={true} /></button>
            <CopyButton/>
        </div>
    );
}

export default ShareButton;

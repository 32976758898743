import React, { useEffect, useState } from "react";
import apiClient from "../api/apiClient";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Cancel, Delete, Edit } from "@mui/icons-material";
import { handleErrors } from "../modules/handleErrors";
import { MdDelete } from "react-icons/md";
import { FaPlusCircle } from "react-icons/fa";
import CuisinesEditpopup from "./CuisinesEditpopup";
import { CircularProgress } from "@mui/material";
import CuisinesaddPopup from "../components/CuisinesaddPopup";

const CuisinesAdd = () => {
  const savedRestaurantId =  localStorage.getItem('selectedRestaurantId');
  const [show, setShow] = useState(false);
  const [name, setname] = useState("");
  const [editname, seeditname] = useState("");
  const [previewImageEdite, setpreviewImageEdite] = useState("");
  
  const [editmodal, seteditmodal] = useState(false);

  const [image, setimage] = useState(null);
  const [imagePreview, setImagePreview] = useState(""); // State to store image preview URL
  const [Editid, seteidetid] = useState("");
  const [data, setdata] = useState([]);
  const [loader, setloader] = useState(false);
  const [cusloader, setcusloader] = useState(false);

  const navigate = useNavigate();

  const fetchmenuData = async () => {
    try {
      setcusloader(true);
      const result = await apiClient.get(`/cuisines/?limt=20`);
      setcusloader(false);
      if (!result.ok) return;
      setdata(result.data.results);
    } catch (error) {
      console.error("Error fetching menu data:", error);
    }
  };

  useEffect(() => {
    fetchmenuData();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("name", name);

      if (typeof image == "object") {
        formData.append("image", image);
      }

      setloader(true);
      const response = await apiClient.post("/cuisines/", formData);
      setloader(false);
      if (!response.ok) return handleErrors(response.data);

      fetchmenuData();
      setname("");
      setimage(null);
      seteidetid("");
      setImagePreview(""); // Clear image preview after submission
      toast.success(Editid ? "Updated successfully" : "Added successfully");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEidt = (item) => {
    seteidetid(item.id);
    seeditname(item.name);
    setimage(item.image);

    setpreviewImageEdite(item.image)
    seteditmodal(true);
  };

  const handleDelete = async (id) => {
    try {
      await apiClient.delete(`/cuisines/${id}/`);
      toast.success("Deleted successfully");
      fetchmenuData();
    } catch (error) {
      console.error("Error deleting cuisine:", error);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setimage(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result); // Set image preview
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>

      <div className="row justify-content-between">
        <div className="d-flex gap-3">
        
          <div className="">

            
            {/* <input
              type="text"
              value={name}
              onChange={(e) => setname(e.target.value)}
              placeholder="Name"
              className="form-control"
            />
            <div
              className=" rounded-5 mb-3"
              style={{ width: "170px", height: "140px", background: "#F7F6F9" }}
            >
              <label
                className="mt-3 d-flex justify-content-center align-items-center flex-column p-3 "
                htmlFor="restimg"
                style={{ height: "136px", backgroundColor: "" }}
              >
                <p className=" d-flex flex-column justify-content-center align-items-center gap-3">
                  <FaPlusCircle /> Upload Image
                </p>
              </label>
              <input
                type="file"
                hidden
                id="restimg"
                name="q"
                onChange={handleImageChange}
              />
              
            </div> */}
          

            <button
              className="btn"
              style={{ backgroundColor: "#492c79", color: "white" }}
              // onClick={onSubmit}
              onClick={()=>{seteditmodal(true);  seteidetid("");}}
            >
              {!loader ? "Add" : "Add..."}
            </button>
          </div>
          {/* <img height={"200px"} src={imagePreview} alt="" /> */}
        </div>
        {!cusloader ? (
          <div className="row">
            {data.map((item, index) => (
              <div key={index} className="col-md-3 rounded-4 card-slider">
                <span className="rounded-2">
                  <div
                    className="bg-img rounded-3 position-relative"
                    style={{
                      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${item.image})`,
                    }}
                  >
                    <div
                      className="position-absolute text-white"
                      style={{ bottom: "10px", left: "10px" }}
                    >
                      {item.name}
                    </div>
                    <div
                      className="position-absolute text-white"
                      style={{ top: "10px", right: "10px" }}
                    >
                      <MdDelete
                        onClick={() => handleDelete(item.id)}
                        style={{ fontSize: "20px" }}
                      />
                    </div>
                    <div
                      className="position-absolute text-white"
                      style={{ top: "30px", right: "10px" }}
                    >
                      {/* {Editid === item.id ? (
                                            <p onClick={() => {
                                                seteidetid(null);
                                                setname('');
                                                setimage('');
                                                setImagePreview('');
                                            }}>
                                                <Cancel sx={{ color: 'white' }} />
                                            </p>
                                        ) : ( */}
                      <p onClick={() => handleEidt(item)}>
                        <Edit sx={{ color: "white" }} />
                      </p>
                      {/* )} */}
                    </div>
                  </div>
                </span>
              </div>
            ))}
          </div>
        ) : (
          <div className=" mx-auto text-center d-flex justify-content-center mt-5">
            <CircularProgress color="secondary" />
          </div>
        )}
      </div>
      {editmodal && (
        <CuisinesEditpopup
          setname={setname}
          fetchData={fetchmenuData}
          show={editmodal}
          onHide={() => seteditmodal(false)}
          name={editname}
          imagePreview={previewImageEdite}
          id={Editid}
          image={image}
        />
      )}
    </div>
  );
};

export default CuisinesAdd;

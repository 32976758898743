import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { NavLink, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
// import { logout } from '../../redux/counterSlice'
import { buttonStyle, listItemStyle } from "./SideNavStyles";
import { logout } from "../redux/authSlice";
import { TfiWorld } from "react-icons/tfi";
import { HiOutlineUsers } from "react-icons/hi";

// import { useDispatch } from "react-redux";

const drawerWidth = 210;
const listItemData = [
  {
    label: "Dashboard",
    link: `/admin/dashboard`,
    icon: <HiOutlineUsers style={{ fontSize: "20px" }} />,
  },
  {
    label: "Restaurants",
    link: "/admin/resturants",
    icon: <TfiWorld style={{ fontSize: "20px" }} />,
  },
  {
    label: "Edit Content",
    link: "/admin/edit-content",
    icon: <TfiWorld style={{ fontSize: "20px" }} />,
  },
  {
    label: "Cities",
    link: "/admin/cities",
    icon: <TfiWorld style={{ fontSize: "20px" }} />,
  },
  {
    label: "Social Media",
    link: "/admin/social-media",
    icon: <TfiWorld style={{ fontSize: "20px" }} />,
  },
  {
    label: "Faqs",
    link: "/admin/faqs",
    icon: <TfiWorld style={{ fontSize: "20px" }} />,
  },
  {
    label: "Cuisines",
    link: "/admin/cuisinesadd",
    icon: <TfiWorld style={{ fontSize: "20px" }} />,
  },
  {
    label: "Users",
    link: "/admin/users-list",
    icon: <TfiWorld style={{ fontSize: "20px" }} />,
  },
  {
    label: "Bookings",
    link: "/admin/AllBooking",
    icon: <TfiWorld style={{ fontSize: "20px" }} />,
  },
  {
    label: "Reviews",
    link: "/admin/Reviews",
    icon: <TfiWorld style={{ fontSize: "20px" }} />,
  },
];

function SideNav(props) {
  // const [show, setShow] = useState(false)
  const { window } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const drawer = (
    <div className="side-nav-outer-rounded  ">
      <div className="" style={{ height: "100%", borderRadius: "20px" }}>
        <div className="d-flex flex-column justify-content-between" style={{height:"100vh"}} >
          {/* <div className="py-2" style={{backgroundColor:"#F4F9F5",borderTopLeftRadius:"20px"}}> */}

          <div
            className="p-3 mx-auto d-flex justify-content-center "
            style={{ width: "100%" }}
          >
            <div className="" style={{ width: "6rem" }}>
              <img width={"100%"} src={require("../assets/logo.png")} alt="" />
            </div>
            {/* <img width={"100%"} className="" src='' alt="Logo here" /> */}
          </div>

          {/* </div> */}

          {/* <h4 className="p-2 mb-4 text-black text-center fw-bold">Business</h4> */}
          <List className="ms-5">
            {listItemData.map((value, i) => (
              <div key={i}>
                <div className="rounded-start">
                  <RenderItem
                    value={value}
                    i={i}
                    style={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: "10px",
                      color: "black",
                    }}
                  />
                </div>
              </div>
            ))}
          </List>

          <List
            className=" rounded-start-4 mb-3  gradient "
            style={{
              bottom: "0px",
              width: "calc(100% - 40px)",
              marginLeft: "auto",
              marginTop: "auto",
              

            }}
          >
            <ListItem
              disablePadding
              className=" list_text "
              onClick={() => {
                dispatch(logout());
                navigate("/");
              }}
              // onClick={() => setShow(true)}
              style={{ cursor: "pointer" }}
            >
              <ListItemText
                className="d-flex  mt-auto gap-3 px-3 "
                primary={
                  <Typography
                    variant="body2"
                    style={{ fontSize: 14, color: "white" }}
                    title="logout"
                  >
                    <LogoutIcon />
                    Logout
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </div>
        {/* {show &&
          <MuiDialog 
            // title={"Logout"}
            title={"Are you sure you want to Logout?"} 
            show={show}
            // onHide={show}
            Buttons={
              ()=>(<>
              <Button
              onClick={() => { setShow(false); }}
              >Cancel
              </Button>
                <Button  
                onClick={() => {
               dispatch(logout())
               navigate('/')
                 }}
                 color="error" variant="contained">
                  Logout
                </Button>
              </>
              )
            }
            />
          } */}
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      className=""
      component="nav"
      sx={{
        width: { md: drawerWidth },
        flexShrink: { md: 0 },
        bgcolor: "black",
      }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={container}
        variant="temporary"
        open={props.mobileOpen}
        onClose={props.handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            bgcolor: "#FFFFFF",
            color: "#AFB7BE",
            zIndex: { md: 1000, xs: 1200 },
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", md: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            bgcolor: "black",
            border: 0,
            color: "#AFB7BE",
            width: drawerWidth,
            zIndex: { md: 1100, xs: 1200 },
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}

SideNav.propTypes = {
  window: PropTypes.func,
};

export default SideNav;

const RenderItem = ({ value, i }) => {
  return value.link ? (
    <ListItem
      key={i}
      component={NavLink}
      to={value?.link || ""}
      disablePadding
      sx={listItemStyle}
      className="rounded-start-4"
    >
      <ListItemButton
        className="list-item list_text "
        // sx={{ ...buttonStyle }}
        sx={{ ...buttonStyle, "&:active": { color: "white" } }}
      >
        {/* <ListItemIcon className="myIconClass" sx={{ color: "white", marginRight: -3 }}> {value.icon}</ListItemIcon> */}
        <ListItemText
          className=""
          primary={
            <Typography
              variant="body2"
              className=""
              style={{ fontSize: 14, paddingLeft: 0, whiteSpace: "nowrap" }}
              title={value.label}
            >
              {value.label}
            </Typography>
          }
        />
      </ListItemButton>
    </ListItem>
  ) : (
    <ListItemButton
      className="list-item list_text no_link_list_item "
      sx={{ ...buttonStyle, "&:hover": { backgroundColor: "#04BCFA" } }}
    >
      {/* <ListItemIcon className="myIconClass" sx={{ color: "white", }}>{value.icon}</ListItemIcon> */}
      <ListItemText
        className=""
        primary={
          <Typography
            variant="body2"
            style={{ fontSize: 14, color: "red", fontWeight: "bold" }}
            title={value.label}
          >
            {value.label}
          </Typography>
        }
      />
    </ListItemButton>
  );
};

import { toast } from "react-toastify"
function handleErrors(errors, field = "") {
  
  if (errors?.message) return toast.error(errors.message)
  if (Array.isArray(errors)) {
    const joinedMessage = errors.join(" ")
    if (field !== "non_field_errors") toast.error(`${field}: ${joinedMessage}`)
    else toast.error(joinedMessage)
  } else if (typeof errors === "object" && errors !== null) {
    Object.entries(errors).forEach(([key, value]) => {
      const newField = field ? `${field}.${key}` : key
      handleErrors(value, newField)
    })
  } else if (typeof errors === "string") {
    toast.error(errors.slice(0, 150))
  }
}
export { handleErrors }

import React, { useEffect, useState } from 'react'
import Slider from 'react-slick';
import { CardContent, IconButton, Rating, Typography } from '@mui/material';
import itemImage from '../assets/city1.jpeg';
import itemImage2 from '../assets/city2.jpeg';
import itemImage3 from '../assets/city3.jpeg';
import itemImage4 from '../assets/city4.jpeg';
import itemImage5 from '../assets/city5.jpeg';
import {useNavigate} from 'react-router-dom'
import { GrNext, GrPrevious } from 'react-icons/gr';
import { FaRegHeart } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import apiClient from '../api/apiClient';
export const Slider3 = ({heading}) => {

const[citydata,setcitydata]=useState([])

  
    // const fetchcityData  = async()=>{
    //   const result = await apiClient.get('/city/')
    //   if (result.ok) setcitydata(result.data.results)
    //   console.log(result, "city detail")
    // }
  

    // useEffect(()=>{
    
    //   fetchcityData()

    // },[])
  
    const sliderRef = React.useRef(null); // Reference to the Slider component
 

    const fetchData  = async()=>{
      const result = await apiClient.get('/city/')
      if (result.ok) setcitydata(result.data.results)
      // console.log(result, "city detail")
    }
  
    useEffect(() => {
      fetchData()
    }, []);

    // Custom arrow components
    const CustomPrevArrow = (props) => (
      <IconButton {...props} className="prev-arrow" onClick={prevSlide}>
        <GrPrevious />
      </IconButton>
    );
  
    const CustomNextArrow = (props) => (
      <IconButton {...props} className="next-arrow" onClick={nextSlide}>
        <GrNext />
      </IconButton>
    );
  
    // Function to navigate to the previous slide
    const prevSlide = () => {
      if (sliderRef.current) {
        sliderRef.current.slickPrev();
      }
    };
  
    // Function to navigate to the next slide
    const nextSlide = () => {
      if (sliderRef.current) {
        sliderRef.current.slickNext();
      }
    };
    const settings = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };
    const navgater=useNavigate()

    const hanldeserach=(city)=>{

  
      navgater(`/filter-restaurant?city=${city}`)
    }
  return (
    <>
    
    <div className="custom-arrows d-flex justify-content-between ">
          <span>
            <h3 className='text-prpl2'>
              {heading}
            </h3>
          </span>
          {/* <span>
            <span className='d-flex justify-content-end'>
              <span className='rounded-2' style={{ border: "1px solid rgb(106,74,159)" }}><CustomPrevArrow className="prev-arrow" /></span>
              <span className='rounded-2 mx-1' style={{ border: "1px solid rgb(106,74,159)" }}><CustomNextArrow className="next-arrow" /></span>
            </span>
          </span> */}
        </div>
        <div className="d-flex justify-content-between ">     <IconButton onClick={prevSlide}>
    <GrPrevious />


  </IconButton>
  <IconButton onClick={nextSlide}>
    <GrNext />
  </IconButton>
  </div>
        <div className="slider-wrapper ">
          <Slider ref={sliderRef} {...settings}>
            {citydata.map((item, index) => (
              <div key={index} className='rounded-4 mt-4'>
                {/* <span className='p-3'> */}
                  <span className='rounded-2'>
                    <span className=''>
                    <div className='bg-img rounded-3 position-relative' style={{backgroundImage:`linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${item.image})`}}  onClick={()=> hanldeserach(item.name)}>

                    <div className='position-absolute text-white' style={{bottom:"10px", left:"10px", }}>
                        {item.name}
                    </div>
                    </div>
                    {/* </span> */}
                  </span>
                </span>
                
              </div>
            ))}
          </Slider>
        </div>
    
    </>
  )
}

import { Rating } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BsPencilFill } from "react-icons/bs";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ArrowBackIos } from "@mui/icons-material";
import apiClient from "../api/apiClient";

const Reviews = () => {
  const navigater = useNavigate();

  const [review, setreviw] = useState([]);
  const fetchreviewData = async () => {
    const result = await apiClient.get(`/reviews/`);
    if (!result.ok) return;

    setreviw(result?.data.results);

    // console.log(result, "city detail")
  };

  useEffect(() => {
    fetchreviewData();
  }, []);
  return (
    <section className="  ">
      <p onClick={() => navigater(-1)}>
        <ArrowBackIos sx={{ color: "black", backgroundColor: "" }} />
      </p>
      <div className="mycontainer py-sm-5 mt-sm-0 mt-2 pt-2">
        <h3 className="text-center">Vos avis </h3>
        <form action="">
          <div className="row">
            <div className="col-lg-8 col-md-10 mx-auto p-3">
              <div className="row mt-3 mx-auto">
                {review.map((e) => (
                  <div className="mb-3">
                    <div className=" gap-3  card my-card">
                      <div className="d-flex gap-3 align-items-center m-3">

                    
                      <img src={e?.restaurant_image} style={{height:"80px",width:"80px"}} alt="" />
                      <p className="mb-0">{e?.restaurant_name}</p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">

                    <Rating
                      name="size-large"
                      readOnly
                      defaultValue={e?.rating}
                      size="large"
                    />
                    <p></p>
                    </div>
                  </div>
                ))}

                {/* <div className='mb-3'>
                    <div className='d-flex gap-3 align-items-center'>
                        <Avatar/>
                    <p className='mb-0'>
                    Superb quality. Will definitely buy again.
                    </p>
                    </div>
                        <Rating name="size-large" defaultValue={5} size="large" />

                    </div> */}

                {/* <div className='mb-3'>
                    <div className='d-flex gap-3 align-items-center'>
                        <Avatar/>
                    <p className='mb-0'>
                    Mediocre performance. Needs improvement.
                    </p>
                    </div>
                        <Rating name="size-large" defaultValue={3} size="large" />

                    </div> */}
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Reviews;

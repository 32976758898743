import React from 'react'
import { ImFire } from "react-icons/im";
import { CiShare2 } from "react-icons/ci";
export const EatTo = () => {
  return (
    <>
    <section className=" first-sec ">
    <div className="mycontainer py-sm-5 mt-3">
      <div className="row mt-sm-0 mt-5 pt-sm-0 pt-5">
        <div className="col-lg-6 col-sm-7 mx-auto">
          <div className="bg-prpl3 p-3 rounded-3">
            <h4 className="mb-0 text-white text-center">
              Share your code with a friend and earn 1000 Eats as a reward{" "}
              <ImFire className='fs-3' style={{color:"rgb(255,193,7)"}}/>
            </h4>
          </div>
          <div className="mt-3">
            <p className="mb-0">Your Code</p>
            <div className="d-flex align-items-center border rounded-3 px-3">
              <input
                type="text"
                className="form-control shadow-none border-0"
              />
              <label htmlFor="" className="text-prpl">
                Copy
              </label>
            </div>
          </div>
          <div>
            <p className="text-center mt-3">
              Your friend will earn 2000 Yums as a reward when they use your
              code for their first booking.
            </p>
          </div>
          <div>
            <button className="bg-prpl3 border-0 w-100 py-2 rounded-2 text-white">
            <CiShare2 className='fs-4 mx-2'/>
              Share code
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
    
    
    </>
  )
}

import React, { useEffect, useState } from 'react';
import apiClient from '../api/apiClient';
import { getCurrentURLParameters } from '../modules/helpers';
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate } from 'react-router-dom';

const AddCity = () => {
const location =useLocation()
const image=location?.state?.state
console.log(image,"image");

useEffect(()=>{
    setAddCity((pre)=>({...pre,previewImage:image}))
},[])


    const [spiner, setSpiner] = useState(false);
    const { id, name } = getCurrentURLParameters();
    const [addCity, setAddCity] = useState({
        name: name,
        image: "",
        previewImage: "" // New state for preview image
    });

    const navigate = useNavigate();

    const handleChange = (key, value) => {
        setAddCity({ ...addCity, [key]: value });
      
    };

    const handleImageChange = e => {
        const imageFile = e.target.files[0];
        const previewImageUrl = URL.createObjectURL(imageFile);
        setAddCity({
            ...addCity,
            image: imageFile,
            previewImage: previewImageUrl
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const uploadData = new FormData();
        uploadData.append("name", addCity.name);
if( typeof addCity.image =="object"){

    uploadData.append("image", addCity.image);
}

        try {
            setSpiner(true);
            const result = id ? await apiClient.put(`/city/${id}/`, uploadData) : await apiClient.post("/city/", uploadData);
            setSpiner(false);
            if (!result.ok) return toast.error("Error");
            toast.success("City Added");
            navigate("/admin/cities");
           
        } catch (error) {
            console.error(error);
            toast.error("Error occurred while adding city");
        }
    };

    return (
        <div className="">
            <div className="right-mid mx-1 mx-sm-3  p-2">
                <div className=" row d-flex justify-content-between align-items-center">
                    <div className="col-12 col-sm-7 p-0 my-3">
                        <h4 className="mb-0 mx-2">{ id?"Edit":"Add New"} City</h4>
                    </div>
                </div>
            </div>

            <div className="right-bottom mx-1 mx-sm-3 ">
                <div className="bottom-inner">
                    <div className="bottom-inner">
                        <form onSubmit={handleSubmit}>
                            <div className="d-flex gap-4">
                            <div className='rounded-5 mb-3' style={{ width: "170px", background: "#F7F6F9" }}>
                                <label className='mt-3 d-flex justify-content-center align-items-center flex-column p-3' htmlFor="restimg" style={{ height: "20vh" }}>
                                    <AddIcon className='text-dark' sx={{ color: "black" }} />
                                    <p className='mt-3'>Upload Image</p>
                                </label>
                                <input hidden type="file" onChange={handleImageChange} name="image" id="restimg" />
                            </div>

                            {addCity.previewImage && (
                                <img src={addCity.previewImage} alt="Preview" style={{  height: '200px' }} />
                            )}</div>

                            <div className="row ">
                                <div className="col-12 col-sm-6 py-1">
                                    <input
                                        type="text"
                                        placeholder="Name"
                                        className="form-control"
                                        value={addCity.name}
                                        onChange={(e) => handleChange("name", e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="col-12 mb-2 py-1">
                                <button
                                    type="submit"
                                    className="gradient col-md-2 text-decoration-none px-5 border-0 py-2 text-white rounded-3">
                                    {spiner ? "Save..." : "Save"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddCity;

import React from "react";
import { Box, Typography } from "@mui/material";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const buttonStyles = (isActive) => ({
    aspectRatio: "1",
    width: "35px",
    borderRadius: "8px",
    border: "2px solid #EFF0F4",
    background: isActive
      ? "linear-gradient(360deg, rgba(68, 36, 121,1) 30%, rgba(121, 92, 169, 0.88) 100%)"
      : "transparent",
    color: isActive ? "white" : "#505470",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  });

  const arrowButtonStyles = (isDisabled) => ({
    ...buttonStyles(false),
    color: isDisabled ? "#C4C4C4" : "#353849",
    cursor: isDisabled ? "not-allowed" : "pointer",
  });

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        marginTop: "24px",
        gap: "8px",
      }}
    >
      <Typography sx={{ color: "#232638", fontSize: "14px" }}>
        {currentPage} - {totalPages} of items
      </Typography>
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        style={arrowButtonStyles(currentPage === 1)}
      >
        &lt;
      </button>
      {Array.from({ length: totalPages }, (_, index) => (
        <button
          key={index + 1}
          onClick={() => handlePageChange(index + 1)}
          style={buttonStyles(index + 1 === currentPage)}
        >
          {index + 1}
        </button>
      ))}
      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        style={arrowButtonStyles(currentPage === totalPages)}
      >
        &gt;
      </button>
    </Box>
  );
};

export default Pagination;

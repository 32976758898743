import React, { useEffect, useState } from 'react'
import { FaQuoteLeft } from "react-icons/fa";
import apiClient from '../api/apiClient';
import { CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
const About = () => {
    const carddata=[{},{},{}]
 
    const [aboutdata,setaboutdata]=useState([])
    const [spiner,setspiner]=useState(true)



    const fetchcityData  = async()=>{
        setspiner(true)
        const result = await apiClient.get('/aboutus_content/')
        setspiner(false)
        if (result.ok) setaboutdata(...result.data.results)
        console.log(result, "city detail")
      }
    
  
      useEffect(()=>{
      
        fetchcityData()
  
      },[])
  return (
    <div>
     { spiner? <div className="d-flex justify-content-center mt-4">
     <div className="d-flex justify-content-center mt-4">
     <CircularProgress color="secondary" />
     </div>
     </div>
     :  <div className="">

      
         <section class=" ">
        <div class="mycontainer py-md-5">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="row py-3  align-items-center">
                        <div class="col-md-6 col-lg-5 ">
                            <div class="d-flex justify-content-center">
                                <img src={aboutdata?.image} alt="usjdh" class="imgabt object-cover"/>
                            </div>
                        </div>
                        <div class="col-md-6  col-lg-7 mt-4 mt-md-0">
                            <div class="mx-4">
                                <h2 class="fs-1 fw-normal text-black fw-bold d-none d-md-block">
                                { aboutdata?.title}       
                                </h2>
                                <p class="text-secondary fs-5  text-center text-md-start">
                               { aboutdata?.description}                             </p>
                                <div class="mt-3 d-flex justify-content-center ">
                                    <Link to={"/select"} class="text-white text-decoration-none px-4 d-inline-block rounded-4 py-3 bg-prpl"> Nous contacter</Link>
                                    

                                     
                                
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
     {/* Notre mission est de rassembler, défendre la restauration et nourrir non seulement nos corps, mais aussi nos esprits ! */}
    {/* <section class="aboutmid">
        <div class="mycontainer py-3 py-sm-5">
            <div class="d-flex justify-content-center text-center  align-items-center">
                <h2 class="text-white mb-0 fs-1">
               

                {aboutdata?.banner_text}
                </h2>
            </div>
        </div>
    </section> */}
    </div>}
    </div>
  )
}

export default About
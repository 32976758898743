import { Box, CssBaseline } from '@mui/material';
import React from 'react';
import { Outlet } from "react-router-dom";
import Header from './Header';
import './layout.css';




const Dashboardnav = ({type}) => {
 
return (
  <Box className="" sx={{
    backgroundColor: 'white',
    // padding: '20px 0px 0px 20px', 
    minHeight: '100vh', 
  }} >
  <Box className="" sx={{ display: "flex",backgroundColor:"white",height:"100vh" }}>
  <CssBaseline />
  <Header />
  <Box className='px-3 px-md-3  pb-2  scroll' style={{backgroundColor:"white",borderRadius:"20px", height:"calc(100vh - 8px)", scrollbarWidth:"none"}}
    component="main"
    sx={{
      flexGrow: 1,
    
      width: { md: `calc(100% - ${240}px)`, overflow: "auto" },
      paddingTop: '70px'
    }}
  >
    {/* <Toolbar /> */}




      <Outlet context={{type}} />


      {/* <Footer/> */}
      </Box>
      </Box>
      </Box>
)
};

export default Dashboardnav;

import React, { useEffect, useState } from "react";
import apiClient from "../api/apiClient";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";
import { ArrowBackIos } from "@mui/icons-material";
export const Eats = () => {
  const [eats, seteats] = useState({ discount: 0, total_eats: 0 });

  const fetchmenuData = async () => {
    const result = await apiClient.get(`/eat/total_eats/`);
    if (!result.ok) return;

    seteats(result?.data);
    // console.log(result, "city detail")
  };

  useEffect(() => {
    fetchmenuData();
  }, []);

  console.log(eats, "'eats");

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
  }));

  const navigater = useNavigate();
  return (
    <>
      <section className="  100vh">
        <p onClick={() => navigater(-1)}>
          <ArrowBackIos className="m-2" sx={{ color: "black" }} />
        </p>
        <div className="mycontainer py-sm-5 mt-sm-4 ">
          <div className="row mt-sm-0 mt-sm-2 pt-sm-0 pt-sm-2">
            <div className="col-12">
              <div className="bg-prpl3 p-3 rounded-3">
                <h4 className="mb-0 text-white">Your Current EATS amount</h4>
                <p className="fs-1 mb-0 text-white">{eats?.total_eats}</p>
              </div>
              <div className="mt-3">
                <h5>Explore your EATS Benefits</h5>
                <div className="bg-white border shadow p-3">
                  <div>
                    <div className="d-flex align-items-center gap-2">
                      <div>
                        <img src={require("../assets/abt2.png")} alt="" />
                      </div>
                      <div>
                        <h6 className="mb-0">Delicious discounts</h6>
                      </div>
                    </div>
                    <p>
                      Earn <span>10 EATS</span> per reservation
                    </p>
                    <div>
                      <div className="d-flex justify-content-between alig">
                        <p className="mb-0">0DH</p>
                        <p className="mb-0">80DH</p>
                        <p className="mb-0">150DH</p>
                      </div>
                      <div>
                        <BorderLinearProgress
                          variant="determinate"
                          value={
                            eats?.total_eats > 200
                              ? 100
                              : (eats?.total_eats / 200) * 100
                          }
                        />
                      </div>
                      <div className="d-flex justify-content-between alig">
                        <p className="mb-0">EATS</p>
                        <p className="mb-0">100</p>
                        <p className="mb-0">&nbsp;&nbsp;&nbsp;200</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

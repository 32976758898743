import React from 'react'
import Slider from 'react-slick';
import { CardContent, IconButton, Rating, Typography } from '@mui/material';
import itemImage from '../assets/item1.png';
import itemImage2 from '../assets/item2.png';
import itemImage3 from '../assets/item3.png';
import itemImage4 from '../assets/item4.png';
import { GrNext, GrPrevious } from 'react-icons/gr';
import { FaRegHeart } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
export const  BestrestaurentSlider= ({heading}) => {
    const sliderRef = React.useRef(null); // Reference to the Slider component
    const data = [
      {
        img: itemImage,
        name: "Marocain",
      },
      {
        img: itemImage2,
        name: "Italien",
      },
      {
        img: itemImage3,
        name: "Libanais (moyen orient)",
      },
      {
        img: itemImage4,
        name: "Jap / Chinois (Asiatique)",
      },
      {
        img: itemImage,
        name: "Brasserie",
      },
    ];
    // Custom arrow components
    const CustomPrevArrow = (props) => (
      <IconButton {...props} className="prev-arrow" onClick={prevSlide}>
        <GrPrevious />
      </IconButton>
    );
  
    const CustomNextArrow = (props) => (
      <IconButton {...props} className="next-arrow" onClick={nextSlide}>
        <GrNext />
      </IconButton>
    );
  
    // Function to navigate to the previous slide
    const prevSlide = () => {
      if (sliderRef.current) {
        sliderRef.current.slickPrev();
      }
    };
  
    // Function to navigate to the next slide
    const nextSlide = () => {
      if (sliderRef.current) {
        sliderRef.current.slickNext();
      }
    };
    const settings = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 300,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        // {
        //   breakpoint: 1200,
        //   settings: {
        //     slidesToShow: 2
        //   }
        // },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };
  return (
    <>
    
    <div className="custom-arrows d-flex justify-content-between">
          <span>
            <h3 className='text-prpl2'>
              {heading}
            </h3>
          </span>
          <span>
            <span className='d-flex justify-content-end'>
              <span className='rounded-2' style={{ border: "1px solid rgb(106,74,159)" }}><CustomPrevArrow className="prev-arrow" /></span>
              <span className='rounded-2 mx-1' style={{ border: "1px solid rgb(106,74,159)" }}><CustomNextArrow className="next-arrow" /></span>
            </span>
          </span>
        </div>
        <div className="slider-wrapper">
          <Slider ref={sliderRef} {...settings}>
            {data.map((item, index) => (
              <div key={index} className='rounded-4 card-slider'>
                <span className='p-3'>
                  <span className='rounded-4 p-3'>
                    <img
                      style={{  width:"calc(100% - 30px)" }}
                      className=' mx-auto rounded-4'
                      src={require('../assets/ist.jpeg')}
                      alt="Paella dish"
                    />
                  </span>
                </span>
                <div className='px-3 pb-5'>
                <div class="card-body">
                                                    <p class="mb-0">French</p>
                                                    <div class="d-flex align-items-center justify-content-between">
                                                        <h5>Terry's cafe</h5>
                                                        <h5>8.8</h5>
                                                    </div>
                                                    <div>
                                                        <p class="bg-prpl3 p-1 max text-white rounded-2 mb-0"><i class="bi bi-aspect-ratio-fill me-2"></i>Pay</p>
                                                    </div>
                                                    <p class="mb-0">70501 Paris</p>
                                                    <p class="mb-0">$ 75 average per price</p>
                                                    <div>
                                                        <p class="bg-prpl3 p-1 max text-white rounded-2 mb-0">-50%</p>
                                                    </div>
                                                </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
    
    </>
  )
}
import { Box } from '@mui/material';
import React, { useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import { Close } from '@mui/icons-material';
import apiClient from '../api/apiClient';
import { toast } from 'react-toastify';
import { handleErrors } from '../modules/handleErrors';

export default function SendEmail() {
const [loader,setloader]=useState(false)
const [email,setEmail]=useState("")
const navigate= useNavigate()

    const onSubmit = async (e) => {
        console.log("submit");
        try {
            e.preventDefault();
       
            setloader(true)
            const response = await apiClient.post('/send-reset/', {
            "email":email
          });
            setloader(false)
            if(!response.ok)return handleErrors(response.data)
              navigate("/")
           
            if (response.ok)  toast.success("send mail successfully")
            // navigate("/admin/resturants")
            console.log(response); // Log the response from the server
        } catch (error) {
            console.error('Error:', error);
        }
    };

  return (

    <>
   <div className='d-flex justify-content-center align-items-center' style={{height:"80vh"}}>

    <Box className="px-4 my-5 sacnshadow" sx={{width:{sm:"400px",xs:"90%"},backgroundColor:"",color:"",borderRadius:"19px"}}>
      
        <form onSubmit={onSubmit} className="my-4 mx-3">

    <div className='text-center mb-4  ' > 
      <MailOutlinedIcon className='p-2 ' style={{color:"#2C9BF6",borderRadius:"29px", backgroundColor:"#151C29",fontSize:"49px",borderTop:"2px solid #1F649F",borderRight:"1px solid #1F649F",borderLeft:"1px solid #1F649F"}}/>  </div>
  
    
    <h6 className='text-center'> Password recovery</h6>
    <p className='text-center' style={{fontSize:"9px",opacity:"0.7"}}>A password renewel link will be sent to the email <br /> you specified.</p>
    <div className="row">
    <div className=" mb-3 p-0 ">
<label style={{color:"#A7AEBF"}} for="exampleInputEmail1" class="form-label m-0">Email</label>
<input  onChange={(e)=>setEmail(e.target.value)} style={{backgroundColor:"",color:"black"}} value={email} type="email" placeholder='member@gmail.com' class="form-control  " id="exampleInputEmail1" aria-describedby="emailHelp"/>

</div>

</div>

<div className="row ">


  <button  disabled={loader} type="submit" class="btn text-light  mt-5 text-center px-5 mb-2  mx-auto" style={{backgroundColor:"#442479"}} >{loader?"Submit...":"Submit"}</button>

  
  
</div>
        </form>
  

    </Box>


    </div>
    
    </>



  
  );
}

import { createContext, useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import apiClient from '../api/apiClient';

const LocationContext = createContext();

export const Indexpagedata = ({ children }) => {
  const [frofiledata, setfrofiledata] = useState({
    last_name:"",
    first_name:"",
    email: "",
    name: "",
    image: "",
    dob: "",
    phone:""
  });
  const [indexData, setIndexData] = useState([]);
  const [profiledata, setprofiledata] = useState([]);
  const [Loading, setLoading] = useState(false);
  const { token, userType, isLoggedIn, userid } = useSelector(state => state.auth);
  const fetchDataprofile = async (id) => {
    setprofiledata({})
if( !userid && !id) return
    const result =  id? await apiClient.get(`/register/${id}/`):await apiClient.get(`/register/${userid}/`)
    if (result.ok) {

      console.log(result.data.results,"qpqpqpqppqp");
      setprofiledata(result?.data)
      
    }
  };
  const fetchData = async () => {
    try {
      setLoading(true)
      const result = await apiClient.get(`/index_content/`);
      setLoading(true)
      if (result.ok) {
        setIndexData(...result?.data?.results);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchData();
    fetchDataprofile()
  }, []);
  return (
    <LocationContext.Provider value={{ indexData, fetchData,profiledata,fetchDataprofile,frofiledata,setfrofiledata }}>
      {children}
    </LocationContext.Provider>
  );
};
export const useIndexPageData = () => {
  return useContext(LocationContext);
};

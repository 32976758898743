import { Button, styled } from "@mui/material";

import { Modal, ModalFooter } from "react-bootstrap";
import { handleErrors } from "../modules/handleErrors";
import apiClient from "../api/apiClient";
import { useEffect, useState } from "react";
import { FaPlusCircle } from "react-icons/fa";

const CuisinesEditpopup = ({
  show,
  onHide,
  id,
  url,
  fetchData,
  imagePreview,
  Editid,
  image,
  name,
  setname,
}) => {
  const [editname, seteditname] = useState("");
  const [editimage, seteditimage] = useState("");
  const [EDperveiwimg, setEDperveiwimg] = useState("");
  const handlefavoritedelete = async () => {
    try {
      const formData = new FormData();
      formData.append("name", editname);
      console.log(typeof image == "object");

      if (typeof editimage == "object") {
        formData.append("image", editimage);
      }
      let response;
      if (id) {
        response = await apiClient.put(`/cuisines/${id}/`, formData);
      } else {
        response = await apiClient.post(`/cuisines/`, formData);
      }
      if (!response.ok) return handleErrors(response.data);
      setname("");
      fetchData();

      onHide();

    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    seteditname(name);
    setEDperveiwimg(imagePreview);
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    seteditimage(file); 
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setEDperveiwimg(reader.result); // Set image preview
      };
      reader.readAsDataURL(file);
    }
  };
  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#513384"),
    backgroundColor: "#513384",
    '&:hover': {
      backgroundColor: "#513384",
    },
  }));
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ zIndex: "1300" }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {id ? "Edit" : "Add"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="rounded-4 card-slider">
            <span className="rounded-2">
           { EDperveiwimg &&  <div
                className="bg-img rounded-3 position-relative"
                style={{
                  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${EDperveiwimg})`,
                }}
              >
                <div
                  className="position-absolute text-white"
                  style={{ bottom: "10px", left: "10px" }}
                >
                  {name}
                </div>
                <div
                  className="position-absolute text-white"
                  style={{ top: "10px", right: "10px" }}
                ></div>
                <div
                  className="position-absolute text-white"
                  style={{ top: "30px", right: "10px" }}
                ></div>
              </div>}
            </span>
          </div>

          <input
            className="form-control mt-4"
            type="text"
            placeholder="Cuisines name"
            value={editname}
            onChange={(e) => seteditname(e.target.value)}
          />
          <div
            className=" rounded-5 mb-3 mx-auto d-flex  justify-content-center mt-4"
            style={{ width: "170px", height: "70px", background: "#F7F6F9" }}
          >
            <label htmlFor="img">
              {" "}
              <p className=" d-flex flex-column justify-content-center align-items-center gap-3">
                <FaPlusCircle style={{ marginTop: "9px" }} /> Upload Image
              </p>
            </label>
            <input
              hidden
              className="form-control mt-4"
              type="file"
              id="img"
              onChange={handleImageChange}
            />
          </div>
        </Modal.Body>
        <ModalFooter className="d-flex gap-2">
          <Button
            onClick={onHide}
         variant="outlined" color="secondary"
            type="submit"
          
          >
            NO
          </Button>
          <ColorButton type="" onClick={handlefavoritedelete} variant="contained">
            Yes
          </ColorButton>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CuisinesEditpopup;

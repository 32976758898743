import React, { useEffect, useState } from 'react'
import { BsExplicit, BsJournal } from 'react-icons/bs'
import { IoIosPower } from 'react-icons/io'
import { IoPersonOutline } from 'react-icons/io5'
import { Link, useNavigate } from 'react-router-dom'
import { logout } from '../redux/authSlice'
import { useDispatch } from 'react-redux'
import { ArrowBackIos, Help } from '@mui/icons-material'
import apiClient from '../api/apiClient'
import { handleErrors } from '../modules/handleErrors'
import { toast } from 'react-toastify'
import { useIndexPageData } from '../contextapi/Indexpagedata'
import Copypopup from '../admin/Copypopup'
import { FcInvite } from "react-icons/fc"

export const Profile = ({setModalShow}) => {
  const { setfrofiledata } = useIndexPageData()
  const navgate = useNavigate()
  const dispatch = useDispatch()
  
  const logoutcam = () => {
    dispatch(logout())
    setfrofiledata({})
    toast.success("You have been logged out successfully.")
    setModalShow(true)
    navgate("/")
  }

  const [eats, seteats] = useState({})
  const [showmodule, setshowmodule] = useState(false)

  const fetchmenuData = async () => {
    const result = await apiClient.get(`/eat/total_eats/`)
    if (!result.ok) return 
    seteats(result?.data || {})  // Avoid setting undefined
  }

  useEffect(() => {
    fetchmenuData()
  }, [])

  return (
    <>
      <section className=" ">
        <div className='m-2' onClick={() => navgate(-1)}>
          <ArrowBackIos sx={{ color: "black", backgroundColor: "" }} />
        </div>
        <div className="mycontainer py-sm-5 mt-sm-3">
          <div className="col-lg-6 col-md-8 mt-sm-0 mt-sm-2 pt-sm-0 pt-2 pt-sm-2">
            <div className="border-black pb-2 px-3">
              {eats?.user && <h4>{eats.user}</h4>}
              {/* {eats?.total_eats && (
                <p className="bg-prpl text-white rounded-pill py-1 px-2 max">
                  {eats.total_eats} EATS
                </p>
              )} */}
            </div>

            <div className="border-black pb-2 mt-3">
              <div>
                <Link to="personal-information" className="text-decoration-none px-3  d-flex  align-items-center text-dark fs-5">
                  <IoPersonOutline className='fs-5 text-prpl me-2' />
            <p className='notranslate m-0'>Informations personnelles </p>    
                </Link>
              </div>
              <div className="mt-3">
                <Link to="/reviews" className="text-decoration-none px-3 text-dark fs-5">
                  <BsJournal className='fs-5 text-prpl me-2' />
                  Avis
                </Link>
              </div>
              <div className="mt-3">
                <div className='text-decoration-none px-3 text-dark fs-5' onClick={() => setshowmodule(true)}>
                  <FcInvite className='fs-5 text-prpl me-2' /> Invite
                </div>  
                {showmodule && <Copypopup show={showmodule} onHide={() => setshowmodule(false)} />}
              </div>
              <div className="mt-3">
                <Link to="/select" className="text-decoration-none px-3 text-dark fs-5">
                  <Help className='fs-5 text-prpl me-2' sx={{ color: "" }} />
                  Aide et soutien
                </Link>
              </div>
            </div>

            {/* <div className="border-black pb-3 mt-3">
              <div className="mt-3">
                <Link to="/eats" className="text-decoration-none text-dark fs-5 px-3">
                  <BsExplicit className='fs-5 text-prpl me-2' />
                  Eats
                </Link>
              </div>
            </div> */}

            <div className="mt-3">
              <div style={{ cursor: "pointer" }} onClick={logoutcam} className="text-decoration-none text-dark py-2 px-3 rounded-2 fs-5">
                <IoIosPower className='fs-5 text-prpl me-2' />
                Déconnexion
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

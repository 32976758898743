import React from 'react'
import Slider from 'react-slick';
import { IconButton } from '@mui/material';
import itemImage from '../assets/item1.png';
import itemImage2 from '../assets/item2.png';
import itemImage3 from '../assets/item3.png';
import itemImage4 from '../assets/item4.png';
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
export const DetailSlider = ({ heading ,resturentdata}) => {
    const sliderRef = React.useRef(null); // Reference to the Slider component
    const data = [
        {
            img: itemImage,
            name: "Marocain",
        },
        {
            img: itemImage2,
            name: "Italien",
        },
        {
            img: itemImage3,
            name: "Libanais (moyen orient)",
        },
        {
            img: itemImage4,
            name: "Jap / Chinois (Asiatique)",
        },
        {
            img: itemImage,
            name: "Brasserie",
        },
    ];
    // Custom arrow components
    const CustomPrevArrow = (props) => (
        <IconButton {...props} className="prev-arrow" onClick={prevSlide}>
            <FaArrowCircleRight className='text-white fs-3' />
        </IconButton>
    );

    const CustomNextArrow = (props) => (
        <IconButton {...props} className="next-arrow" onClick={nextSlide}>
            <FaArrowCircleLeft className='text-white fs-3' />
        </IconButton>
    );

    // Function to navigate to the previous slide
    const prevSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };

    // Function to navigate to the next slide
    const nextSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };
    const settings = {
        dots: true,
        arrows: false,
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };
    console.log(resturentdata,"resturentdataresturentdata");
    return (
        <>


            <div className="slider-wrapper position-relative">
                <CustomNextArrow className="next-arrow" style={{ top: "50%", left: "15px", position: "absolute", transform: "translateY(-50%)", zIndex: "99" }} />
                <Slider ref={sliderRef} {...settings}>
                    {resturentdata&&  resturentdata.map((item, index) => (
                        <div key={index} className='rounded-4 mt-4'>
                            <span className=' rounded-2' >
                                <span className='' style={{height:"100px"}}>
                                    <img className=' rounded-3 object-fit-cover  ' style={{width:"100%",height:"300px",objectPosition:"top"}}  src={item.image} />
                                    
                                </span>
                            </span>

                        </div>
                    ))}
                </Slider>
                <CustomPrevArrow className="prev-arrow" style={{ top: "50%", right: "15px", position: "absolute", transform: "translateY(-50%)", zIndex: "99" }} />
            </div>
        </>
    )
}

import { createSlice } from "@reduxjs/toolkit";
import apiClient from "../api/apiClient";
const initialState = {
  adminData: null,
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setAdminData: (state, action) => {
      state.adminData = action.payload;
    //   console.log(state.adminData);
    },
  },
});

// export const fetchAdminData = () => async (state, action) => {
//   try {
//     const { data, ok } = await apiClient.get("/stats");
//     // console.log(data);

//     if (ok) {
//       //   dispatch(authSlice.actions.setUser(data?.result)); // Dispatch the action to set the user data
//       state.adminData = data;
//     }
//   } catch (e) {
//     console.e("Error fetching user data:", e);
//   }
// };

export const { setAdminData } = adminSlice.actions;
export const selectAdminData = (state) => state.admin.adminData;
export default adminSlice.reducer;

import { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function ProtectedRoute({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn, userType } = useSelector((state) => state.auth);
  const redirectToDashboard = (userType, dashboardPath) => {
    !location.pathname.includes(userType) && navigate(dashboardPath);
  };
  useEffect(() => {
    if (!isLoggedIn) {
      // navigate("/"); 
    } else if (userType) {
      switch (userType) {
        case "A":
          redirectToDashboard("admin", "/admin/dashboard");
          break;
        // case "R":
        //   redirectToDashboard("restaurant ", "/restaurant/dashboard");
        //   break;
        
        default:
          break;
      }
    }
  }, [isLoggedIn, userType]);

  return isLoggedIn ? children : children;
}

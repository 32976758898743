import { CardContent, CircularProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import apiClient from '../api/apiClient';
export const Featured = () => {
  
    const [restaurant, setrestaurantsData ] = useState([])
    const [loading, setLoading]=useState(true);
    const navgater=useNavigate()


    const fetchData  = async()=>{
        try {
          setLoading(true)
          const result = await apiClient.get(`/cuisines/`)
          if (result.ok) setrestaurantsData(result.data.results)
        //   setCount(result?.data.count);
          setLoading(false)
          console.log(result, "resttt detail")
        } catch (error) {
          
        }
    
      }



  useEffect(() => {
    fetchData()
   }, []);

   console.log(restaurant, "fff")
   const hanldeserach=(type)=>{
  

    navgater(`/filter-restaurant?type=${type}`)
  }

  return (
    <div>

{!loading?  <section class="bg-ltw  ">
        <div class="container py-3 py-sm-5">
            <div class="row justify-content-between ">
                <h5 class="fs-3 fw-normal text-center text-prpl">
                Découvrez notre sélection de cuisines et laissez-vous emporter par l'expérience Boogeat !
                </h5>
                
            </div>


<div className="row ">
    {restaurant?.map((item, index) => (
      <div className="col-md-4 col-sm-6">
        <div key={index} className='rounded-4 card-slider'>
        <span className='px-3'>

          <div className='rounded-4 px-3'onClick={()=>hanldeserach(item?.id)} >
            <img
              style={{ width:"100%", height:"250px", objectPosition:"top" }}
              className='object-fit-cover mx-auto rounded-4'
              src={item?.image}
              alt="Paella dish"
            />
          </div>
        </span>
        <CardContent className='text-center pt-0'>
          <Typography className='' variant="h6" color="text.secondary">
            {item?.name}
          </Typography>
        </CardContent>
        </div>
      </div>
      ))}
    </div>

        </div>
    </section>: <div className="d-flex justify-content-center mt-4">
    <CircularProgress color="secondary" />

    </div>
    
  
  }
    </div>
  )
}

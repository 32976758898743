import React from "react";
import { BestrestaurentSlider } from "../components/BestrestaurentSlider";

export const BestRestaurent = () => {
  return (
    <div>
      <div>
        <section className="shadow-sm py-3 first-sec">
          <div className="container-fluid">
            <div className="d-flex align-items-center flex-wrap">
              <div className="w-cal">
                <div className="filter-border rounded-pill py-2 px-3 d-flex align-items-center justify-content-between">
                  <div className="line win-line">
                    <div className="dropdown">
                      <button
                        aria-expanded="false"
                        className="bg-transparent border-0 d-flex"
                        data-bs-toggle="dropdown"
                        type="button"
                      >
                        <i className="bi bi-calendar-date me-2" />
                        Date
                        <i className="bi bi-chevron-down ms-2" />
                      </button>
                      <ul className="dropdown-menu">
                        <div className="mycalendar bg-white">
                          <div className="mt-0" id="calendar-container" />
                          <input
                            className="mt-4 d-none"
                            defaultValue=""
                            id="inputField"
                            name="date"
                            required
                            type="text"
                          />
                        </div>
                      </ul>
                    </div>
                  </div>
                  <div className="line  win-line">
                    <div className="dropdown">
                      <button
                        aria-expanded="false"
                        className="bg-transparent border-0 d-flex"
                        data-bs-toggle="dropdown"
                        type="button"
                      >
                        <i className="bi bi-alarm me-2" />
                        Time
                        <i className="bi bi-chevron-down ms-2" />
                      </button>
                      <ul className="dropdown-menu w-ca">
                        <p className="text-center fs-5 mb-2">Select a time</p>
                        <div className="row p-2">
                          <div className="col-4 my-2">
                            <div className="border-prpl rounded-3 h-100 d-flex align-items-center pointer justify-content-center">
                              <p className="text-center fs-5 mb-0 py-2">1:00</p>
                            </div>
                          </div>
                          <div className="col-4 my-2">
                            <div className="border-prpl rounded-3 h-100 d-flex align-items-center pointer justify-content-center">
                              <p className="text-center fs-5 mb-0 py-2">2:00</p>
                            </div>
                          </div>
                          <div className="col-4 my-2">
                            <div className="border-prpl rounded-3 h-100 d-flex align-items-center pointer justify-content-center">
                              <p className="text-center fs-5 mb-0 py-2">3:00</p>
                            </div>
                          </div>
                          <div className="col-4 my-2">
                            <div className="border-prpl rounded-3 h-100 d-flex align-items-center pointer justify-content-center">
                              <p className="text-center fs-5 mb-0 py-2">4:00</p>
                            </div>
                          </div>
                          <div className="col-4 my-2">
                            <div className="border-prpl rounded-3 h-100 d-flex align-items-center pointer justify-content-center">
                              <p className="text-center fs-5 mb-0 py-2">5:00</p>
                            </div>
                          </div>
                          <div className="col-4 my-2">
                            <div className="border-prpl rounded-3 h-100 d-flex align-items-center pointer justify-content-center">
                              <p className="text-center fs-5 mb-0 py-2">6:00</p>
                            </div>
                          </div>
                          <div className="col-4 my-2">
                            <div className="border-prpl rounded-3 h-100 d-flex align-items-center pointer justify-content-center">
                              <p className="text-center fs-5 mb-0 py-2">7:00</p>
                            </div>
                          </div>
                          <div className="col-4 my-2">
                            <div className="border-prpl rounded-3 h-100 d-flex align-items-center pointer justify-content-center">
                              <p className="text-center fs-5 mb-0 py-2">8:00</p>
                            </div>
                          </div>
                          <div className="col-4 my-2">
                            <div className="border-prpl rounded-3 h-100 d-flex align-items-center justify-content-center">
                              <p className="text-center fs-5 mb-0 py-2">9:00</p>
                            </div>
                          </div>
                          <div className="col-4 my-2">
                            <div className="border-prpl rounded-3 h-100 d-flex align-items-center justify-content-center">
                              <p className="text-center fs-5 mb-0 py-2">
                                10:00
                              </p>
                            </div>
                          </div>
                          <div className="col-4 my-2">
                            <div className="border-prpl rounded-3 h-100 d-flex align-items-center justify-content-center">
                              <p className="text-center fs-5 mb-0 py-2">
                                11:00
                              </p>
                            </div>
                          </div>
                          <div className="col-4 my-2">
                            <div className="border-prpl rounded-3 h-100 d-flex align-items-center justify-content-center">
                              <p className="text-center fs-5 mb-0 py-2">
                                12:00
                              </p>
                            </div>
                          </div>
                        </div>
                      </ul>
                    </div>
                  </div>
                  <div className=" win-line">
                    <div className="dropdown">
                      <button
                        aria-expanded="false"
                        className="bg-transparent border-0 d-flex"
                        data-bs-toggle="dropdown"
                        type="button"
                      >
                        <i className="bi bi-person me-2" />
                        Person
                        <i className="bi bi-chevron-down ms-2" />
                      </button>
                      <ul className="dropdown-menu w-ca">
                        <div className="row  justify-content-center">
                          <div className="col-10">
                            <div className="row">
                              <div className="col-3 my-2">
                                <div className="border-prpl shadow rounded-3 h-100 d-flex align-items-center pointer justify-content-center">
                                  <p className="text-center fs-5 mb-0 py-2">
                                    1
                                  </p>
                                </div>
                              </div>
                              <div className="col-3 my-2">
                                <div className="border-prpl shadow rounded-3 h-100 d-flex align-items-center pointer justify-content-center">
                                  <p className="text-center fs-5 mb-0 py-2">
                                    2
                                  </p>
                                </div>
                              </div>
                              <div className="col-3 my-2">
                                <div className="border-prpl shadow rounded-3 h-100 d-flex align-items-center pointer justify-content-center">
                                  <p className="text-center fs-5 mb-0 py-2">
                                    3
                                  </p>
                                </div>
                              </div>
                              <div className="col-3 my-2">
                                <div className="border-prpl shadow rounded-3 h-100 d-flex align-items-center pointer justify-content-center">
                                  <p className="text-center fs-5 mb-0 py-2">
                                    4
                                  </p>
                                </div>
                              </div>
                              <div className="col-3 my-2">
                                <div className="border-prpl shadow rounded-3 h-100 d-flex align-items-center pointer justify-content-center">
                                  <p className="text-center fs-5 mb-0 py-2">
                                    5
                                  </p>
                                </div>
                              </div>
                              <div className="col-3 my-2">
                                <div className="border-prpl shadow rounded-3 h-100 d-flex align-items-center pointer justify-content-center">
                                  <p className="text-center fs-5 mb-0 py-2">
                                    1
                                  </p>
                                </div>
                              </div>
                              <div className="col-3 my-2">
                                <div className="border-prpl shadow rounded-3 h-100 d-flex align-items-center pointer justify-content-center">
                                  <p className="text-center fs-5 mb-0 py-2">
                                    2
                                  </p>
                                </div>
                              </div>
                              <div className="col-3 my-2">
                                <div className="border-prpl shadow rounded-3 h-100 d-flex align-items-center pointer justify-content-center">
                                  <p className="text-center fs-5 mb-0 py-2">
                                    3
                                  </p>
                                </div>
                              </div>
                              <div className="col-3 my-2">
                                <div className="border-prpl shadow rounded-3 h-100 d-flex align-items-center pointer justify-content-center">
                                  <p className="text-center fs-5 mb-0 py-2">
                                    4
                                  </p>
                                </div>
                              </div>
                              <div className="col-3 my-2">
                                <div className="border-prpl shadow rounded-3 h-100 d-flex align-items-center pointer justify-content-center">
                                  <p className="text-center fs-5 mb-0 py-2">
                                    5
                                  </p>
                                </div>
                              </div>
                              <div className="col-3 my-2">
                                <div className="border-prpl shadow rounded-3 h-100 d-flex align-items-center pointer justify-content-center">
                                  <p className="text-center fs-5 mb-0 py-2">
                                    6
                                  </p>
                                </div>
                              </div>
                              <div className="col-3 my-2">
                                <div className="border-prpl shadow rounded-3 h-100 d-flex align-items-center pointer justify-content-center">
                                  <p className="text-center fs-5 mb-0 py-2">
                                    7
                                  </p>
                                </div>
                              </div>
                              <div className="col-3 my-2">
                                <div className="border-prpl shadow rounded-3 h-100 d-flex align-items-center pointer justify-content-center">
                                  <p className="text-center fs-5 mb-0 py-2">
                                    8
                                  </p>
                                </div>
                              </div>
                              <div className="col-3 my-2">
                                <div className="border-prpl shadow rounded-3 h-100 d-flex align-items-center pointer justify-content-center">
                                  <p className="text-center fs-5 mb-0 py-2">
                                    9
                                  </p>
                                </div>
                              </div>
                              <div className="col-3 my-2">
                                <div className="border-prpl shadow rounded-3 h-100 d-flex align-items-center pointer justify-content-center">
                                  <p className="text-center fs-5 mb-0 py-2">
                                    10
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-3">
                          <a className="d-block text-center text-prpl" href="#">
                            See more persons
                          </a>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-cal2">
                <div className="d-flex flex-wrap align-items-center">
                  <div className="w-cal3">
                    <div>
                      <button className="rounded-pill bg-prpl5 border-0 px-3 py-2 text-white d-flex">
                        <i className="bi bi-sliders2-vertical me-2" />
                        Filter
                      </button>
                    </div>
                  </div>

                  <div className="w-cal4">
                    <div className="center7 d-flex" style={{maxWidth:"950px",overflowX:"auto", scrollbarWidth:"none"}}>
                      <div className="px-3">
                        <button className="rounded-pill btn-font bg-transparent border-c px-3 py-2 text-dark text-nowrap">
                          <i className="bi bi-percent" />
                          Special Offers
                        </button>
                      </div>
                      <div className="px-3">
                        <button className="rounded-pill btn-font bg-transparent border-c px-3 py-2 text-dark text-nowrap">
                          <i className="bi bi-percent" />
                          Best rated
                        </button>
                      </div>
                      <div className="px-3">
                        <button className="rounded-pill btn-font bg-transparent border-c px-3 py-2 text-dark text-nowrap">
                          <i className="bi bi-percent" />
                          Cuisine
                        </button>
                      </div>
                      <div className="px-3">
                        <button className="rounded-pill btn-font bg-transparent border-c px-3 py-2 text-dark text-nowrap">
                          <i className="bi bi-percent" />
                          Neighborhood
                        </button>
                      </div>
                      <div className="px-3">
                        <button className="rounded-pill btn-font bg-transparent border-c px-3 py-2 text-dark text-nowrap">
                          <i className="bi bi-percent" />
                          Accepting my yums
                        </button>
                      </div>
                      <div className="px-3">
                        <button className="rounded-pill btn-font bg-transparent border-c px-3 py-2 text-dark text-nowrap">
                          <i className="bi bi-percent" />
                          Price
                        </button>
                      </div>
                      <div className="px-3">
                        <button className="rounded-pill btn-font bg-transparent border-c px-3 py-2 text-dark text-nowrap">
                          <i className="bi bi-percent" />
                          Insider
                        </button>
                      </div>
                      <div className="px-3">
                        <button className="rounded-pill btn-font bg-transparent border-c px-3 py-2 text-dark text-nowrap">
                          <i className="bi bi-percent" />
                          The Boogeat pay
                        </button>
                      </div>
                      <div className="px-3">
                        <button className="rounded-pill btn-font bg-transparent border-c px-3 py-2 text-dark text-nowrap">
                          <i className="bi bi-percent" />
                          Setting & Moments
                        </button>
                      </div>
                      <div className="px-3">
                        <button className="rounded-pill btn-font bg-transparent border-c px-3 py-2 text-dark text-nowrap">
                          Clear all
                        </button>
                      </div>
                      <div className="px-3">
                        <button className="rounded-pill btn-font bg-transparent border-c px-3 py-2 text-dark text-nowrap">
                          Sort by
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className=" mb-4">
          <div className="container-fluid">
            <div className="d-flex align-items-center flex-wrap gap-4 py-4">
              <h4 className="mb-0">The Best Restaurants in Paris</h4>
              <p className="mb-0">6 restaurants</p>
            </div>
            <div className="row flex-lg-row flex-column-reverse">
              <div className="col-lg-7 mt-lg-0 mt-3">
                <div className="border-black">
                  <a
                    className="bg-white rounded-3 p-2 mt-sm-0 text-decoration-none mb-3 text-dark shadow d-flex flex-sm-row flex-column gap-2"
                    // href="details.html"
                  >
                    <div>
                      <img alt="" className="gly-img" src={require("../assets/item3.png")} />
                    </div>
                    <div className="w-100">
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <p className="fs-5">French</p>
                        </div>
                        <div>
                          <i className="bi bi-heart fs-4" />
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <h5 className="fs-5">Princess Cafe</h5>
                        </div>
                        <div>
                          <h6>9.0</h6>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <p className="fs-5">32 Rue Bichat, 75010, Paris</p>
                        </div>
                        <div>
                          <i className="bi bi-chat" />
                          <span>745</span>
                        </div>
                      </div>
                      <div>
                        <p>Average Price $13</p>
                      </div>
                      <div>
                        <p className="text-danger fw-bold">30% off Food</p>
                      </div>
                      <div>
                        <ul className="list-unstyled d-flex align-items-center pb-2">
                          <li className="bg-prpl3 text-white px-4 py-2 position-relative">
                            10:00
                            <div className="position-per">
                              <p className="bg-white text-dark mb-0 p-1">
                                -50%
                              </p>
                            </div>
                          </li>
                          <li className="bg-prpl3 text-white px-4 py-2 position-relative ms-3">
                            10:00
                            <div className="position-per">
                              <p className="bg-white text-dark mb-0 p-1">
                                -50%
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="border-black mt-3">
                  <a
                    className="bg-white rounded-3 p-2 mt-sm-0 text-decoration-none mb-3 text-dark shadow d-flex flex-sm-row flex-column gap-2"
                    href="details.html"
                  >
                    <div>
                      <img alt="" className="gly-img" src={require("../assets/item3.png")} />
                    </div>
                    <div className="w-100">
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <p className="fs-5">French</p>
                        </div>
                        <div>
                          <i className="bi bi-heart fs-4" />
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <h5 className="fs-5">Princess Cafe</h5>
                        </div>
                        <div>
                          <h6>9.0</h6>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <p className="fs-5">32 Rue Bichat, 75010, Paris</p>
                        </div>
                        <div>
                          <i className="bi bi-chat" />
                          <span>745</span>
                        </div>
                      </div>
                      <div>
                        <p>Average Price $13</p>
                      </div>
                      <div>
                        <p className="text-danger fw-bold">30% off Food</p>
                      </div>
                      <div>
                        <ul className="list-unstyled d-flex align-items-center pb-2">
                          <li className="bg-prpl3 text-white px-4 py-2 position-relative">
                            10:00
                            <div className="position-per">
                              <p className="bg-white text-dark mb-0 p-1">
                                -50%
                              </p>
                            </div>
                          </li>
                          <li className="bg-prpl3 text-white px-4 py-2 position-relative ms-3">
                            10:00
                            <div className="position-per">
                              <p className="bg-white text-dark mb-0 p-1">
                                -50%
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="bg-prpl3 p-3 radius-prpl mt-4">
                  <h5 className="text-white">Discover our suggestions</h5>
                  <p className="text-white">
                    We have a large variety of restaurants that might interest
                    you based on your search
                  </p>
                </div>
                <section className="bg-ltw position-relative slider_arr mt-3">
                  <div className="mycontainer py-5 py-sm-5">
                    <div className="px-3 d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-sm-between mb-3">
                      <h2 className="fs-1 fw-normal my-2 mb-0 fs-3 text-prpl">
                        You may also like in Paris
                      </h2>
                     
                    </div>
                    <div>
                    <BestrestaurentSlider/>
                    </div>
                  </div>
                </section>
              </div>
              <div className="col-lg-5">
              <div
      style={{
        position: 'relative',
        textAlign: 'right',
        width: '100%',
        height: '100%',
      }}
      className="mapouter"
    >
      <div
        style={{
          overflow: 'hidden',
          background: 'none!important',
          width: '100%',
          height: '100%',
        }}
        className="gmap_canvas"
      >
        <iframe
          className="gmap_iframe"
          title="Google Map"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          src="https://maps.google.com/maps?width=600&amp;height=938&amp;hl=en&amp;q=sialkot &amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          style={{
            width: '600px',
            height: '100%',
          }}
        ></iframe>
        <a href="https://strandsgame.net/">Strands</a>
      </div>
    </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

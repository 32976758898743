import React, { useEffect, useState } from "react";
import { BsPencilFill } from "react-icons/bs";
import apiClient from "../api/apiClient";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { handleErrors } from "../modules/handleErrors";
import { toast } from "react-toastify";
import { ArrowBackIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useIndexPageData } from "../contextapi/Indexpagedata";
import ReactInputMask from "react-input-mask";

export const RestaProfile = () => {
  const { profiledata, fetchDataprofile, frofiledata, setfrofiledata } =
    useIndexPageData();

  const { userid } = useSelector(
    (state) => state.auth
  );

  const [name, setName] = useState("");

  const [email, setEmail] = useState("");
  const [imagePreview, setImagePreview] = useState(null); // State variable to store the selected image preview
  const [loader, setLoader] = useState(false);

  const handleonchage = (key, value) => {
    setfrofiledata((prev) => ({ ...prev, [key]: value }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setfrofiledata((prev) => ({ ...prev, image: file }));

    // Read the selected image file and set the preview
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };

  const navigater = useNavigate();

  const dispatch = useDispatch();
  // const fetchData = async () => {
  //   const result = await apiClient.get(`/register/${userid}/`);
  //   if (result.ok) {
  //     setfrofiledata(result.data);
  //     setEmail(result.data.email);
  //     setName(result.data.name);
  //   }
  // };

  useEffect(() => {
    setfrofiledata(profiledata);
    setEmail(profiledata.email);
    setName(profiledata.first_name);
  }, [profiledata.email, profiledata.name]);


  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("name", frofiledata.name);

    formData.append("email", frofiledata.email);

    if (frofiledata.phone) {
      formData.append("phone", frofiledata.phone);
    }

    if (frofiledata.first_name) {
      formData.append("first_name", frofiledata.first_name);
    }
    if (frofiledata.last_name) {
      formData.append("last_name", frofiledata.last_name);
    }

    if (imagePreview) {
      formData.append("image", frofiledata.image);
    }

    setImagePreview("");

    // Send form data to the server
    setLoader(true);
    const result = await apiClient.put(`/register/${userid}/`, formData);
    setLoader(false);
    if (!result.ok) return handleErrors(result.data);
    toast.success("Update profile");
    if (result.ok) {
      fetchDataprofile();
      // Handle success
    } else {
      // Handle error
    }
  };

  useEffect(() => {
    fetchDataprofile();
  }, []);
  return (
    <>
      <section className="mb-2">
        <p className="m-0" onClick={() => navigater(-1)}>
          <ArrowBackIos className=" m-0 mx-2" sx={{ color: "black" }} />
        </p>
        <div className="mycontainer py-sm-5 mt-sm-0 mt-0 pt-0">
          <h3 className="text-center m-0 notranslate">Informations personnelles</h3>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-8 col-md-10 mx-auto py-2">
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <div className="text-center position-rel">
                    <img
                      src={
                        imagePreview ||
                        (frofiledata.image
                          ? frofiledata.image
                          : require("../assets/person.png"))
                      }
                      alt=""
                      className="personal-img"
                    />
                    <div className="position-edit">
                      <label htmlFor="up">
                        <BsPencilFill className="fs-6" />
                      </label>
                      <input
                        type="file"
                        name="image"
                        id="up"
                        className="d-none"
                        onChange={handleImageChange}
                      />
                    </div>
                  </div>
                  <p className="text-center fw-bold m-0">{name}</p>
                  <p className="m-0" >{email}</p>
                </div>
                <div className="row mt-0">
                  <div className="col-sm-7 mx-auto">
                    <div className="mt-1">
                      <label htmlFor="">Frist Name</label>
                      <input
                        type="text"
                        name=""
                        value={frofiledata?.first_name}
                        onChange={(e) =>
                          handleonchage("first_name", e.target.value)
                        }
                        className="form-control shadow-none"
                        placeholder="First Name"
                      />
                    </div>
                    <div className="mt-2">
                      <label htmlFor=""> Last Name</label>
                      <input
                        type="text"
                        name=""
                        value={frofiledata?.last_name}
                        onChange={(e) =>
                          handleonchage("last_name", e.target.value)
                        }
                        className="form-control shadow-none"
                        placeholder="First Name"
                      />
                    </div>

                    <div className="mt-2">
                      <label htmlFor="">Email</label>
                      <input
                        type="email"
                        name=""
                        onChange={(e) => handleonchage("email", e.target.value)}
                        value={frofiledata?.email}
                        className="form-control shadow-none"
                        placeholder="Email"
                      />
                    </div>
                    {/* <div className="mt-2">
                      <label htmlFor="">DOB</label>
                      <input
                        type="date"
                        name=""
                        onChange={(e) => handleonchage("dob", e.target.value)}
                        value={frofiledata?.dob}
                        className="form-control shadow-none"
                        placeholder="Date of birth "
                      />
                    </div> */}
                    <div className="mt-2">
                      <label htmlFor="">Phone Number</label>
                      <ReactInputMask
                        mask="+212 9 99 99 99 99"
                        onChange={(e) => handleonchage("phone", e.target.value)}
                        value={frofiledata?.phone}
                      >
                        {(inputProps) => (
                          <input
                            {...inputProps}
                            min={1}
                            type="text"
                            name=""
                            className="form-control shadow-none"
                            placeholder="Phone Number"
                          />
                        )}
                      </ReactInputMask>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <button
                disabled={loader}
                type="submit"
                style={{ backgroundColor: "#442479" }}
                className="btn text-light "
              >
                {!loader ? "Submit" : "Submit..."}
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

import React, { useState } from 'react'
import { handleErrors } from '../modules/handleErrors';
import apiClient from '../api/apiClient';
import { toast } from 'react-toastify';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Link } from 'react-router-dom';
export const CreateNewUser = () => {
    const [lastname, setlastName] = useState("");
    const [firstname, setfristName] = useState("");
    const [registerloader, setregisterloader] = useState(false);
    const [email, setEmail] = useState("");
    const [Confirmpassword, setConfirmpassword] = useState("");
    const [password, setPassword] = useState("");
    const [role, setrole] = useState("");
    const handelsubmit = async (e) => {
        e.preventDefault();
        if (password !== Confirmpassword) {
          toast.error("Password and Confirm password does not match ");
        } else {
          let requestBody = {
            first_name: firstname,
            last_name: lastname,
            email,
            password,
            role: role,
          };
        //   if (user_id) {
        //     requestBody.user_id = user_id;
        //   }
          setregisterloader(true);
          const result = await apiClient.post("/register/", requestBody);
          setregisterloader(false);
          if (!result.ok) {
            handleErrors(result.data);
         
            return;
          }
          // toast.success("You have registered successfully!")
          setlastName("");
          setfristName("");
          setEmail("");
          setPassword("");
          setConfirmpassword("");
        //   fetchDataprofile(result.data.user.id);
         
    
          toast.success("You Are Registered Successfully");
       
        }
      };
  return (
    <>
  <p className='text-dark '>

<Link to={"/admin/resturants"}>
<ArrowBackIosNewIcon sx={{color:"black",cursor:"pointer"}} />
</Link>
      </p>
    <div className='d-flex justify-content-center'>
    
           <form style={{width:"60vw"}}
                      className="register"
                      onSubmit={handelsubmit}
                      action=""
                    >
                      <div className="px-2 py-3  rounded-4 d-flex align-items-center mt-3 px-4 bg-white shadow">
                        <i className="fa-regular fa-user me-2" />
                        <input
                          className="border-0 w-100 focus-none"
                          placeholder="prénom"
                          required
                          type="text"
                          value={firstname}
                          onChange={(e) => setfristName(e.target.value)}
                        />
                      </div>
                      <div className="px-2 py-3  rounded-4 d-flex align-items-center mt-3 px-4 bg-white shadow">
                        <i className="fa-regular fa-user me-2" />
                        <input
                          className="border-0 w-100 focus-none"
                          placeholder="nom de famille"
                          required
                          type="text"
                          value={lastname}
                          onChange={(e) => setlastName(e.target.value)}
                        />
                      </div>

                      <div className="px-2 py-3 rounded-4 d-flex align-items-center mt-3 px-4 bg-white shadow">
                        <i className="far fa-envelope me-2" />
                        <input
                          className="border-0 w-100 focus-none"
                          placeholder="Adresse e-mail"
                          type="email"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>

                      <div className="px-2 py-3 rounded-4 d-flex align-items-center mt-3 px-4 bg-white shadow">
                        <i className="fas fa-lock me-2" />
                        <input
                          className="border-0 w-100 focus-none"
                          required
                          placeholder="entrez votre mot de passe"
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>

                      <div className="px-2 py-3 rounded-4 d-flex align-items-center mt-3 px-4 bg-white shadow">
                        <i className="fas fa-lock me-2" />
                        <input
                          required
                          className="border-0 w-100 focus-none"
                          placeholder="confirmez votre mot de passe"
                          type="password"
                          value={Confirmpassword}
                          onChange={(e) => setConfirmpassword(e.target.value)}
                        />
                      </div>
                      {/* <div className="px-2 py-3 rounded-4 d-flex align-items-center mt-3 px-4 bg-white shadow">
                        <i className="fas fa-lock me-2" /> */}
                        {/* <div className="my-2 py-2"> */}

                        <div className="px-0 py-0 rounded-4 d-flex align-items-center mt-3 px-4 bg-white shadow">
                        <select className ="form-select border-0  removeactiveinput py-3" aria-label="Default select example" value={role} onChange={(e)=>setrole(e.target.value)}>
  <option selected> select role</option>
  <option value="R">Restaurants</option>
  <option value="U">User</option>
  
</select>
</div>
{/* </div> */}
                      {/* </div> */}

                      <div className="mt-4 d-flex justify-content-center justify-content-between">
                       
                        <button
                          className="text-white border-0 rounded-pill bg-prpl px-4 mx-s px-md-4 px-lg-5 py-2"
                          type="submit"
                        >
                          {registerloader ? "s’inscrire..." : "s’inscrire "}
                        </button>
                      </div>
                      <div className="mt-4">
                        <p className="mb-0 text-center mt-3">
                     
                          <br />
                        
                        </p>
                      </div>
                    </form>



    </div>
    </>
  )
}

import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Rating,
  Select,
} from "@mui/material";
import { useEffect, useState } from "react";

import { FaLocationDot } from "react-icons/fa6";
import { FaCalendar } from "react-icons/fa";
import { MdAccessTime } from "react-icons/md";

import { FaUser } from "react-icons/fa";
import { DetailSlider } from "../components/DetailSlider";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { getCurrentURLParameters } from "../modules/helpers";
import apiClient from "../api/apiClient";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Bookingpopup from "../components/Bookingpopup";
import Faq from "./Faq";
import { toast } from "react-toastify";
import { MdDirections } from "react-icons/md";

const Detail = () => {
  const { id } = getCurrentURLParameters();
  const [resturentdata, setResturentdDta] = useState([]);
  const [menudata, setmenudata] = useState([]);

  const [menu, setmenu] = useState([]);
  const navgate = useNavigate();
  // Booking Table #ff0
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [time, setTime] = useState("");
  // Booking Table #ff0
  const [loading, setLoading] = useState(false);
  const [loadingbooking, setloadingbooking] = useState(false);
  const [filter, setFilter] = useState([]);

  const [showmodul, setshowmodul] = useState(false);

  const { token, userType, isLoggedIn, userid } = useSelector(
    (state) => state.auth
  );
  // console.log(isLoggedIn, "llll")

  const handleClick = (number) => {
    setSelectedNumber(number);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const result = await apiClient.get(`/restaurants/${id}/`);
    // console.log(result,"deee");
    if (result.ok) setResturentdDta(result?.data);

    const filterResult = await apiClient.get(
      `/restaurants/?restaurant_id=${id}`
    );
    if (filterResult.ok) setFilter(filterResult?.data?.results);
    // console.log(filterResult, "ffff")

    setLoading(false);

    // console.log(result, "city detail")
  };

  const fetchmenuData = async () => {
    const result = await apiClient.get(`/menus/?restaurant_id=${id}`);
    if (!result.ok) return;
    console.log(result, "deee");
    setmenudata(result?.data.results);
    // console.log(result, "city detail")
  };

  useEffect(() => {
    fetchData();
    fetchmenuData();
  }, [id]);

  console.log(filter, "ffff");

  const handletimeChange = (time) => {
    setTime(time);
    setcalendertab("user");
  };
  console.log(time);

  const handleDateChange = (date) => {
    // Check if the date object is valid
    console.log(date.$d, "date");
    if (date && typeof date === "object") {
      const year = date.$d.getFullYear();
      const month = date.$d.getMonth() + 1;
      const day = date.$d.getDate();

      const datefarmate = `${year}-${month}-${day}`;
      setSelectedDate(datefarmate);
    }
    setcalendertab("time");
  };
  console.log(selectedDate, "dat");
  console.log(menudata, "menudata");

  const [activetab, setactivetab] = useState("about");
  const [bookingtable, setbookingtable] = useState(true);
  const [calendertab, setcalendertab] = useState("calender");
  console.log(calendertab, "tatat");

  const handelbooking = async (e) => {
    e.preventDefault();
    console.log(selectedDate, selectedNumber, time, menu, "pppppppppp");
    setloadingbooking(true);
    const result = await apiClient.post("/bookings/", {
      date: selectedDate,
      persons: selectedNumber,
      time: time,
      restaurant: id,
    });
    setloadingbooking(false);
    if (!result.ok) return;
    toast.success("Your reservation has been successfully booked.");

    navgate("/upcoming-booking");
    // if( result.data?.user_type=="user"){

    // }else if() { props.navgater("admin/dashboard")}
  };
  const lunchTimes = [
    "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30",
    "15:00", "15:30", "16:00", "16:30"
  ];
  const dinnerTimes = [
    "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30",
    "21:00", "21:30", "22:00", "22:30", "23:00", "23:30", "00:00", "00:30",
    "01:00", "01:30"
  ];
  return (
    <div className="">
      <div>
        <div
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel2"
          className="modal my-modal fade"
          id="exampleModalToggle2"
          tabIndex="-1"
        >
          <div className="modal-dialog">
            <div className="modal-content position-relative">
              <div className="modal-body">
                <div className="d-flex flex-column justify-content-between align-items-center h-100">
                  <div>
                    <div className="text-center">
                      <img alt="" src="/img/great-meal.jpeg" />
                    </div>
                    <h5 className="text-center">
                      Get everyone set for a great meal.
                    </h5>
                    <p className="text-center">
                      Send personalised invitation links to your guests
                      everything they need to plan the ahead.
                    </p>
                  </div>
                  <div className="w-100">
                    <div>
                      <button
                        className="text-white border-0 bg-prpl p-2 w-100 rounded-2"
                        data-bs-target="#exampleModalToggle3"
                        data-bs-toggle="modal"
                        id="mainModalButton"
                      >
                        <i className="bi bi-upload me-2" />
                        SEND INVITES
                      </button>
                    </div>
                    <div className="mt-3">
                      <button
                        aria-label="Close"
                        className="text-prpl2 border-0 bg-white p-2 fw-bold w-100 rounded-2"
                        data-bs-dismiss="modal"
                      >
                        Not Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel3"
          className=" modal my-modal fade"
          id="exampleModalToggle3"
          tabIndex="-1"
        ></div>
        {loading ? (
          <div className=" mx-auto text-center d-flex justify-content-center mt-5">
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <div className=" after-hidden positon-relative pb-sm-0  mb-2 mb-sm-0">
            <div
              className="position-table bg-white"
              onClick={() => setbookingtable(!bookingtable)}
            >
              <div onClick={() => setshowmodul(true)}>
                <h4
                  className="bg-prpl3 text-white text-center p-2 pointer"
                  onclick="toggleDivs()"
                >
                  Book a table
                </h4>
              </div>
            </div>

            <section className="  py-sm-5  py-3 mb-2">
              <div className="mycontainer ">
                {<DetailSlider resturentdata={resturentdata.images} />}
              </div>
            </section>
            <section className=" my-3 mt-5">
              <div className="mycontainer">
                <div className="row justify-content-center">
                  <div className="col-md-7 col-lg-8">
                    <p className="bg-success d-inline-block text-white rounded-3 px-3 py-2 mb-0">
                      Available
                    </p>
                    <h2 className="fs-3 mt-3">{resturentdata?.name}</h2>
                    <p>
                      {/* In Publishing And Graphic Design, Lorem Ipsum Is A Placeholder Text Commonly Used To Demonstrate    
                                      The Visual Form Of A Document */}
                      {resturentdata?.about}
                    </p>
                    <div className="d-flex align-items-center ">
                      <Rating value={resturentdata?.average_rating} readOnly />
                      {/* <p className="mb-0">{4}.00/5.00</p> */}
                    </div>
                    <div className="mt-4">
                      <div className="d-flex my-2 align-items-center">
                        <img
                          alt=""
                          className="det-img me-2"
                          src={require("../assets/abt1.png")}
                        />
                        <p className="text-prpl mb-0">
                          {/* 60 Boulevard Du Montparnasse, 75015 Paris */}
                          {resturentdata?.location}
                        </p>
                      </div>
                      <div className="d-flex my-2 align-items-center ">
                        <img
                          alt=""
                          className="det-img me-2"
                          src={require("../assets/abt2.png")}
                        />
                        <p className="text-prpl d-flex gap-2 mb-0">
                          {/* French */}
                          {/* {resturentdata?.city_name?.name} */}
                          {resturentdata?.cuisine_names?.map((e) => (
                            <div className="">{e}</div>
                          ))}
                        </p>
                      </div>

                      <div className="d-flex my-2 align-items-center">
                        <img
                          alt=""
                          className="det-img me-2"
                          src={require("../assets/abt3.png")}
                        />
                        <p className="text-prpl mb-0">
                          Prix moyen {resturentdata?.average_price}
                        </p>
                      </div>
                    </div>
                    <div className="tab-container mt-5">
                      <div
                        style={{
                          background: activetab === "about" ? "#CFC4E0" : "",
                        }}
                        onClick={() => setactivetab("about")}
                        className="tab tab1"
                      >
                        à propos
                      </div>
                      <div
                        onClick={() => setactivetab("menu")}
                        style={{
                          background: activetab === "menu" ? "#CFC4E0" : "",
                        }}
                        className="tab tab2"
                        data-tab="tab2"
                      >
                        Menu
                      </div>
                      {/* <div    onClick={()=>setactivetab("avis")}
              style={{ background: activetab === "avis" ? "#CFC4E0" : "" }}
                className="tab tab3"
                data-tab="tab3"
              >
                Avis
              </div> */}
                    </div>
                    {activetab == "about" && (
                      <div
                        className="tab-content px-0"
                        id="tab1"
                        style={{
                          display: "block",
                        }}
                      >
                        <div className="mt-4">
                          <p>{resturentdata?.about}</p>
                        </div>
                      </div>
                    )}

                    {activetab == "menu" && (
                      <div
                        className="tab-content px-0"
                        id="tab2"
                        style={{
                          display: "block",
                        }}
                      >
                        <div className="mt-4">
                          <h2 className="fs-2">Menu</h2>
                          <div className="my-4">
                            {menudata?.map((e) => (
                              <div className="py-3 px-3 my-2 bg-tb d-flex justify-content-between align-items-center">
                                <p className="mb-0 text-prpl">
                                  {/* Appetizers */}
                                  {e.name}
                                </p>
                                <div className="d-flex bg-tb align-items-center">
                                  <span className="text-prpl">
                                    {/* 10 DH */}
                                    {e.price} DH
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}

                    {activetab == "avis" && (
                      <div
                        className="tab-content px-0"
                        id="tab3"
                        style={{
                          display: "block",
                        }}
                      >
                        <div className="mt-3 row">
                          <div className="col-lg-6">
                            <div className="bg-notice ">
                              <div className="px-3 py-3 d-flex align-items-center">
                                <div className="d-flex align-items-center py-4 px-3 border-prpl rounded-circle me-3">
                                  <p className="mb-0">9.5/</p>
                                  <p className="mt-3 mb-0 small">10.0</p>
                                </div>
                                <div>
                                  <p className="mb-0 text-prpl fs-5">
                                    Excellent table
                                  </p>
                                  <p className="mb-0 small">5974 reviews</p>
                                  <div className="mt-2">
                                    <i className="fa-solid fs-5 yellow fa-star" />
                                    <i className="fa-solid fs-5 yellow fa-star" />
                                    <i className="fa-solid fs-5 yellow fa-star" />
                                    <i className="fa-solid fs-5 yellow fa-star" />
                                    <i className="fa-solid fs-5 gray fa-star" />
                                  </div>
                                </div>
                              </div>
                              <div className="border-top border-1">
                                <div className="row">
                                  <div className="col-4 px-0">
                                    <div className="d-flex flex-column  py-2 border-end border-1 align-items-center">
                                      <div>
                                        <div className="d-flex align-items-center  rounded-circle">
                                          <p className="mb-0 small">9.5/</p>
                                          <p className="mt-3 mb-0 small">
                                            10.0
                                          </p>
                                        </div>
                                        <p className="mt-2 text-prpl small">
                                          Dishes
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-4 px-0">
                                    <div className="d-flex flex-column  py-2 border-end border-1 align-items-center">
                                      <div>
                                        <div className="d-flex align-items-center  rounded-circle">
                                          <p className="mb-0 small">9.5/</p>
                                          <p className="mt-3 mb-0 small">
                                            10.0
                                          </p>
                                        </div>
                                        <p className="mt-2 text-prpl small">
                                          Service
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-4 px-0">
                                    <div className="d-flex flex-column  py-2 align-items-center">
                                      <div>
                                        <div className="d-flex align-items-center  rounded-circle">
                                          <p className="mb-0 small">9.5/</p>
                                          <p className="mt-3 mb-0 small">
                                            10.0
                                          </p>
                                        </div>
                                        <p className="mt-2 text-prpl small">
                                          Atmosphere
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mt-3 mt-lg-0 det-progress">
                            <div className="bg-notice h-100 px-3 py-3">
                              <div className="row align-items-center my-2">
                                <div className="col-3 col-sm-2 col-md-3 col-xl-2">
                                  <p className="mb-0">9-10</p>
                                </div>
                                <div className="col-9 col-sm-10 col-md-9 col-xl-10">
                                  <div className="progress rounded-pill w-100">
                                    <div
                                      className="progress-bar bg-prpl rounded-pill"
                                      style={{
                                        width: "70%",
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                              <div className="row align-items-center my-2">
                                <div className="col-3 col-sm-2 col-md-3 col-xl-2">
                                  <p className="mb-0">9-10</p>
                                </div>
                                <div className="col-9 col-sm-10 col-md-9 col-xl-10">
                                  <div className="progress rounded-pill w-100">
                                    <div
                                      className="progress-bar bg-prpl rounded-pill"
                                      style={{
                                        width: "50%",
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                              <div className="row align-items-center my-2">
                                <div className="col-3 col-sm-2 col-md-3 col-xl-2">
                                  <p className="mb-0">6-7</p>
                                </div>
                                <div className="col-9 col-sm-10 col-md-9 col-xl-10">
                                  <div className="progress rounded-pill w-100">
                                    <div
                                      className="progress-bar bg-prpl rounded-pill"
                                      style={{
                                        width: "30%",
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                              <div className="row align-items-center my-2">
                                <div className="col-3 col-sm-2 col-md-3 col-xl-2">
                                  <p className="mb-0">4-5</p>
                                </div>
                                <div className="col-9 col-sm-10 col-md-9 col-xl-10">
                                  <div className="progress rounded-pill w-100">
                                    <div
                                      className="progress-bar bg-prpl rounded-pill"
                                      style={{
                                        width: "20%",
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                              <div className="row align-items-center my-2">
                                <div className="col-3 col-sm-2 col-md-3 col-xl-2">
                                  <p className="mb-0">1-2</p>
                                </div>
                                <div className="col-9 col-sm-10 col-md-9 col-xl-10">
                                  <div className="progress rounded-pill w-100">
                                    <div
                                      className="progress-bar bg-prpl rounded-pill"
                                      style={{
                                        width: "10%",
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="p-2 pointer">
                              <div className="card py-3 px-4 pb-5 rounded-4 shadow-sm border-0">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div>
                                    <p className="mb-0 text-black fs-2 fw-md">
                                      Miranda W.
                                    </p>
                                    <Rating value={4} readOnly />
                                    <p className="mb-0 text-prpl fw-md mt-3 fs-5">
                                      Verified Customer
                                    </p>
                                 
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="p-2 pointer">
                              <div className="card py-3 px-4 pb-5 rounded-4 shadow-sm border-0">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div>
                                    <p className="mb-0 text-black fs-2 fw-md">
                                      Miranda W.
                                    </p>
                                    <Rating value={4} readOnly />
                                    <p className="mb-0 text-prpl fw-md mt-3 fs-5">
                                      Verified Customer
                                    </p>
                                
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="p-2 pointer">
                              <div className="card py-3 px-4 pb-5 rounded-4 shadow-sm border-0">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div>
                                    <p className="mb-0 text-black fs-2 fw-md">
                                      Miranda W.
                                    </p>
                                    <Rating value={4} readOnly />
                                    <p className="mb-0 text-prpl fw-md mt-3 fs-5">
                                      Verified Customer
                                    </p>
                                 
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="p-2 pointer">
                              <div className="card py-3 px-4 pb-5 rounded-4 shadow-sm border-0">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div>
                                    <p className="mb-0 text-black fs-2 fw-md">
                                      Miranda W.
                                    </p>
                                    <Rating value={4} readOnly />
                                    <p className="mb-0 text-prpl fw-md mt-3 fs-5">
                                      Verified Customer
                                    </p>
                                 
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <h2 className="fs-2 mt-4">User Photos</h2>
                        <div className="mt-4 d-flex">
                          <div className="col-4 d-flex flex-column">
                            <div className="py-1 glry1">
                              <img
                                alt=""
                                className="w-100 h-100 object-cover rounded-3"
                                src={require("../assets/glry1.png")}
                              />
                            </div>
                            <div className="py-1 glry2">
                              <img
                                alt=""
                                className="w-100 h-100 object-cover rounded-3"
                                src={require("../assets/glry4.png")}
                              />
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="py-1 px-2 h-100 glry3">
                              <img
                                alt=""
                                className="w-100 h-100 object-cover rounded-3"
                                src={require("../assets/ist.jpeg")}
                              />
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="py-1 glry4">
                              <img
                                alt=""
                                className="w-100 h-100 object-cover rounded-3"
                                src={require("../assets/ist.jpeg")}
                              />
                            </div>
                            <div className="py-1 glry5">
                              <img
                                alt=""
                                className="w-100 h-100 object-cover rounded-3"
                                src={require("../assets/glry3.png")}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div
                    className={` col-sm-8 col-md-5 col-lg-4 px-0 d-sm-flex justifyContent-center ${
                      bookingtable ? "d-none" : ""
                    }`}
                  >
                    {isLoggedIn ?
                     (
                      <form
                        action=""
                        className="bg-white tab-containercal h-fit cal-sec myshadow mx-2  py-0 "
                      >
                        <div className="tab-container  position-relative w-100 myshadow d-flex justify-content-between align-items-center border-0">
                          <div
                            className={`tabcal tab1cal border-0 pointer col-3 d-flex justify-content-center ${
                              calendertab === "calender" ? "active" : ""
                            }`}
                            data-tab="tabcal1"
                            onClick={() => setcalendertab("calender")}
                          >
                            <FaCalendar />

                            {/* <MdAccessTime /> */}
                          </div>
                          <div
                            className={`tabcal tab2cal border-0 pointer col-3 d-flex justify-content-center ${
                              calendertab === "time" ? "active" : ""
                            }`}
                            data-tab="tabcal2"
                            onClick={() => setcalendertab("time")}
                          >
                            <MdAccessTime />
                          </div>
                          <div
                            className={`tabcal tab3cal border-0 pointer col-3 d-flex justify-content-center ${
                              calendertab === "user" ? "active" : ""
                            }`}
                            data-tab="tabcal3"
                            onClick={() => setcalendertab("user")}
                          >
                            <FaUser />
                          </div>
                          {/* <div
                className={`tabcal tab4cal border-0 pointer col-3 d-flex justify-content-center ${calendertab === "person" ? "active" : ""}`}
                data-tab="tabcal4"
                  
                  onClick={()=>setcalendertab("person")}
                >
                <FaPercent />
                </div> */}
                        </div>
                        {calendertab == "calender" && (
                          <div
                            className="tab-contentcal px-0 pb-3"
                            id="tabcal1"
                            style={{
                              display: "block",
                            }}
                          >
                            <div className="w-100 d-flex justify-content-center">
                              <div className="mycalendar bg-white">
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DateCalendar
                                    disablePast
                                    onChange={handleDateChange}
                                  />
                                </LocalizationProvider>
                              </div>
                            </div>
                          </div>
                        )}

                        {calendertab == "time" && (
                          <div
                            className="tab-contentcal px-0 py-3 px-3"
                            id="tabcal2"
                            style={{
                              display: "block",
                            }}
                          >
                            <p className="text-center fs-5 mb-2">
                              Select a time
                            </p>
                            <div>
    

      {/* Lunch Time Section */}
      <div className="time-section">
        <h3>Déjeuner</h3>
        <div className="row">
          {lunchTimes.map((times) => (
            <div className="col-3 my-2" key={times}>
              <div
                className={`rounded-3 pointe border h-100 d-flex align-items-center justify-content-center pointer transition-all ${
                  time === times ? "selected text-light" : ""
                }`}
                onClick={() => handletimeChange(times)}
              >
                <p className="text-center notranslate mb-0 py-2">{times}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Dinner Time Section */}
      <div className="time-section">
        <h3>Dîner</h3>
        <div className="row">
          {dinnerTimes.map((times) => (
            <div className="col-3 my-2" key={times}>
              <div
                className={`rounded-3 pointe   border h-100 d-flex align-items-center justify-content-center pointer transition-all ${
                  time === times ? "selected text-light" : ""
                }`}
                onClick={() => handletimeChange(times)}
              >
                <p className="text-center notranslate mb-0 py-2">{times}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>

                          </div>
                        )}
                        {calendertab == "user" && (
                          <div
                            className="tab-contentcal px-3 py-3"
                            id="tabcal3"
                            style={{
                              display: "block",
                            }}
                          >
                            <div className="row  justify-content-center">
                              <div className="col-10">
                                <div className="row">
                                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.map(
                                    (number, index) => (
                                      <div key={index} className="col-4 my-2">
                                        <div
                                          className={`border-prpl shadow rounded-3 h-100 d-flex align-items-center pointer justify-content-center ${
                                            selectedNumber === number
                                              ? "selected text-light "
                                              : ""
                                          }`}
                                          onClick={() => handleClick(number)}
                                        >
                                          <p className="text-center fs-5 mb-0 py-2">
                                            {number}
                                          </p>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="mt-3">
                              {/* <a className="d-block text-center text-prpl">
                                See more persons
                              </a> */}
                              <button
                                disabled={
                                  !selectedNumber ||
                                  !time ||
                                  !selectedDate ||
                                  loadingbooking
                                }
                                onClick={handelbooking}
                                className="py-3 w-100 px-3 text-white bg-prpl border-0 rounded-4 mt-4"
                                type="submit"
                              >
                                {loadingbooking
                                  ? "Reserve this restaurant..."
                                  : "Reserve this restaurant"}
                              </button>
                            </div>
                          </div>
                        )}

                        {calendertab == "person" && (
                          <div
                            className="tab-contentcal px-4 py-3 "
                            id="tabcal4"
                            style={{
                              display: "block",
                            }}
                          >
                            <p className="text-center fs-5 mb-2 mt-3">
                              Add an option
                            </p>
                            {/* <div className="py-3 px-3 mt-4 rounded-4  border-prpl">
                  Discover menu
                </div> */}
                            <FormControl fullWidth>
                              <InputLabel
                                id="demo-simple-select-label"
                                sx={{ color: "black" }}
                              >
                                {" "}
                                Discover menu
                              </InputLabel>
                              <Select
                                className=" px-3  rounded-4  border-prpl"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                multiple
                                onChange={(e) => setmenu(e.target.value)}
                                value={menu}
                                label="Age"
                              >
                                {menudata?.map((e) => (
                                  <MenuItem value={e.id}>{e.name}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <button
                              onClick={handelbooking}
                              className="py-3 w-100 px-3 text-white bg-prpl border-0 rounded-4 mt-4"
                              type="submit"
                            >
                              {loadingbooking
                                ? "Reserve this restaurant..."
                                : "Reserve this restaurant"}
                            </button>
                          </div>
                        )}
                      </form>
                    ) : (
                      <div className="ms-md-auto my-auto">
                        <h3 className="text-center   text-prpl">
                           Create  Reservation
                        </h3>

                        <div className=" d-flex justify-content-center mt-3">
                          {/* <Link to={"/"}> */}
                            <button className="btn bg-prpl4 text-white" onClick={() => setshowmodul(true)}>
                              {" "}
                           Book a      table
                            </button>
                          {/* </Link> */}
                        </div>
                      </div>
                    )}
                  </div>
            
                </div>
              </div>
            </section>
            <Bookingpopup show={showmodul} onHide={() => setshowmodul(false)} />
            {/* Google Map Section */}
            <section>
              <div className="mycontainer">
                <div>
                  {
                    <>
                      <iframe
                        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDQmPq0HH1XTcSNa55xUP748V1Vv4hhK1w&q=${resturentdata?.latitude},${resturentdata?.longitude}`}
                        width="100%"
                        height="300"
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        className="rounded-4"
                      />
                      <button
                        onClick={() => {
                          console.log(
                            `https://www.google.com/maps/dir/?api=1&destination=${resturentdata?.latitude},${resturentdata?.longitude}`
                          );
                          window.open(
                            `https://www.google.com/maps/dir/?api=1&destination=${resturentdata?.latitude},${resturentdata?.longitude}`,
                            "_blank"
                          );
                        }}
                        className="py-3 w-100 px-3 text-white bg-prpl border-0 rounded-4 mt-2 d-flex justify-content-center align-items-center gap-2"
                      >
                        <MdDirections style={{ fontSize: "24px" }} />
                        Get Directions
                      </button>
                    </>
                  }
                </div>
              </div>
            </section>
            {/* Similar Restaurants Section */}
            <section className="bg-ltw">
              <div className="mycontainer py-5 mt-5 py-sm-5">
                <div className="row justify-content-between ">
                  <h2 className="fs-1 fw-normal text-center text-prpl">
                    Similar Restaurants
                  </h2>
              
                </div>

                <div className="row ">
                  {filter?.map((item, index) => (
                    <div
                      key={index}
                      className=" col-md-3 rounded-4 card-slider"
                    >
                      <div className="d-flex justify-content-center">
                        <div
                          className=" rounded-4 p-3 "
                          style={{ width: "15rem" }}
                        >
                          <Link to={`/detail?id=${item.id}`}>
                            <img
                              style={{
                                width: "100%",
                                height: "200px",
                                objectFit: "cover",
                                objectPosition: "top",
                              }}
                              className=" mx-auto rounded-4"
                              src={item?.images[0]?.image} // Assuming item.images is an array containing image objects
                              alt={item.name}
                            />
                          </Link>
                        </div>
                      </div>

                      <div className=" pb-5">
                        <div className="d-flex justify-content-between align-items-start">
                          <span>
                            <p className="mb-1">
                              {" "}
                              <FaLocationDot className="fs-4 mx-2" />
                              {item?.location}
                            </p>
                            <h4 className="text-prpl2">{item?.name}</h4>
                          </span>
                          {/* <MdDelete  onClick={()=>handleitem(item?.id)} style={{fontSize:"20px"}}/>
           <Link to={`/admin/add-resturants?id=${item?.id}`} state={{state:item}}>     <CiEdit  className='text-dark'  style={{fontSize:"20px"}}/></Link> */}
                        </div>
                        <p>
                          {item?.description}
                          {/* Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator. */}
                        </p>
                        {/* <Rating name="read-only" value={4} readOnly className='fs-2'/> */}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
            {/* FAQ Section */}
            <section className="">
              <div className="mycontainer py-5 py-sm-5">
                <div className="row justify-content-center">
                  <Faq />
                </div>
              </div>
            </section>
          </div>
        )}
      </div>
    </div>
  );
};

export default Detail;

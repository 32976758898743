import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FaCalendar, FaUser } from "react-icons/fa";
import { MdAccessTime } from "react-icons/md";

import { Link, useNavigate } from "react-router-dom";
import apiClient from "../api/apiClient";
import { useSelector } from "react-redux";
import { getCurrentURLParameters } from "../modules/helpers";
const Bookingpopup = (props) => {
  const { id } = getCurrentURLParameters();
  const [bookingtable, setbookingtable] = useState(true);
  const [loadingbooking, setloadingbooking] = useState(false);
  const [menu, setmenu] = useState([]);
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [menudata, setmenudata] = useState([]);
  const [time, setTime] = useState("");
  const [calendertab, setcalendertab] = useState("calender");
  const handleDateChange = (date) => {
    // Check if the date object is valid
    console.log(date.$d, "date");
    if (date && typeof date === "object") {
      const year = date.$d.getFullYear();
      const month = date.$d.getMonth() + 1;
      const day = date.$d.getDate();

      const datefarmate = `${year}-${month}-${day}`;
      setSelectedDate(datefarmate);
    }
    setcalendertab("time");
  };
  const handletimeChange = (time) => {
    setTime(time);
    setcalendertab("user");
  };
  const handleClick = (number) => {
    setSelectedNumber(number);
  };
  const handelbooking = async (e) => {
    e.preventDefault();
    console.log(selectedDate, selectedNumber, time, menu, "pppppppppp");
    setloadingbooking(true);
    const result = await apiClient.post("/bookings/", {
      date: selectedDate,
      persons: selectedNumber,
      time: time,
      restaurant: id,
    });

    setloadingbooking(false);
    if (!result.ok) return;

    navgate("/upcoming-booking");
    // if( result.data?.user_type=="user"){

    // }else if() { props.navgater("admin/dashboard")}
  };
  const { token, userType, isLoggedIn, userid } = useSelector(
    (state) => state.auth
  );
  const navgate = useNavigate();
  return (
    <div>
      {" "}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>

        <div
          className={` col-sm-8 col-md-12 col-lg-12 px-0 d-sm-block 
                      "d-none" 
                    `}
        >
          {isLoggedIn ? (
            <form
              action=""
              className="bg-white tab-containercal cal-sec myshadow mx-2  py-0 "
            >
              <div className="tab-container  position-relative w-100 myshadow d-flex justify-content-between align-items-center border-0">
                <div
                  className={`tabcal tab1cal border-0 pointer col-3 d-flex justify-content-center ${
                    calendertab === "calender" ? "active" : ""
                  }`}
                  data-tab="tabcal1"
                  onClick={() => setcalendertab("calender")}
                >
                  <FaCalendar />

                  {/* <MdAccessTime /> */}
                </div>
                <div
                  className={`tabcal tab2cal border-0 pointer col-3 d-flex justify-content-center ${
                    calendertab === "time" ? "active" : ""
                  }`}
                  data-tab="tabcal2"
                  onClick={() => setcalendertab("time")}
                >
                  <MdAccessTime />
                </div>
                <div
                  className={`tabcal tab3cal border-0 pointer col-3 d-flex justify-content-center ${
                    calendertab === "user" ? "active" : ""
                  }`}
                  data-tab="tabcal3"
                  onClick={() => setcalendertab("user")}
                >
                  <FaUser />
                </div>
                {/* <div
                className={`tabcal tab4cal border-0 pointer col-3 d-flex justify-content-center ${calendertab === "person" ? "active" : ""}`}
                data-tab="tabcal4"
                  
                  onClick={()=>setcalendertab("person")}
                >
                <FaPercent />
                </div> */}
              </div>
              {calendertab == "calender" && (
                <div
                  className="tab-contentcal px-0 pb-3"
                  id="tabcal1"
                  style={{
                    display: "block",
                  }}
                >
                  <div className="w-100 d-flex justify-content-center">
                    <div className="mycalendar bg-white">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateCalendar disablePast onChange={handleDateChange} />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
              )}

              {calendertab == "time" && (
                <div
                  className="tab-contentcal px-0 py-3 px-3"
                  id="tabcal2"
                  style={{
                    display: "block",
                  }}
                >
                  <p className="text-center fs-5 mb-2">Select a time</p>
                  <div className="row">
                    {[
                      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
                      18, 19, 20, 21, 22, 23, 0,
                    ].map((e) => (
                      <div className="col-3 my-2" key={e}>
                        <div
                          className={`border-prpl rounded-3 h-100 d-flex align-items-center pointer justify-content-center ${
                            time === `${e}:00` ? "selected" : ""
                          }`}
                          onClick={() => handletimeChange(`${e}:00`)}
                        >
                          <p
                            className="text-center fs-6
       mb-0 py-2"
                          >
                            {e}h00
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {calendertab == "user" && (
                <div
                  className="tab-contentcal px-3 py-3"
                  id="tabcal3"
                  style={{
                    display: "block",
                  }}
                >
                  <div className="row  justify-content-center">
                    <div className="col-10">
                      <div className="row">
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.map(
                          (number, index) => (
                            <div key={index} className="col-4 my-2">
                              <div
                                className={`border-prpl shadow rounded-3 h-100 d-flex align-items-center pointer justify-content-center ${
                                  selectedNumber === number ? "selected" : ""
                                }`}
                                onClick={() => handleClick(number)}
                              >
                                <p className="text-center fs-5 mb-0 py-2">
                                  {number}
                                </p>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    {/* <a className="d-block text-center text-prpl">
                      See more persons
                    </a> */}
                    <button
                      disabled={
                        !time ||
                        !selectedNumber ||
                        !selectedDate ||
                        loadingbooking
                      }
                      onClick={handelbooking}
                      className="py-3 w-100 px-3 text-white bg-prpl border-0 rounded-4 mt-4"
                      type="submit"
                    >
                      {loadingbooking
                        ? "Reserve this restaurant..."
                        : "Reserve this restaurant"}
                    </button>
                  </div>
                </div>
              )}

              {calendertab == "person" && (
                <div
                  className="tab-contentcal px-4 py-3 "
                  id="tabcal4"
                  style={{
                    display: "block",
                  }}
                >
                  <p className="text-center fs-5 mb-2 mt-3">Add an option</p>
                  {/* <div className="py-3 px-3 mt-4 rounded-4  border-prpl">
                  Discover menu
                </div> */}
                  <FormControl fullWidth>
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{ color: "black" }}
                    >
                      {" "}
                      Discover menu
                    </InputLabel>
                    <Select
                      className=" px-3  rounded-4  border-prpl"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      multiple
                      onChange={(e) => setmenu(e.target.value)}
                      value={menu}
                      label="Age"
                    >
                      {menudata?.map((e) => (
                        <MenuItem value={e.id}>{e.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <button
                    onClick={handelbooking}
                    className="py-3 w-100 px-3 text-white bg-prpl border-0 rounded-4 mt-4"
                    type="submit"
                  >
                    {loadingbooking
                      ? "Reserve this restaurant..."
                      : "Reserve this restaurant"}
                  </button>
                </div>
              )}
            </form>
          ) : (
            <div className="my-2 p-3  " >
              <h3 className="text-center  text-prpl">
                For booking Login please
              </h3>

              <div className=" d-flex justify-content-center mt-3">
                <Link to={"/"}>
                  <button className="btn bg-prpl4 text-white"> Login </button>
                </Link>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Bookingpopup;

import React from 'react';

const Seetings = () => {
    return (
        <div>
            Seetings will be here.
        </div>
    );
}

export default Seetings;

import React, { useEffect, useState } from 'react'
import { BsTwitterX } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io";
import {Link,} from "react-router-dom";
import { useIndexPageData } from '../contextapi/Indexpagedata';
import apiClient from '../api/apiClient';
export const Footer = () => {

  const { indexData } = useIndexPageData();

  const [social,setsocial] =useState([])


  const fetchcityData  = async()=>{
      const result = await apiClient.get('/social/')
      if (result.ok) setsocial(...result.data.results)
    
    }
  

    useEffect(()=>{
    
      fetchcityData()

    },[])

  return (
    <>
    <footer className="bg-prpl text-white">
      <div className="mycontainer">
        <div className="py-5">
          <div className="row ">
            <div className="col-sm-6 my-2 my-md-0 col-md-3 col-lg-3">
              <p className="mb-0 text-light small">
              {indexData?.social_text     }
              </p>
              <div className="d-flex align-items-center mt-4">
                <Link  to={social?.twitter}  target={social?.twitter? '_blank':"" }  
                  className="text-prpl bg-white rounded-circle px-2 py-2 socialftr d-flex align-items-center justify-content-center text-decoration-none my-2 me-2"
                >
                  <BsTwitterX  className='fs-4'/>
                </Link>
                <Link    to={social?.facebook} target={social?.facebook? '_blank':"" } 
                  className="text-prpl bg-white rounded-circle px-2 py-2 socialftr d-flex align-items-center justify-content-center text-decoration-none my-2 me-2"
                >
                  <FaFacebookF   className='fs-4'/>
                </Link>
                <Link
                to={social?.insta} target={social?.insta? '_blank':"" } 
                  className="text-prpl bg-white rounded-circle px-2 py-2 socialftr d-flex align-items-center justify-content-center text-decoration-none my-2 me-2"
                >
                  <IoLogoInstagram   className='fs-4'/>
                </Link>
              </div>
            </div>
            <div className="col-6 my-2 my-md-0 col-md-3 col-lg-3 d-flex flex-column align-items-md-center">
              <div>
                <h2 className="fs-5 pb-2">Useful Links</h2>
                <Link  
                  to="/about"
                  className="d-block my-2 text-light text-decoration-none"
                >
                  About
                </Link>
                {/* <Link  
                  // to="partners.html"
                  className="d-block my-2 text-light text-decoration-none"
                >
                  Partners
                </Link> */}
                <Link  

                  to="/select"
                  className="d-block my-2 text-light text-decoration-none"
                >
                  Contact
                </Link>
              </div>
            </div>
            <div className="col-6 my-2 my-md-0 col-md-2 col-lg-3 d-flex flex-column align-items-md-center">
              <div>
                <h2 className="fs-5 pb-2">Help?</h2>
                <Link  
                  // to="faq.html"
                  className="d-block my-2 text-light text-decoration-none"
                >
                  FAQ
                </Link>
                <Link  
                  // to="terms.html"
                  className="d-block my-2 text-light text-decoration-none"
                >
                  Term &amp; Conditi0on
                </Link>
                <Link  
                  // to="policy.html"
                  className="d-block my-2 text-light text-decoration-none"
                >
                  Privacy Policy
                </Link>
                
              </div>
            </div>
            {/* <div className="col-sm-6 my-2 my-md-0 col-md-4 col-lg-3 ">
              <div>
                <h2 className="fs-5 pb-2">Address</h2>
                <p className="mb-0 text-light small">
             {indexData?.address}
                 
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </footer>
    <div className="copyright py-3 bg-light-prpl">
      <div className="mycontainer">
        <div className="row justify-content-center justify-content-between">
          <div className="col-md-7">
            <p className="mb-0 text-center text-md-start text-black pe-3">
              © Copyright  {indexData?.copyright}
            </p>
          </div>
          <div className="col-md-5">
            <div className="d-flex flex-wrap justify-content-center justify-content-md-end align-items-center">
              <Link className="text-black text-decoration-none">
                Terms &amp; Condition
              </Link>
              <span className="px-2 px-lg-3">|</span>
              <Link className="text-black text-decoration-none">
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}
